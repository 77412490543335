import { LocationOption } from '../../lib/types';

interface SegmentPickerProps {
  segments: LocationOption[];
  activeSegment: string | undefined;
  setActiveSegment: (s: string) => void;
}

const SegmentPicker = ({ segments, activeSegment, setActiveSegment }: SegmentPickerProps) => {
  if (segments.length <= 1) {
    return <></>;
  }
  return (
    <>
      <div id="circlebg"></div>

      {segments.map((segment: LocationOption, index: number) => {
        const rotation = segment.meta?.left_border + 45;
        if (segment && !activeSegment && segment.meta?.left_border === 0) {
          setActiveSegment(segment.key);
        }
        const active = activeSegment ? (activeSegment === segment.key ? true : false) : index === 0 ? true : false;
        return (
          <div key={segment.key} className="sw" style={{ transform: 'rotate(' + rotation + 'deg)' }}>
            <div
              onClick={() => {
                setActiveSegment(segment.key);
              }}
              className={'circle_segment ' + (active ? 'active' : '')}
            ></div>
          </div>
        );
      })}

      <div
        key={'n'}
        style={{
          top: '10px',
          position: 'absolute',
          left: '97px',
          color: 'white',
          fontSize: '25px',
          pointerEvents: 'none',
        }}
      >
        N
      </div>
      <div
        key={'e'}
        style={{
          top: '60px',
          position: 'absolute',
          left: '150px',
          color: 'white',
          fontSize: '25px',
          pointerEvents: 'none',
        }}
      >
        E
      </div>
      <div
        key={'s'}
        style={{
          top: '97px',
          position: 'absolute',
          left: '97px',
          color: 'white',
          fontSize: '25px',
          pointerEvents: 'none',
        }}
      >
        S
      </div>
      <div
        key={'w'}
        style={{
          top: '60px',
          position: 'absolute',
          left: '50px',
          color: 'white',
          fontSize: '25px',
          pointerEvents: 'none',
        }}
      >
        W
      </div>
    </>
  );
};
export default SegmentPicker;

import { useState } from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react';
import releases from './releases';

const ReleaseItemList = ({ items }: any) => (
  <ul>
    {items.map((d: any) => (
      <li key={d}>{d}</li>
    ))}
  </ul>
);

function ReleaseNote(props: any) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const { version, changes, knownissues }: any = props;
  const icon = expanded ? 'angle double up' : 'angle double down';

  return (
    <Segment>
      <Header as="h4" onClick={toggleExpanded}>
        Release {version} <Icon name={icon} />
      </Header>

      {expanded && (
        <div>
          <Header as="h5">Changes</Header>
          <ReleaseItemList items={changes} />

          {knownissues.length > 0 && (
            <div>
              <Header as="h5">Known issues</Header>
              <ReleaseItemList items={knownissues} />
            </div>
          )}
        </div>
      )}
    </Segment>
  );
}

const ReleaseNotes = () => (
  <div>
    {releases.map((d, i) => (
      <ReleaseNote key={d.version} expanded={i === 0} {...d} />
    ))}
  </div>
);

export default ReleaseNotes;

import { Button, Icon } from 'semantic-ui-react';
import useStore from '../../Store/Store';
import { observer } from 'mobx-react';
const Splash = observer(() => {
  const { appStore, firstTimeSetupStore } = useStore();
  const user = appStore.getUser();
  const firstName = user.auth0?.given_name;
  return (
    <div className="splash">
      <div className="hero p-3 rounded text-center w-75 mx-auto text-white">
        <h1 className="heading">Welcome {firstName}!</h1>
        <div className="content text-left mx-auto my-2 px-2">
          <p>This wizard will guide you through setting up your CageSense&trade;. Click to get started.</p>
          <Button
            color="green"
            size="huge"
            className="bg-light-green"
            fluid
            content="Start"
            icon="chevron right"
            labelPosition="right"
            onClick={() => {
              firstTimeSetupStore.setSetupStarted(true);
            }}
          >
            <span className="text-blue">Start</span>
          </Button>
        </div>
        {user.local.first_time_setup_complete === true && (
          <p className="text-right text-sm text-light-green text-italic m-0">
            You have already completed this.
            <Icon name="check" className="ms-1" />
          </p>
        )}
      </div>
    </div>
  );
});

export { Splash };

import _ from 'lodash';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Modal, Grid, Select, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { AlarmContents, CageContents, LocationOption, SensorLocationContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';
import SegmentPicker from '../UIElements/SegmentPicker';
import useAlarmListStore from './AlarmListStore';

const makeTitleForConnectingSensor = (cageName: string, alarm: AlarmContents | undefined) => {
  if (!cageName || !alarm) {
    return '';
  }

  return 'Connect ' + _.capitalize(alarm.name) + ' Alarm to Cage ' + cageName;
};

interface ConnectAlarmDialogueProps {
  siteKey: string | undefined;
  cageName: string;
  alarm: AlarmContents | undefined;
  cage: CageContents | undefined;
}

const ConnectAlarmDialogue = observer(({ siteKey, cageName, alarm, cage }: ConnectAlarmDialogueProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore } = useStore();
  const { alarmListStore } = useAlarmListStore();
  const [activeSegment, setActiveSegment] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<any>();

  var locationOptions: LocationOption[] = [];
  if (cage) {
    cage.sensor_locations?.sort((a: SensorLocationContents, b: SensorLocationContents) =>
      a.meta.min_depth > b.meta.min_depth ? 1 : -1
    );
    locationOptions = cage.sensor_locations.map((location: SensorLocationContents) => {
      const depth = location?.meta?.max_depth
        ? location.meta.type === 'depth'
          ? location.meta.min_depth + ' - ' + location.meta.max_depth
          : location.meta.max_depth - (location.meta.max_depth - location.meta.min_depth) / 2
        : 0;
      const description = location.meta?.type
        ? location.meta.type === 'depth'
          ? depth + ' meters, Cage '
          : depth + ' meters, ' + _.upperFirst(location.meta.type).replace('_', ' ')
        : 'Unknown';
      return {
        key: location.node_key,
        value: location.node_key,
        text: description,
        // type: location.meta.type,
        meta: location.meta,
      };
    });
  }
  var segments: LocationOption[] = [];
  if (locationOptions.length > 0) {
    const location = locationOptions.find((option: LocationOption) => option.key === selectedLocation);
    segments = locationOptions.filter(
      (o: LocationOption) =>
        o.meta?.min_depth === location?.meta?.min_depth &&
        o.meta.type === location.meta.type &&
        o.meta.max_depth === location.meta.max_depth
    );
  }

  const assign = async () => {
    if (!alarm || !cage) {
      console.log('no alarm');
      return;
    }

    let alarmKey = alarm.node_key;
    const locationKey = activeSegment ? activeSegment : selectedLocation;

    const sl = cage.sensor_locations.find((sl: SensorLocationContents) => sl.node_key === locationKey);

    if (!sl) {
      notyf.error('unknown location');
      return;
    }

    if (sl?.scenarios.findIndex((a: AlarmContents) => a.node_key === alarm.node_key) > -1) {
      notyf.error('This alarm is already assinged to this location');
      return;
    }

    AuthFetcher(
      '/api/v1/add/connection/' + locationKey + '/SensorLocation/' + alarmKey + '/Alarm',
      authStore.getTokenFunction(),
      {
        method: 'PUT',
      }
    )
      ?.then((res) => {
        res.error
          ? notyf.error('Could not pair alarm. Error code: ' + res.status + ' ' + res.statusText)
          : notyf.success('Successfully connected alarm');
        setSelectedLocation(undefined);
        alarmListStore.showConnectAlarm(false);
        mutate(`/api/v1/site/${siteKey}`);
        mutate(`/api/v1/sensor/site/${siteKey}`);
      })
      .catch((err) => {
        notyf.error('Could not pair alarm.');
      });
  };

  return (
    <Modal open={alarmListStore.connectAlarm} size="tiny" dimmer className="blue">
      <Modal.Header>{makeTitleForConnectingSensor(cageName, alarm)}</Modal.Header>
      <Modal.Content style={{ color: 'black' }}>
        <Grid stackable columns={2}>
          <Grid.Column width={9}>
            <Select
              fluid
              placeholder="Select placement"
              options={locationOptions.filter((location: any) => location.meta?.left_border === 0)}
              onChange={(e, { value }) => {
                setActiveSegment(undefined);
                setSelectedLocation(value);
              }}
              value={selectedLocation}
            />
          </Grid.Column>
          <Grid.Column
            width={7}
            style={{
              height: '115px',
              marginTop: '-30px',
              transform: 'translateY(0%) translateZ(-50px) rotateX(-65deg)',
            }}
          >
            <SegmentPicker segments={segments} activeSegment={activeSegment} setActiveSegment={setActiveSegment} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(e) => {
            setActiveSegment(undefined);
            alarmListStore.showConnectAlarm(false);
          }}
          negative
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (selectedLocation) assign();
            else notyf.error('No placement selected.');
          }}
          positive
        >
          Connect
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default ConnectAlarmDialogue;

import React from 'react';
import Catch from './Catch';
import { Message, Icon } from 'semantic-ui-react';

// https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c

type Props = {
  children: React.ReactNode;
};

const ErrorBoundary = Catch(function MyErrorBoundary(props: Props, error?: Error) {
  if (error) {
    // You can render any custom fallback UI
    return (
      <Message icon>
        <Icon name="frown" />
        <Message.Content>
          <Message.Header>Woops</Message.Header>
          <p>
            The component stopped working. If this failure happens again, please record the steps you have taken which
            triggered the failure and notify Water Linked with the details below.
          </p>
          <details style={{ whiteSpace: 'pre-wrap' }}>{error && error.toString()}</details>
        </Message.Content>
      </Message>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export default ErrorBoundary;

import { FC } from 'react';
import { MeshProps, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';

const HeightPlane: FC<MeshProps> = (props) => {
  const height = useLoader(THREE.TextureLoader, 'elevation.png');
  const normals = useLoader(THREE.TextureLoader, 'elevationNormals.png');

  return (
    <Plane
      {...props}
      scale={[300, 300, 300]}
      rotation={[-Math.PI / 2, 0, 0]}
      args={[64, 64, 1024, 1024]}
      castShadow
      receiveShadow
    >
      <meshStandardMaterial attach="material" color="gray" normalMap={normals} displacementMap={height} />
    </Plane>
  );
};

export default HeightPlane;

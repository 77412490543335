// Container for content of Auth App
import { Fragment, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Sidebar, Ref } from 'semantic-ui-react';
import ErrorBoundary from './Components/ErrorBoundary';
import About from './Routes/About';
import Admin from './Routes/Admin';
import Analytics from './Routes/AnalyticsV2';
import CageOverview from './Routes/CageOverview';
import Dashboard from './Routes/Dashboard';
import Diagnostic from './Routes/Diagnostic';
import EditSite from './Routes/EditSite';
import ImageUpload from './Routes/ImageUpload';
import OverViewMap from './Routes/OverviewMap';
import PrivacyPolicy from './Routes/PrivacyPolicy';
import SensorConfiguration from './Routes/SensorConfiguration';
import TimeControl from './Routes/Time';
import Welcome from './Routes/Welcome';
import Help from './Routes/Help';

interface AppMainProps {
  menuVisible: boolean;
  menuRightVisible: boolean;
  setMenuRightVisible: any;
  setBreadCrumb: any;
  isAdmin: boolean;
}

const AppMain = ({ menuVisible, setMenuRightVisible, setBreadCrumb, isAdmin }: AppMainProps) => {
  const segmentRef = useRef(null);

  return (
    <Sidebar.Pushable id="AppMain">
      <Sidebar.Pusher
        style={{
          width: menuVisible ? 'calc(100% - 150px)' : '100%',
          minHeight: 'calc(100vh - 60px)',
          left: menuVisible ? '150px' : 0,
          transition: 'all 0.5s ease',
        }}
      >
        <Fragment>
          <Ref innerRef={segmentRef}>
            <ErrorBoundary>
              <Switch>
                <Route
                  path="/dashboard/:siteID?"
                  render={(props) => <Dashboard setBreadCrumb={setBreadCrumb} {...props} />}
                />
                <Route path="/siteedit/:siteID?" render={() => <EditSite />} />
                <Route
                  path="/cageoverview/:siteID?/:cageID?"
                  render={(props) => <CageOverview setMenuRightVisible={setMenuRightVisible} {...props} />}
                />
                <Route exact path="/" render={() => <OverViewMap />} />
                <Route exact path="/welcome" render={() => <Welcome />} />
                {isAdmin && (
                  <Route path="/diagnostic/:siteID" render={() => <Diagnostic spectrumUrl="/api/v1/spectrum" />} />
                )}
                <Route path="/about" render={(props) => <About />} />
                <Route path="/help" render={(props) => <Help />} />
                <Route path="/welcome" render={(props) => <Welcome />} />
                <Route path="/analytics/:siteID" render={(props) => <Analytics {...props}/>} />
                <Route path="/imageUpload/:siteID" render={() => <ImageUpload />} />
                <Route path="/time/:siteID" render={() => <TimeControl />} />
                {isAdmin && (
                  <Route
                    path="/admin"
                    render={() => {
                      return <Admin />;
                    }}
                  />
                )}
                {
                  /* Disabled because it doesn't work in 2.0.0 TODO:Fix it with topside release */
                  false && isAdmin && (
                    <Route path="/sensorconfig/:siteID" render={(props) => <SensorConfiguration {...props} />}></Route>
                  )
                }
                <Route
                  path="/privacy"
                  render={() => <PrivacyPolicy />}
                />
              </Switch>
            </ErrorBoundary>
          </Ref>
        </Fragment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default AppMain;

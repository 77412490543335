import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Button, Icon, Input, Loader, Popup } from 'semantic-ui-react';
import NotyfContext from '../Components/NotyfContext';
import { AuthFetcher } from '../lib/fetch';
import useStore from '../Store/Store';

interface CsvDownloadProps {
  sensors: string[];
  to: number;
  from: number;
}

const CsvDownload = observer(({ sensors, to, from }: CsvDownloadProps) => {
  const { authStore } = useStore();
  const [name, setName] = useState<string>('exported-sensor-report');
  const [inProgress, setInProgess] = useState<boolean>(false);
  const notyf = useContext(NotyfContext);
  const showLoader = () => {
    if (inProgress) {
      return <Loader active inline size="small"></Loader>;
    }
    return (
      <Popup
        trigger={
          <div
            style={{ width: '100%' }}
            className='d-flex align-items-center'
          >
            <Icon name="download" />Download
          </div>
        }
        content='Download the sensor report as CSV'
      />
    );
  };

  const handleClientsDownload = async () => {
    setInProgess(true);
    console.dir(sensors)
    const response = await AuthFetcher(
      '/api/v1/download/sensor/csv/from/' + from + '/to/' + to + '?' + sensors.map((sensor:any) => 'sensor=' + sensor.node_key).join('&'),
      authStore.getTokenFunction(),
      {
        headers: {
          Accepts: '*/*',
          'Content-Type': 'text/plain; charset=utf-8',
        },
        raw: true,
      }
    );
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name + `.csv`);
      document.body.appendChild(link);
      link.click();
      notyf.success('Report generated');
    } else {
      notyf.error('unable to download');
    }
    setInProgess(false);
  };

  return (
    <Popup
      as={Button}
      on="click"
      position='bottom center'
      content={
        <div className='text-left'>
          <h5>Download Sensor Report</h5>
          <Input
            placeholder={name}
            onChange={(e, data) => {
              setName(data.value);
            }}
          >
            <input
              placeholder='Enter file name for the sensor report'
              value={name}
            />
            <Button
              floated="right"
              color='blue'
              onClick={handleClientsDownload}
              disabled={!name?.length}
              attached="left"
            >
              {showLoader()}
            </Button>
          </Input>
        </div>

      }
      trigger={
        <Button
          icon="download"
          color='blue'
          title="Download sensor report"
        />
      }
    ></Popup>
  );
});

export default CsvDownload;

// This step is performed locally on the Gateway, hence no Auth is required.
import { observer } from 'mobx-react';
import { Grid, Icon, Message } from 'semantic-ui-react';
import useStore from '../../Store/Store';
import useSWR from 'swr';
import { AuthFetcher } from '../../lib/fetch';

const UserGatewaysSelect = ({ gateways, siteStore, firstTimeSetupStore }: any) => {
  const sites = siteStore.getSites()
  const selectGateway = (gatewayName: string) => {
    // Find the site ID from the sites
    firstTimeSetupStore.setActiveGatewayId(gatewayName)
    const siteConnectedToGateway = sites.filter((e: any) => e.topside_id.toLowerCase() === gatewayName.toLowerCase())

    // We've found an exact match (there can't be more than 1)
    if (siteConnectedToGateway.length === 1) {
      siteStore.setSiteKey(siteConnectedToGateway[0].node_key)
    }
  }
  /**
 * Computational getter for what site a gateway belongs to. Uses the siteStore and iterates
 * each entry for the provided gatewayName
 * 
 * @param gatewayName 
 */
  const siteName = (gatewayName: string) => {
    const results = sites.filter((e: any) => e.topside_id.toLowerCase() === gatewayName.toLowerCase())
    // There must be exactly one site that is connected to this gateway
    return results.length === 1 ? results[0].name : `Not connected to a site (${results.length})`
  }
  const radioOptions = gateways.map((gatewayName: string, index: number) => {
    const activeGatewayId = firstTimeSetupStore.getActiveGatewayId();
    return (
      <Grid.Column
        key={`form-field-${index}`}
        className="col rounded hover-intent"
        onClick={() => selectGateway(gatewayName)}
      >
        <div
          className={
            (activeGatewayId === gatewayName ? 'bg-dark-green text-white text-bold' : 'bg-light-gray') +
            ' rounded p-2'
          }
        >
          <div className='text-capitalize'>
            {activeGatewayId === gatewayName && <Icon name="chevron right" size="small" />}
            {gatewayName}
          </div>
          <div className='text-sm mt-1'>
            {siteName(gatewayName)}
          </div>
        </div>
      </Grid.Column>
    );
  });
  return (
    <Grid columns={2} divided>
      {radioOptions}
    </Grid>
  );
};

const SetupInternet = observer(() => {
  const { appStore, firstTimeSetupStore, siteStore, authStore } = useStore();
  const currentStep = firstTimeSetupStore.getCurrentStep();
  let activeGatewayId = firstTimeSetupStore.getActiveGatewayId();
  let gatewayStatus = null;
  let internetConnection = false;
  let cloudConnection = false;

  // We'll only fetch gateway status once an activate gateway ID has been set
  let { data: gatewayData = null, error: gatewayError = null } = useSWR(
    activeGatewayId ? `/api/v1/status/${activeGatewayId}` : null,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {
      refreshInterval: 1000,
    }
  );
  const userInfo: any = appStore.getUser();
  /**
 * Computational getter for all requirements for this step to be complete
 * @returns boolean
 */
  const checksOk = () => {
    return gatewayData && internetConnection === true && cloudConnection === true;
  }

  let userGateways = [];
  if ('https://cagesense.cloud/topsideIds' in userInfo.auth0) {
    userGateways = userInfo.auth0['https://cagesense.cloud/topsideIds'];
  }

  if (gatewayError) {
    console.error('We could not fetch info about the Gateway.' + gatewayError);
  }

  if (!gatewayError && gatewayData) {
    // We have information about the Gateway, set the ID and internet status
    gatewayStatus = gatewayData.topside_status;
    internetConnection = gatewayStatus.time_sync.status;
    cloudConnection = gatewayStatus.cloud.status;
  }

  if (checksOk()) {
    // We have both an ID for the Gateway and an internet connection. Good times.
    firstTimeSetupStore.setStepComplete(currentStep);
  } else {
    // We're missing either of. This step will not be completable until they're available.
    firstTimeSetupStore.setStepIncomplete(currentStep);
  }



  return (
    <div>
      <img
        src="/images/first-time-setup-connect-ethernet.jpg"
        alt="Step 1 - Connect gateway"
        className="w-100 rounded mb-2"
      />
      <p>Connect the included network cable to the CageSense&trade; Gateway and to the internet.</p>
      <p>Select your gateway from the list below. We'll check automatically for an internet connection.</p>
      <UserGatewaysSelect
        gateways={userGateways}
        siteStore={siteStore}
        firstTimeSetupStore={firstTimeSetupStore}
      />
      {(!userGateways || !userGateways.length) && (
        <p className="text-sm my-2">Cannot find your gateway? Please contact <a href="mailto:support@waterlinked.com">support@waterlinked.com</a> for help.</p>
      )}
      {activeGatewayId && (
        // Gateway info exists, so we can display this info
        <div className="mt-3">
          <Message color={checksOk() ? 'grey' : 'red'}>
            <Message.Header>
              Status: {gatewayStatus?.topside_id || `${activeGatewayId} wasn't found.`}
            </Message.Header>
            <Grid columns={3} className="mt-1">
              <Grid.Column mobile={16} computer={6}>
                <div title={gatewayStatus ? gatewayStatus?.time_sync?.message : 'N/A'}>
                  <Icon
                    name="wifi"
                    className="me-1"
                    color='black'
                  />
                  <strong>Internet connection</strong>
                  <Icon
                    name={internetConnection === true ? 'check' : 'cancel'}
                    color={internetConnection === true ? 'green' : 'red'}
                    className="ms-2"
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} computer={6}>
                <div title={gatewayStatus ? gatewayStatus?.cloud?.message : 'N/A'}>
                  <Icon
                    name="cloud"
                    color='black'
                    className="me-1"
                  />
                  <strong>Water Linked Cloud</strong>
                  <Icon
                    name={cloudConnection === true ? 'check' : 'cancel'}
                    color={cloudConnection === true ? 'green' : 'red'}
                    className="ms-2"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={16}>
                {cloudConnection === true && internetConnection === true
                  ? `Looks good! "${activeGatewayId}" is connected to the Internet and Water Linked.`
                  : `Seems like "${activeGatewayId}" is not connected to the Internet and/or Water Linked.`}
              </Grid.Column>
            </Grid>
          </Message>
        </div>
      )}
    </div>
  );
});

export default SetupInternet;

import { useState } from "react";
import { Accordion, Icon, Segment } from "semantic-ui-react";
import { getSensorImagePath } from "../lib/functionality";

const Help = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const handleClick = (e: any, titleProps: any) => {
    // Retrieve properties active and index from titleProps
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  const helpContents = [
    {
      title: 'Sensor - Lys-, og lydsignaler',
      image: 'three-circles-90deg.svg',
      ingress: 'Informasjon om hva lys-, og lydsignaler som vises og høres på sensorene betyr.',
      description: <>
        <h5>Lyssignaler (LED)</h5>
        Hva betyr egentlig de forskjellige lys-, og lydsignalene som sensorene viser?
        <ul>
          <li><strong>Pulserende grønn</strong>: Sensoren lader (står på ladeplaten)</li>
          <li><strong>Solid grønn</strong>: Sensoren er fulladet</li>
          <li><strong>Blinkende grønn</strong>: Sensoren er tatt av ladeplaten og er klar til bruk</li>
          <li><strong>Pulserende oransje</strong>: Sensoren lader og er satt i lagringsmodi</li>
          <li><strong>Solid oransje</strong>: Sensoren er fulladet og er satt i lagringsmodi</li>
          <li><strong>Blinkende oransje</strong>: Sensoren er tatt av ladeplaten og i lagringsmodi, men er ikke klar for bruk</li>
          <li><strong>Solid blå</strong>: Sensoren er tilkoblet via blåtann</li>
        </ul>

        <h5>Lydsignaler</h5>
        <ul>
          <li>Kort, positiv melodi: Sensoren er tatt av ladeplaten og klar til bruk</li>
        </ul>
      </>,
      items: null
    },
    {
      title: 'Fiskesymptomer - Hvordan oppdage de',
      image: 'salmon.svg',
      ingress: 'Fiskevelvære starter med å forstå forskjellige symptomer som kan oppstå og hvordan å overvåke og forhindre dem i fremtiden. Nedenfor har vi listet de vanligste symptomene og hvilke sensorer fra Water Linked som kan hjelpe deg.',
      description: '',
      items: [
        {
          title: 'Hva er hypoksi?',
          content: (
            <>
              <p>Hypoksi er (utilstrekkelig tilførsel eller tilgang til oksygen).</p>
              <p>
                Siden laks er vekselvarme vil både metabolsk rate og oksygenbehov øke ved økende
                vanntemperatur. I det ligger at fisk forbruker mer oksygen ved høyere vanntemperatur. Ettersom
                løseligheten til oksygen avtar med økende vanntemperatur er kontinuerlig overvåkning av
                oksygenmetningen i en oppdrettsmerd helt avgjørende for å unngå at fisken opplever episoder
                med for lavt oksygeninnhold i vannet. Skulle dette skje vil laksen respondere med en
                stressrespons og påfølgende appetitt tap, vekstreduksjon og i ytterste konsekvens, død.</p>
            </>
          )
        },
        {
          title: 'Utstående øyne/synssvekkelse/blindhet',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Oksygenmangel, Underernæring, Feilernæring
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Gjellelokkfrekvens',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Alger, Hypoksi, Amøber, Sykdom
              </p>
              <h4>Detaljer</h4>
              <p>
                Fisk som opplever stressende situasjoner av forskjellige
                årsaker vil ofte forsøke å kompensere ved hjelp av økt
                gjellelokkfrekvens.
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('turbidity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Turbiditet
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Kondisjonsfaktor',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Oksygenmangel, Underernæring, Stress, Parasitter,
                Redusert vannkvalitet
              </p>
              <h4>Detaljer</h4>
              <p>
                Kondisjonsfaktor (K) er et mål på fiskens ernæringsstatus og
                beregnes som 100 × Vekt (g)/ Lengde3(cm). Jo høyere K,
                desto fetere er fisken.
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur</li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('turbidity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Turbiditet
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Unngåelsesadferd',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Hypoksi, Alger, Vannkvalitet, Predatorer,
                Trengeoperasjoner
              </p>
              <h4>Detaljer</h4>
              <p>
                Laks som opplever en negativ utvikling i basale
                miljøparametere, økt predatortrykk eller andre forstyrrende
                faktorer vil instinktivt forsøke å søke seg bort.
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('turbidity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Turbiditet
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Osmotisk balanse',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Smoltkvalitet, Avlusning med ferskvann,
                Elvepåvirkning
              </p>
              <h4>Detaljer</h4>
              <p>
                Laks har en naturlig lavere ionekonsentrasjon sammenlignet
                med naturlig sjøvann. Ved langvarige eksponering for
                betydelig lavere eller høyere verdier enn naturlig vil laks
                kunne oppleve problemer med den osmotiske balansen.
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('salinity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Salinitet
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Svømmehastighet',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Oksygenmetning, Temperatur, Fôrtilgang, Predatorpåvirkning
              </p>
              <h4>Detaljer</h4>
              <p>
                Laks vil i enkelte tilfeller reagere med endring av
                svømmehastighet som er resultat av ytre eller indre
                påvirkninger. For eksempel vil tilstedeværelse av predatorer
                kunne gi en betydelig økt svømmehastighet. Motsatt vil laks
                utsatt for oksygenbrist redusere hastigheten
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Appetitt-tap',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Hypoksi, Stress, Sykdom, Osmotisk stress, Alger
              </p>
              <h4>Detaljer</h4>
              <p>
                Appetitt hos laks er nøye regulert av fiskens tilstand både
                fysiologisk og gjennom ytre påvirkninger. Ved tilfeller av f.eks.
                betydelig oksygendropp vil laksens appetitt reduseres og
                dermed fòropptak reduseres.
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">

                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('turbidity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Turbiditet
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('salinity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Salinitet
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Termisk påvirkning',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Vekst, Fôropptak, Stress
              </p>
              <h4>Detaljer</h4>
              <p>
                Fisk er vekselvarm, det vil si de kan ikke produsere egen varme og er
                derfor avhengig av temperaturen i omgivelsene. Skal fiskens
                enzymatiske prosesser som f.eks. hormonell og nervøs kontroll,
                fordøyelse, respirasjon, osmoregulering fungere optimalt er
                temperaturen avgjørende. Laks har et temperaturoptimum på 13-14
                grader som den vil søke aktivt mot. Store avvik fra den ideelle
                temperaturen vil kunne gi seg utslag i nedsatt appetitt, stressadferd
                og i ytterste konsekvens, død
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Sårdannelse',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Temperatur, Fórtilgang, Oksygensvikt, Sykdom
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('oxygen')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Oksygen
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur</li>
              </ul>
            </>
          )
        },
        {
          title: 'Lusepåslag',
          content: (
            <>
              <h4>Årsak</h4>
              <p>
                Brakkvannspåvirkning, Temperatur, Strøm, Naturlig lys/ årstid
              </p>
              <h4>CageSense&trade; sensorer som monitorerer dette</h4>
              <ul className="d-flex">
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('temperature')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Temperatur
                </li>
                <li className="d-flex align-items-center me-2">
                  <img
                    src={getSensorImagePath('salinity')}
                    alt="Sensor illustration"
                    width={30}
                    className="me-2"
                  />
                  Salinitet
                </li>
              </ul>
            </>
          )
        },
      ]
    }
  ]
  return (
    <div>
      <Segment>
        <h1>Hjelp</h1>
        <p>Velkommen til CageSense&trade; hjelp! Vi har delt hjelpen opp i seksjoner for sensorer og fiskesymptomer, hva du kan gjøre og hvilken sensorer fra Water Linked som hjelper deg å utbedre årsaken(e).</p>
        <p>
          CageSense&trade; lar deg overvåke en rekke velferdsindikatorer som er viktige for den daglige driften:
        </p>
        <ul>
          <li>OVI-er (også passive) som enkelt kan være en del av den daglige driften</li>
          <li>CageSense&trade; miljøparametere (ytre påvirkninger)</li>
          <li>Scenarioindikasjon på oversiktsiden for din installasjon</li>
        </ul>
        {helpContents.map((category, categoryIndex) => {
          return (
            <div key={`help-item-category-${categoryIndex}`}>
              {/* Render each category's title and ingress */}
              <h2 className="d-flex align-items-center">
                <img
                  src={`/images/${category.image}`}
                  alt="Section description"
                  className="me-2"
                  style={{ width: 50 }
                  }
                />
                {category.title}
              </h2>
              <div className="my-2">
                {category.ingress}
              </div>
              {category.description}
              {/* Output category items (for the accordion), if any are present */}
              {category?.items &&
                <Accordion
                  fluid
                  styled
                >
                  {/* Render each category's list of items (topics) */}
                  {category.items.map((item, itemIndex) => {
                    return (
                      <div key={`category-${itemIndex}`}>
                        <Accordion.Title
                          active={activeIndex === itemIndex}
                          index={itemIndex}
                          onClick={handleClick}
                          key={`category-title-${itemIndex}`}
                        >
                          <Icon
                            name={activeIndex === itemIndex ? 'chevron up' : 'chevron down'}
                          />
                          {item.title}
                        </Accordion.Title>
                        <Accordion.Content
                          active={activeIndex === itemIndex}
                          key={`category-content-${itemIndex}`}
                        >
                          {item.content}
                        </Accordion.Content>
                      </div>
                    )
                  })}
                </Accordion>
              }
            </div>
          )
        })}
      </Segment>
    </div>
  )
}

export default Help
import { createMedia } from "@artsy/fresnel"
import { observer } from "mobx-react"
import { Link, useLocation } from "react-router-dom"
import { Sticky, Menu, Icon, Image, Header, Popup } from "semantic-ui-react"
import useStore from "./Store/Store"
import logo from './images/waterlinked-logo.png';
import SiteSelect from './Components/UIElements/SiteSelect';

interface TopMenuProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
  displayIcon: any;
  breadCrumb: string;
  setBreadCrumb: any;
}
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 600,
  },
});

interface BreadCrumbProps {
  text: string;
}

const BreadCrumb = ({ text }: BreadCrumbProps) => {
  return (
    <>
      {text && text !== 'CageSense' ? (
        <>
          <span style={{ width: '2em', textAlignLast: 'center' }}>|</span>
          <Header as="h3" color="green" style={{ marginTop: '5px' }}>
            {text}
          </Header>
        </>
      ) : null}
    </>
  );
};

const TopMenu = observer(({ toggleMenu, displayIcon, breadCrumb, setBreadCrumb }: TopMenuProps) => {
  const { appStore } = useStore();
  const location = useLocation()
  const displaySiteSelect = !location.pathname.startsWith('/welcome') && location.pathname !== '/'
  return (
    <Sticky>
      <Menu secondary fluid attached="top" color="blue" inverted={true} style={{ height: '60px' }}>
        {displayIcon === true && (
          <Menu.Item onClick={toggleMenu} style={{ textAlignLast: 'center' }}>
            <Icon name={false ? 'angle double left' : 'sidebar'} size="large" />
          </Menu.Item>
        )}
        <Menu.Item style={{ fontSize: '20px', marginTop: '2px' }}>
          <Link onClick={() => appStore.setBreadcrumb('Overview Map')} to="/">
            CageSense &trade;
          </Link>
          <BreadCrumb text={appStore.getBreadcrumb()} />
        </Menu.Item>
        <Menu.Item>
          {
          /* Display the site select if not on the overview or welcome screen as this would interfere with the interface/ux */
          displaySiteSelect  &&
          <SiteSelect />
          }
        </Menu.Item>
        <Menu.Menu position="right">
          <MediaContextProvider>
            <Media greaterThanOrEqual="md">
              <Menu.Item>
                <Popup
                  trigger={
                    <a
                      href='https://waterlinked.com'
                      rel='noreferrer'
                      target='_blank'
                    >
                      <Image
                        size='small'
                        src={logo}
                        className="me-1"
                      />
                    </a>
                  }
                  content="Visit Waterlinked.com"
                  position="bottom center"
                />
              </Menu.Item>
            </Media>
          </MediaContextProvider>
        </Menu.Menu>
      </Menu>
    </Sticky>)
})

export default TopMenu;
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from 'semantic-ui-react'
import { AuthFetcher } from '../lib/fetch';
import useStore from '../Store/Store';
import './LEDButton.css'

const BlinkLED = (getAccessTokenSilently: () => Promise<string>, siteKey: string) => {
  return AuthFetcher('/api/v1/about/led/' + siteKey, getAccessTokenSilently, { raw: true })
    .then((r) => r.ok)
    .catch((e) => {
      console.log('Error: ', e.toString());
      return false;
    });
};

function useLedBlink(getAccessTokenSilently: () => Promise<string>, siteKey: string): [boolean, boolean, () => void] {
  const [isActive, setIsActive] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const onClick = () => {
    if (isActive) {
      return;
    }
    setIsActive(true);
    setIsSubmitFail(false);
    BlinkLED(getAccessTokenSilently, siteKey).then((ok) => {
      if (ok) {
        console.log('led success');
        setTimeout(() => {
          setIsActive(false);
        }, 3000);
      } else {
        console.log('led fail');
        setIsSubmitFail(true);
        setIsActive(false);
        setTimeout(() => {
          setIsSubmitFail(false);
        }, 3000);
      }
    });
  };
  return [isActive, isSubmitFail, onClick];
}

const LEDButton = observer(() => {
  const { authStore, siteStore } = useStore();
  const [isActive, isSubmitFail, onClick] = useLedBlink(authStore.getTokenFunction(), siteStore.getSiteKey());

  return (
    <Button
      onClick={onClick}
      icon
      color={isSubmitFail ? 'red' : isActive ? 'green' : undefined}
    >
      Flash Led
    </Button>
  );
});

export { LEDButton, BlinkLED }

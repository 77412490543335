/*
* A message component that tries to standardize the look and feel 
* of informational boxes through formatting, colors and icons.
*/
import { Icon, Message, SemanticCOLORS, SemanticICONS } from "semantic-ui-react"

interface props {
  heading?: string | '',
  message: string | '',
  icon: SemanticICONS | 'check',
  iconColor?: SemanticCOLORS | 'blue',
  loading?: boolean | false,
}
const MessageBox = ({ heading, message, icon, iconColor, loading = false }: props) => {
  return (
    <Message
      icon
      className='p-3'
    >
      <Icon
        name={icon}
        color={iconColor}
        loading={loading}
      />
      <Message.Content>
        {heading?.length &&
          <Message.Header className="pb-1">
            {heading}
          </Message.Header>
        }
        {message}
      </Message.Content>
    </Message>
  )
}

export default MessageBox
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button, Form, Icon, Image, Segment, Select } from 'semantic-ui-react';
import useStore from '../Store/Store';

const ImageUpload = observer(() => {
  const { appStore, authStore } = useStore();
  const [imageFile, setFile] = useState<File>();
  const [displayImage, setDisplayImage] = useState<any>([true, true, true]);

  const fileUpload = async (file: any) => {
    const url = '/api/v1/upload/logo/' + logoNum;
    const formData = new FormData();
    formData.append('file', file);
    console.log(formData.get('file'));
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + (await authStore.getTokenFunction()),
      }),
      body: formData,
    });
  };
  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (!logoNum) {
      alert('Choose logo number first...');
      return;
    }
    fileUpload(imageFile)
      .then((response) => console.log('response->', response))
      .catch((error) => console.log('error->', error));
  };
  const onFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const onDeleteImage = (i: number, e: any) => {
    const url = '/api/v1/upload/logo/' + i;
    fetch(url, {
      method: 'DELETE',
    })
      .then((response) => console.log('response->', response))
      .catch((error) => console.log('error->', error));
  };

  const [logoNum, setLogoNum] = useState<any>();
  appStore.setBreadcrumb('Image Upload');

  return (
    <Segment>
      <Form onSubmit={onFormSubmit}>
        <p>Choose image file by clicking the button or drag and drop the file in the area below </p>
        <input id="drop-area" type="file" onChange={onFileChange} style={{ maxWidth: '300px', height: '45px' }} />
        <Select
          clearable
          style={{ height: '45px' }}
          placeholder="Choose logo position"
          options={[
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
          ]}
          value={logoNum}
          onChange={(e, { value }) => setLogoNum(value)}
        />
        <p>
          <Button type="submit" icon primary labelPosition="right" disabled={!imageFile || !logoNum}>
            Upload
            <Icon name="arrow right"></Icon>
          </Button>
        </p>
        {displayImage[0] && (
          <Segment>
            <Image
              src="user/logo-1.png"
              onError={(e: any) => {
                e.currentTarget.style.display = 'none';
                setDisplayImage((displayImage: any[]) =>
                  displayImage.map((display, index: number) => (index === 0 ? false : display))
                );
              }}
            ></Image>
            <Button onClick={(e) => onDeleteImage(1, e)}>DELETE IMAGE 1</Button>
          </Segment>
        )}
        {displayImage[1] && (
          <Segment>
            <Image
              src="user/logo-2.png"
              onError={(e: any) => {
                e.currentTarget.style.display = 'none';
                setDisplayImage((displayImage: any[]) =>
                  displayImage.map((display, index: number) => (index === 1 ? false : display))
                );
              }}
            ></Image>
            <Button onClick={(e) => onDeleteImage(2, e)}>DELETE IMAGE 2</Button>
          </Segment>
        )}
        {displayImage[2] && (
          <Segment>
            <Image
              src="user/logo-3.png"
              onError={(e: any) => {
                e.currentTarget.style.display = 'none';
                setDisplayImage((displayImage: any[]) =>
                  displayImage.map((display, index: number) => (index === 2 ? false : display))
                );
              }}
            ></Image>
            <Button onClick={(e) => onDeleteImage(2, e)}>DELETE IMAGE 3</Button>
          </Segment>
        )}
      </Form>
    </Segment>
  );
});

export default ImageUpload;

import { MeshProps } from '@react-three/fiber';
// import React, { useRef, useMemo } from 'react'
import { ReactThreeFiber, extend, useLoader, useFrame } from '@react-three/fiber';
import { FC, useRef } from 'react';
import * as THREE from 'three';
import { MeshPhysicalMaterial } from 'three';
import { Water } from 'three-stdlib';

import '../Materials/OceanMaterial';

extend({ Water });

const OceanPlane: FC<MeshProps> = (props) => {
  const waterNormals = useLoader(THREE.TextureLoader, './WaterNormals.png');
  // const [offset, setOffset] = useState(0);

  const ref = useRef<MeshPhysicalMaterial>(null);

  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  waterNormals.repeat.set(20, 20);

  // useFrame((state, delta) => setOffset(offset + delta * 0.01));
  // waterNormals.offset.set(1, offset);

  useFrame((state, delta) => ref.current?.normalMap?.offset.set(0, ref.current.normalMap.offset.y + delta * 0.01));

  return (
    // <Suspense fallback={null}>
    //     <Ocean />
    // </Suspense>

    <mesh {...props} receiveShadow renderOrder={999}>
      <planeBufferGeometry attach="geometry" args={[5, 5]} />
      <meshPhysicalMaterial
        ref={ref}
        color={'#009dc4'}
        transparent
        opacity={0.2}
        normalMap={waterNormals}
        reflectivity={0.5}
        clearcoat={1}
        side={THREE.DoubleSide}
        depthWrite={false}
      />
    </mesh>
  );
};

// function Ocean() {
//     const ref = useRef<any>();
//     // const gl = useThree((state) => state.gl);
//     const waterNormals = useLoader(THREE.TextureLoader, './WaterNormals.png')
//     waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
//     const geom = useMemo(() => new THREE.PlaneGeometry(2048, 2048), [])
//     const config = useMemo(
//         () => ({
//             textureWidth: 512,
//             textureHeight: 512,
//             waterNormals,
//             sunDirection: new THREE.Vector3(),
//             sunColor: 0xffffff,
//             waterColor: 0x009dc4,
//             distortionScale: 0.2,
//             fog: true
//         }), [waterNormals])

//         useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta * 0.1))
//         return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI /2} />

// }

declare global {
  namespace JSX {
    interface IntrinsicElements {
      water: ReactThreeFiber.Object3DNode<Water, typeof Water>;
    }
  }
}

export default OceanPlane;

import {
  useGLTF,
  Html,
  /*, QuadraticBezierLine*/
} from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import rgba from 'color-normalize';
import { useRef, useState, Fragment } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import hexColors, { getColorForSeverity } from '../../../lib/colors';
import { CageContents, LICE_SKIRT, SensorLocationContents, SINKER_TUBE } from '../../../lib/types';
import '../Materials/Cage4DepthMaterial';

interface BottomRingProps {
  cagePosition: [number, number, number];
  color: [number, number, number];
  depth: number;
}

type BottomRingGLTFResult = GLTF & {
  nodes: {
    Torus: THREE.Mesh;
  };
  materials: {};
};

const BottomRingSetup = (props: BottomRingProps) => {
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/bunnring.glb') as BottomRingGLTFResult;
  const depthPos = props.depth * (-25.5 / 40);
  return (
    <Fragment>
      <group ref={group} dispose={null} scale={105}>
        <mesh geometry={nodes.Torus.geometry} material={nodes.Torus.material} position={[0, depthPos, 0]}>
          <meshStandardMaterial color={props.color} emissiveIntensity={1} />
        </mesh>
      </group>
    </Fragment>
  );
};

type ShieldingSkirtGLTFResult = GLTF & {
  nodes: {
    Torus001: THREE.Mesh;
  };
  materials: {};
};

interface ShieldingSkirtProps {
  cagePosition: [number, number, number];
  color: [number, number, number];
}

const ShieldingSkirtSetup = (props: ShieldingSkirtProps) => {
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/ShieldingSkirt.glb') as ShieldingSkirtGLTFResult;

  return (
    <Fragment>
      <group ref={group} dispose={null} scale={105}>
        <mesh geometry={nodes.Torus001.geometry} material={nodes.Torus001.material} position={[0, -6.5, 0]}>
          <meshStandardMaterial color={props.color} emissiveIntensity={1} />
        </mesh>
      </group>
    </Fragment>
  );
};

interface CageProps {
  cageData: CageContents;
  setHoveredCageKey: any;
  showColor?: boolean;
}

interface SLGroup {
  sensorLocations: SensorLocationContents[];
  min_depth: number;
  max_depth: number;
}

type GLTFResult = GLTF & {
  nodes: {
    Cage: THREE.Mesh;
    AnchorPointNE: THREE.Mesh;
    AnchorPointNW: THREE.Mesh;
    AnchorPointSE: THREE.Mesh;
    AnchorPointSW: THREE.Mesh;
    Nett: THREE.Mesh;
    Walkway: THREE.Mesh;
  };
  materials: {};
};
const standardScale: number = 0.0148;
const standardSize: number = 160;
// const highlightScaling: number = 0.016/0.0148;
const standardWalkwayColor = '#555555';
const highlightWalkwayColor = '#1D3556';
const yOffset: number = 1.1;
const waterSurfaceConst = -350;
const meterEquivalent = 1.5 / 40;

const findScaleFromCircumference = (circumference: number) => {
  return (circumference * standardScale) / standardSize;
};

const Cage = ({ cageData, setHoveredCageKey, showColor }: CageProps) => {
  const scale = findScaleFromCircumference(cageData.meta.circumference);

  const [walkWayColor, setWalkwayColor] = useState(standardWalkwayColor);
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/Cage_Polarcirkel_withNett.glb') as GLTFResult;

  const netTexture = useLoader(THREE.TextureLoader, 'CageNet.png');
  netTexture.wrapS = netTexture.wrapT = THREE.RepeatWrapping;
  netTexture.repeat.set(10, 10);
  const newPosition: [number, number, number] = [
    cageData.position[0],
    cageData.position[1] + yOffset,
    cageData.position[2],
  ];

  const heightFromMaxMin = (max: number, min: number) => {
    return meterEquivalent * (max - min);
  };
  const addOrCreate = (sl: SensorLocationContents, slGroups: SLGroup[]) => {
    var added = false;
    for (var slGroup of slGroups) {
      if (slGroup.max_depth === sl.meta.max_depth && slGroup.min_depth === sl.meta.min_depth) {
        slGroup.sensorLocations.push(sl);
        added = true;
      }
    }
    if (!added) {
      slGroups.push({ sensorLocations: [sl], max_depth: sl.meta.max_depth, min_depth: sl.meta.min_depth });
    }
    return slGroups;
  };
  var slGroups: SLGroup[] = [];
  cageData &&
    cageData.sensor_locations
      .filter((sensorLoc: SensorLocationContents) => sensorLoc.meta?.type === 'depth')
      .forEach((sl: SensorLocationContents) => addOrCreate(sl, slGroups));
  //  const randomStatus= (index:number)=>{
  //    return index%3
  //  }
  var maxDepth = slGroups
    .map((slGroup) => slGroup.max_depth)
    .reduce((prev, current) => (prev > current ? prev : current), 5);
  const cageScale = (maxDepth / 30) * 0.75;
  const shieldingSkirt = cageData.sensor_locations.find((slc) => {
    return slc.meta.type === LICE_SKIRT;
  });
  const sinkerTube = cageData.sensor_locations.find((slc) => {
    return slc.meta.type === SINKER_TUBE;
  });

  return (
    <group
      ref={group}
      position={newPosition}
      rotation={[0, 45 * (Math.PI / 180), 0]}
      dispose={null}
      scale={[scale, scale, scale]}
      onPointerEnter={(e) => {
        e.stopPropagation();
        setWalkwayColor(highlightWalkwayColor);
        document.body.style.cursor = 'pointer';
        setHoveredCageKey(cageData.node_key);
      }}
      onPointerLeave={(e) => {
        setWalkwayColor(standardWalkwayColor);
        document.body.style.cursor = 'auto';
      }}
    >
      {slGroups &&
        slGroups.map((slGroup: SLGroup, index: number) => {
          const segments = slGroup.sensorLocations.map((sl: SensorLocationContents, index) => {
            return {
              level: sl.sensors.length > 0 || showColor ? sl.status.level : -1,
              left_border: (sl.meta.left_border * Math.PI) / 180.0,
              right_border: (sl.meta.right_border * Math.PI) / 180.0,
            };
          });
          //const segments = slGroup.sensorLocations.map((sl:SensorLocationContents,i)=>{return {level:randomStatus(i),left_border:(sl.meta.left_border*Math.PI/180.0),right_border:sl.meta.right_border*Math.PI/180.0}})

          return (
            <mesh
              key={'depth_key_' + slGroup.max_depth + slGroup.min_depth}
              scale={1750}
              position={[0, waterSurfaceConst - meterEquivalent * slGroup.min_depth * 1750, 0]}
            >
              <cylinderGeometry args={[1, 1, heightFromMaxMin(slGroup.max_depth, slGroup.min_depth), 64, 1, true, 0]} />
              <cage2DepthSegmentsMaterial segments={segments} side={THREE.DoubleSide} />
            </mesh>
          );
        })}
      <mesh
        geometry={nodes.Cage.geometry}
        //position={[0, -meterEquivalent * 1750 * (maxDepth-5.0), 0]}
        position={[0, -meterEquivalent * 1750 * (maxDepth - 5.25 * cageScale), 0]}
        rotation={[Math.PI / 2, 0, 0]}
        castShadow
        receiveShadow
        scale={[1, 1, cageScale]}
      >
        {<meshStandardMaterial color={rgba(hexColors.darkBlue)} transparent opacity={0.2} />}
      </mesh>
      {/* <primitive position={[0,-meterEquivalent*30*1750,0]} object={new AxesHelper(meterEquivalent*30*1750)}></primitive> */}
      <mesh
        geometry={nodes.Walkway.geometry}
        position={[0, 135, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        castShadow
        receiveShadow
      >
        <meshStandardMaterial color={walkWayColor} />
      </mesh>
      <mesh geometry={nodes.Nett.geometry} position={[0, 150, 0]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial transparent opacity={1.0} color={'#555555'} alphaMap={netTexture} depthWrite={false} />
      </mesh>

      <Html center position={[0, 500, 0]} style={{ pointerEvents: 'none' }}>
        <div style={{ fontSize: 'x-large', fontWeight: 'bold', whiteSpace: 'nowrap', color: hexColors.darkBlue }}>
          {cageData.name.length > 2 ? cageData.name : `${cageData.node_kind} ${cageData.name}`}
        </div>
      </Html>

      {sinkerTube && (
        <BottomRingSetup
          depth={(sinkerTube.meta.max_depth + sinkerTube.meta.min_depth) / 2}
          cagePosition={newPosition}
          color={
            sinkerTube.sensors.length > 0 || showColor
              ? rgba(getColorForSeverity(sinkerTube.status.level))
              : rgba(hexColors.grey)
          }
        />
      )}
      {shieldingSkirt && (
        <ShieldingSkirtSetup
          cagePosition={newPosition}
          color={
            shieldingSkirt.sensors.length > 0 || showColor
              ? rgba(getColorForSeverity(shieldingSkirt.status.level))
              : rgba(hexColors.grey)
          }
        />
      )}
    </group>
  );
};

useGLTF.preload('Models/Cage_Polarcirkel_withNett.glb');
useGLTF.preload('Models/bunnring.glb');
useGLTF.preload('Models/ShieldingSkirt.glb');

export default Cage;

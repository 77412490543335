import moment from 'moment';
import React, { Fragment, useState } from 'react';
import {
  Dropdown,
  Icon,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Loader,
  Segment,
  Dimmer,
  Header,
} from 'semantic-ui-react';
import useSWR from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { getSensorName, getSensorDepth, getSensorSegment } from '../../lib/format';
import { CageContents, SensorListItem, SensorLocationContents } from '../../lib/types';
import ConnectSensorDialogue from './ConnectSensorDialogue';
import SensorIcon from '../UIElements/SensorIcon';
import AddNewSensorDialogue from './AddNewSensorDialogue';
import DisconnectSensorDialogue from './DisconnectSensorDialogue';
import EditSensorDialogue from './EditSensorDialogue';
import useStore from '../../Store/Store';
import { observer } from 'mobx-react';

interface SensorListProps {
  cageKey: string | undefined;
  cageName: string;
}

const SensorList = observer(({ cageKey, cageName }: SensorListProps) => {
  const { siteStore, authStore } = useStore();
  const siteKey = siteStore.getSiteKey();
  const [column] = useState();
  const [direction] = useState<any>('ascending');
  const [showConnectSensor, setShowConnectSensor] = useState<boolean>(false);
  const [showEditSensor, setShowEditSensor] = useState<boolean>(false);
  const [showDisconnectSensor, setShowDisconnectSensor] = useState<boolean>(false);
  const [pickedSensor, setPickedSensor] = useState<SensorListItem>();
  const [displayUnassigned, setDisplayUnassigned] = useState(false);
  const [displayAddSensor, setDisplayAddSensor] = useState(false);
  const { data: site, error } = useSWR(siteKey ? `/api/v1/site/${siteKey}` : '', (url: string) =>
    AuthFetcher(url, authStore.getTokenFunction())
  );

  const { data: allSensors } = useSWR(siteKey ? `/api/v1/sensor/site/${siteKey}` : '', (url: string) =>
    AuthFetcher(url, authStore.getTokenFunction())
  );

  if (!allSensors) {
    return <Loader active></Loader>;
  }

  const typedAllSensors: SensorListItem[] = allSensors;

  const cage: CageContents | undefined = site && site.cages.find((cage: CageContents) => cage.node_key === cageKey);
  const cageSlKeys: string[] = cage ? cage.sensor_locations.map((sl: SensorLocationContents) => sl.node_key) : [];

  const cageSensors: SensorListItem[] = typedAllSensors
    .filter((sli: SensorListItem) => sli.sensor_location)
    .filter((sli: SensorListItem) => sli.sensor_location && cageSlKeys.includes(sli.sensor_location.node_key));

  return (
    <Dimmer.Dimmable as={Fragment}>
      <Table selectable sortable celled inverted color="blue">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'type' ? direction : null}
              // onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
            >
              Sensor
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'depth' ? direction : null}
              // onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'age' })}
            >
              Placement
            </Table.HeaderCell>
            <Table.HeaderCell
              width={5}
              sorted={column === 'battery' ? direction : null}
              // onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'gender' })}
              colSpan="2"
              textAlign="center"
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell disabled width={2}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {cageSensors.length > 0 && (
          <Table.Body>
            {cageSensors
              .filter((sli: SensorListItem) => sli.sensor_location)
              .sort((a: SensorListItem, b: SensorListItem) => {
                if (a.sensor_location && b.sensor_location) {
                  if (a.sensor_location.meta.min_depth === b.sensor_location.meta.min_depth) {
                    return a.sensor_location.meta.left_border > b.sensor_location.meta.left_border ? 1 : -1;
                  }
                  return a.sensor_location.meta.min_depth > b.sensor_location.meta.min_depth ? 1 : -1;
                }
                //Beacuase of the filter this should not happen. Complier does not agree
                return -1;
              })
              .map((sli: SensorListItem, index) => {
                return (
                  <Table.Row key={sli.sensor?.node_key || index}>
                    <Table.Cell>
                      {sli.sensor && <SensorIcon sensorType={sli.sensor?.sensor_type} />}

                      {sli.sensor && sli.sensor.name ? (
                        <Popup
                          content={sli.sensor.name}
                          trigger={
                            <span style={{ left: '40px', position: 'relative' }}>
                              {getSensorName(sli.sensor.sensor_type, sli.sensor.sensor_id, sli.sensor.name, 15)}
                            </span>
                          }
                        />
                      ) : (
                        sli.sensor && (
                          <span style={{ left: '40px', position: 'relative' }}>
                            {getSensorName(sli.sensor.sensor_type, sli.sensor_id, sli.sensor.name)}
                          </span>
                        )
                      )}
                    </Table.Cell>
                    {sli.sensor_location && (
                      <Table.Cell>
                        {getSensorDepth(sli.sensor_location.meta)}{' '}
                        {getSensorSegment(sli.sensor_location.meta)?.label
                          ? ', ' + getSensorSegment(sli.sensor_location.meta).label
                          : ''}
                      </Table.Cell>
                    )}
                    <SensorStatus sli={sli} />
                    <Table.Cell collapsing>
                      <Dropdown item direction="left" icon="ellipsis horizontal">
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setShowDisconnectSensor(true);
                              setPickedSensor(sli);
                            }}
                          >
                            <Icon name="broken chain" />
                            Disconnect placement
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setShowEditSensor(true);
                              setPickedSensor(sli);
                            }}
                          >
                            <Icon name="pencil" />
                            Edit description
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        )}

        {typedAllSensors && typedAllSensors.flat().length > 0 && (
          <Table.Header>
            <Table.Row key="unassigned_sensors">
              <Table.HeaderCell
                colSpan="5"
                onClick={() => {
                  setDisplayUnassigned((status) => !status);
                }}
              >
                <Icon name="angle right" rotated={displayUnassigned ? 'clockwise' : undefined} />
                Unassigned Sensors (
                {typedAllSensors.flat().filter((sli: SensorListItem) => !sli.sensor_location).length})
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          {displayUnassigned &&
            typedAllSensors &&
            typedAllSensors
              .flat()

              .filter((sli: SensorListItem) => !sli.sensor_location)
              .sort((a: SensorListItem, b: SensorListItem) =>
                a.sensor && b.sensor ? (a.sensor.sensor_type > b.sensor.sensor_type ? 1 : -1) : -1
              )
              .map((sli: SensorListItem, index: number) => {
                const sensorType = sli.last_message
                  ? sli.last_message.sensor_type
                  : sli.sensor
                  ? sli.sensor.sensor_type
                  : '';
                const sensorId = sli.last_message ? sli.last_message.sensor_id : sli.sensor ? sli.sensor.sensor_id : -1;
                return (
                  <Table.Row key={sli.sensor ? sli.sensor.node_key : index}>
                    <Table.Cell>
                      <SensorIcon sensorType={sensorType} />
                      <span style={{ left: '40px', position: 'relative' }}>
                        {getSensorName(sensorType, sensorId, sli.sensor?.name)}
                      </span>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <SensorStatus sli={sli} />
                    <Table.Cell>
                      <Icon
                        name="chain"
                        className="connect"
                        onClick={() => {
                          setPickedSensor(sli);
                          setShowConnectSensor(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
        </Table.Body>
        <Table.Header>
          <Table.Row key="add_sensor">
            <Table.HeaderCell
              colSpan="5"
              onClick={() => {
                setDisplayAddSensor((status) => !status);
              }}
            >
              <Icon name="plus circle" color="green" />
              Add Sensor
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <AddNewSensorDialogue
        siteKey={siteKey ? siteKey : ''}
        sensors={typedAllSensors}
        displayAddSensor={displayAddSensor}
        setDisplayAddSensor={setDisplayAddSensor}
      />
      <EditSensorDialogue
        siteKey={siteKey}
        sensor={pickedSensor?.sensor}
        display={showEditSensor}
        setDisplay={setShowEditSensor}
      />
      <DisconnectSensorDialogue
        siteKey={siteKey}
        sli={pickedSensor}
        display={showDisconnectSensor}
        setDisplay={setShowDisconnectSensor}
      />
      <ConnectSensorDialogue
        siteKey={siteKey}
        cage={cage}
        sli={pickedSensor}
        display={showConnectSensor}
        setDisplay={setShowConnectSensor}
        cageName={cageName}
      />
      <Dimmer active={error}>
        <Header as={'h2'}>Unable to load sensors</Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
});

interface SensorStatusProps {
  sli: SensorListItem;
}
const SensorStatus = ({ sli }: SensorStatusProps) => {
  const battery = sli.last_message?.battery ? sli.last_message.battery : 0;
  const timeSince = moment(sli.last_message?.ts)
    .fromNow(true)
    .replace('minute', 'min')
    .replace(/seconds/, 'sec')
    .replace(/second/, 'sec');

  const hoursSinceLastMessage = moment.duration(moment().diff(moment(sli.last_message?.ts))).asHours();

  return (
    <>
      <Table.Cell>
        <Icon
          size="large"
          color={!battery ? 'grey' : battery > 20 ? 'green' : battery > 5 ? 'yellow' : 'red'}
          name={
            battery > 75
              ? 'battery four'
              : battery > 50
              ? 'battery three'
              : battery > 25
              ? 'battery two'
              : battery > 5
              ? 'battery one'
              : 'battery zero'
          }
        />{' '}
        {battery ? battery + '%' : ''}
      </Table.Cell>
      <Table.Cell collapsing>
        <Popup
          position="left center"
          wide
          header="Last message"
          content={
            sli.last_message ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Sensor id</TableCell>
                    <TableCell>{sli.sensor_id}</TableCell>
                  </TableRow>
                  {sli.sensor?.name && (
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>{sli.sensor?.name}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    {sli.last_message && (
                      <>
                        <TableCell>Bit rate error</TableCell>
                        <TableCell>{sli.last_message ? sli.last_message.bit_error_rate.toFixed(2) : ''}</TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>
                      {sli.last_message ? moment(sli.last_message?.ts).format('H:mm D. MMM') : 'No messages'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Segment>No message received for sensor {sli.sensor_id}</Segment>
            )
          }
          trigger={
            <span style={{ color: hoursSinceLastMessage > 0 && hoursSinceLastMessage < 24 ? 'white' : 'grey' }}>
              <Icon
                name="heartbeat"
                color={hoursSinceLastMessage > 0 && hoursSinceLastMessage < 24 ? 'green' : 'grey'}
              />
              {hoursSinceLastMessage > 0 ? timeSince : ''}
            </span>
          }
        />
      </Table.Cell>
    </>
  );
};

export default SensorList;

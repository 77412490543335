import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { SensorContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';

interface EditSensorDialogueProps {
  display: boolean;
  setDisplay: (b: boolean) => void;
  siteKey: string | undefined;
  sensor?: SensorContents;
}

const EditSensorDialogue = observer(({ siteKey, sensor, display, setDisplay }: EditSensorDialogueProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore } = useStore();
  const [name, setName] = useState<string>();
  const changeName = () => {
    if (!sensor) {
      return;
    }
    const { sensor_type, sensor_id } = sensor;
    let url = '/api/v1/sensor/' + sensor_type + '/' + sensor_id + '/meta/setName';
    AuthFetcher(url, authStore.getTokenFunction(), { method: 'PUT', body: name, raw: true })
      ?.then((res) => {
        res.ok ? notyf.success('Successfully changed sensor name') : notyf.error('Could not change sensor name.');
        setDisplay(false);
        mutate(`/api/v1/site/${siteKey}`);
        mutate(`/api/v1/sensor/site/${siteKey}`);
      })
      .catch((err) => {
        notyf.error('Could not change sensor name');
        console.log(err);
      });
  };

  return (
    <Modal open={display} size="tiny" dimmer="blurring" className="blue">
      <Modal.Header style={{ color: 'black' }}>Edit sensor name/description</Modal.Header>
      <Modal.Content style={{ color: 'black' }}>
        <Form onSubmit={changeName}>
          <Input
            defaultValue={sensor?.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={(e) => setDisplay(false)} negative>
          Cancel
        </Button>
        <Button onClick={changeName} positive>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default EditSensorDialogue;

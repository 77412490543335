import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Icon, Message, List, Label, Button, Popup } from 'semantic-ui-react';
import useSWR from 'swr';

import { AuthFetcher } from '../lib/fetch';
import useStore from '../Store/Store';

interface iconWithTitleProps {
  name: any;
  color: string;
  title: string;
}

const IconWithTitle = ({ name, color, title }: iconWithTitleProps) => (
  <span style={{ color: color }}>
    <Popup trigger={<Icon name={name} />} content={title} on={['hover', 'click']} />
  </span>
);

const colorOK = '#16a04f';
const colorWarn = '#eaae00';

const VersionOK = () => (
  <IconWithTitle name="check" color={colorOK} title={'Your system is up to date with the latest software.'} />
);

interface VersionNeedsUpdateProps {
  url: string;
  version: string;
}

const VersionNeedsUpdate = ({ url, version }: VersionNeedsUpdateProps) => (
  <span>
    <span style={{ color: colorWarn }}>
      <Icon name="exclamation" />
      New version available ({version}){' '}
    </span>{' '}
    <a href={url} target="_new">
      <Button positive size="small">
        Download
      </Button>
    </a>
  </span>
);

const VersionLoadError = ({ error }: any) => (
  <IconWithTitle
    name="question"
    color={colorWarn}
    title={`Unable to contact update server. Please connect your computer to the internet to check if there is a new update ready. (${error})`}
  />
);

interface UpdateCheckProps {
  updateAvailable: boolean;
  latestRelease: string;
  latestReleaseURL: string;
  loading: boolean;
  error: string;
}

const VersionStatusIconView = ({
  updateAvailable,
  latestRelease,
  loading,
  error,
  latestReleaseURL,
}: UpdateCheckProps) => {
  if (loading) {
    return (
      <span title="Checking version">
        <Icon loading name="spinner" />
      </span>
    );
  } else if (error) {
    return <VersionLoadError error={error} />;
  } else if (updateAvailable) {
    return <VersionNeedsUpdate version={latestRelease} url={latestReleaseURL} />;
  }
  return <VersionOK />;
};

interface VersionDetailProps {
  version: string;
  versionShort: string;
  chipid: string;
  updateURL: string;
}

const VersionDetail = observer(({ version, versionShort, chipid, updateURL }: VersionDetailProps) => {
  const { authStore } = useStore();
  const latestURL = 'https://update.waterlinked.com/api/v1/latest';

  const config = useMemo(
    () => ({
      method: 'POST',
      body: { chipid: chipid, current: version },
    }),
    [version, chipid]
  );
  const {
    data: rData,
    error,
    isValidating,
  } = useSWR([latestURL, config], (url) => AuthFetcher(url, authStore.getTokenFunction()));

  return (
    <List relaxed>
      <List.Item>
        <List.Content>
          <List.Header>Software version</List.Header>
          <List.Description>
            <Label>{version} </Label>
            <VersionStatusIconView
              updateAvailable={rData?.is_latest === false ? true : false}
              latestRelease={rData?.latest_release ? rData.latest_release : ''}
              loading={isValidating}
              error={error}
              latestReleaseURL={'https://update.waterlinked.com' + (rData?.url ? rData.url : '')}
            />
            <p style={{ paddingTop: '0.5em' }}>
              <a href={updateURL}>
                <Button content="Software upgrade" color="teal" size="mini" icon="right arrow" labelPosition="left" />
              </a>
            </p>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header>Chip ID</List.Header>
          <List.Content>
            <Label>{chipid}</Label>
          </List.Content>
        </List.Content>
      </List.Item>
    </List>
  );
});

const LoadingMessage = () => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>Just a second</Message.Header>
      We are fetching the content for you.
    </Message.Content>
  </Message>
);

const ErrorMessage = (props: any) => (
  <Message icon>
    <Icon name="exclamation" />
    <Message.Content>
      <Message.Header>Failed to load content</Message.Header>
      {props.children}
    </Message.Content>
  </Message>
);

export { VersionDetail, ErrorMessage, LoadingMessage };

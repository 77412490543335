import { Button, Icon, Popup } from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import useStore from '../../Store/Store';
import appInfo from '../../../package.json';
import { AuthFetcher } from '../../lib/fetch';
import { useContext, useState } from 'react';
import NotyfContext from '../NotyfContext';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';

const Footer = () => {
  const { appStore, authStore, firstTimeSetupStore } = useStore();
  const { logout } = useAuth0();
  const history = useHistory();
  const notify = useContext(NotyfContext);
  const [saving, setSaving] = useState(false);
  // Display CageSense Local if the user isn't logged in
  let versionInfo = appStore.getUser().auth0 ? 'CageSense Cloud' : 'CageSense Local';
  let userInfo = appStore.getUser();
  versionInfo += ` ${appInfo.version}`;

  const completeSetup = async () => {
    if (!window.confirm('Are you sure you want to skip the first time setup? (You can start it later from the side menu).')) {
      return
    }

    if (!userInfo.local) {
      notify.error('There is no user information. Please contact support@waterlinked.com for assistance.');
      return
    }

    userInfo.local.first_time_setup_complete = true;
    setSaving(true);

    const results = await AuthFetcher('/api/v1/organization/setup', authStore.getTokenFunction(), {
      method: 'PUT',
      body: userInfo.local,
      raw: true,
    });

    if (results.ok) {
      // Remove the active gateway ID
      firstTimeSetupStore.setActiveGatewayId('');
      // Update the user object to include the newly set first time setup status
      appStore.setUser(userInfo.local, userInfo.auth0);
      mutate('/api/v1/organization/setup');
      /**
       * Redirect after a short wait, to accomodate saving and redirect issues
       * Trying to do this using useEffect results in all kinds of weird behaviour.
       */
      setTimeout(() => {
        // Set page breadcrumb and redirect
        appStore.setBreadcrumb('Overview Map')
        history.replace('/');
      }, 500)
    } else {
      notify.error('Updating failed, unfortunately');
      userInfo.local.first_time_setup_complete = false;
      setSaving(false);
    }
  };

  return (
    <footer className="text-right mx-3 text-white text-right">
      <div className="mb-3 text-sm">
        <Popup
          content={
            <>
              <h5>Skip first time setup?</h5>
              <p>This wizard was designed to setting up CageSense&trade; easier. Skipping may limit some features.</p>
            </>
          }
          on='hover'
          position='top right'
          trigger={
            <Button
              onClick={() => completeSetup()}
              color="black"
              size="mini"
              className='me-3'
              loading={saving === true}
              disabled={saving === true}
            >
              Skip
              <Icon name='chevron right' />
            </Button>
          }
        >

        </Popup>
        <Button onClick={() => logout()} color="red" size="mini">
          Log out
        </Button>
      </div>
      <span className="text-xs position-fixed bottom-right p-1">{versionInfo}</span>
    </footer>
  );
};

export default Footer;

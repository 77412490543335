import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Icon, Header, Form, Button, Table, Message } from 'semantic-ui-react';
import useSWR, { mutate } from 'swr';
import fetch from 'unfetch';

import { AuthFetcher } from '../lib/fetch';
import useStore from '../Store/Store';

async function SetNTP(ntp_server: string, getAccessTokenSilently: () => Promise<string>) {
  const data = {
    ntp_server: ntp_server,
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await getAccessTokenSilently()),
    },
    body: JSON.stringify(data),
  };
  return fetch('/api/v1/time/ntp', options);
}

function SetManual(time: string) {
  const data = {
    time: String(time),
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch('/api/v1/time/manual', options);
}

const refreshNTP = () => {
  mutate('/api/v1/time');
};

interface TimeProps {
  ntp_enabled: boolean;
  ntp_synchronized: boolean;
  ntp_server: string;
  time: string;
}

const CurrentNTPServer = observer(() => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/time', (url) => AuthFetcher(url, authStore.getTokenFunction()));
  if (error) {
    console.log('config load error', error);
    return (
      <Message
        icon="exclamation"
        header="Cannot load time information"
        content={'Lost connection with DVL? (' + error.toString() + ')'}
      />
    );
  }
  if (!data) {
    return <Icon name="spinner" loading />;
  }
  const timeData: TimeProps = data;

  const date = new Date(timeData.time).toString() || '?';
  return (
    <>
      <Header as="h3">System time status</Header>
      <Table basic="very" collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Time source</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{timeData.ntp_enabled ? 'Network Time Protocol' : 'Manual'}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Time synchronized from NTP</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{timeData.ntp_synchronized ? 'Yes' : 'No'}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>NTP server</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{timeData.ntp_server}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>System time</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{date}</strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button onClick={refreshNTP}>
        <Icon name="refresh" />
        Refresh{' '}
      </Button>
    </>
  );
});

type NtpSetProps = {
  ntp_server: string;
};

const NTPSet = observer(({ ntp_server }: NtpSetProps) => {
  const { authStore } = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [ntpServer, setNtpServer] = useState(ntp_server);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setIsSubmitSuccess(false);
    setSubmitError('');
    const result = await SetNTP(ntpServer, authStore.getTokenFunction());
    if (!result.ok) {
      const body = await result.json();
      console.log('submit error', body);
      setSubmitError(body.error);
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      setTimeout(() => {
        setIsSubmitSuccess(false);
      }, 5000);
    }
    setIsSubmitting(false);
    refreshNTP();
  };
  const onChangeNtpServer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNtpServer(e.target.value);
  };

  return (
    <>
      <Form.Group>
        <Form.Input
          value={ntpServer}
          name="ntpServer"
          onChange={onChangeNtpServer}
          label="NTP server (blank for default)"
        />
      </Form.Group>

      <div className="spacing">
        <Button
          icon
          positive={submitError === ''}
          negative={submitError !== ''}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Apply
          {isSubmitting && <Icon name="circle notched" loading />}
          {isSubmitSuccess && <Icon name="checkmark" />}
        </Button>
        {submitError && <p>{submitError}</p>}
      </div>
    </>
  );
});

function ManualSet() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setIsSubmitSuccess(false);
    setSubmitError('');
    const now = new Date().toISOString();
    const result = await SetManual(now);
    if (!result.ok) {
      const body = await result.text();
      console.log('submit error', body);
      setSubmitError(body);
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      setTimeout(() => {
        setIsSubmitSuccess(false);
      }, 5000);
    }
    setIsSubmitting(false);
    refreshNTP();
  };

  return (
    <>
      <Table basic="very" collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Computer time</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{currentTime.toString()}</strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <div className="spacing">
        <Button
          icon
          positive={submitError === ''}
          negative={submitError !== ''}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Set current computer time
          {isSubmitting && <Icon name="circle notched" loading />}
          {isSubmitSuccess && <Icon name="checkmark" />}
        </Button>
        {submitError && <p>{submitError}</p>}
      </div>
    </>
  );
}

type NTPConfigProps = {
  ntp_server: string;
  ntp_enabled: boolean;
};

const NTPConfig = observer(({ ntp_server, ntp_enabled }: NTPConfigProps) => {
  const [ntpEnabled, setNTPEnabled] = useState(ntp_enabled);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  return (
    <Segment style={{ paddingTop: '2em' }}>
      <Link to="/about">
        <Icon name="arrow left" />
        Return to about
      </Link>

      <Segment attached>
        <Form onSubmit={handleSubmit}>
          <div className="spacing">
            <Button
              active={ntpEnabled}
              onClick={() => {
                setNTPEnabled(true);
              }}
            >
              Network Time Protocol (NTP)
            </Button>
            <Button
              active={!ntpEnabled}
              onClick={() => {
                setNTPEnabled(false);
              }}
            >
              Manual time
            </Button>
          </div>
          <div className="spacing">{ntpEnabled ? <NTPSet ntp_server={ntp_server} /> : <ManualSet />}</div>
        </Form>
      </Segment>

      <Segment attached>
        <CurrentNTPServer />
      </Segment>
    </Segment>
  );
});

const FetchNTP = observer(() => {
  const { appStore, authStore } = useStore();
  appStore.setBreadcrumb('Time control');
  const { data, error } = useSWR('/api/v1/time', (url) => AuthFetcher(url, authStore.getTokenFunction()));
  if (error) {
    console.log('config load error', error);
    return (
      <Message icon="exclamation" header="Cannot load time information" content={'Lost connection with Cagesense?'} />
    );
  }
  if (!data) {
    return <Icon name="spinner" loading />;
  }

  const tData: TimeProps = data;
  return <NTPConfig {...tData} />;
});

export default FetchNTP;

/**
 * Displays the content for the current step of the welcome screen
 *
 * @param allSteps {Array} - Information about all steps and their status
 * @param currentStep {Number} - The current step parameter in the setup process
 * @returns mixed {Jsx.element}
 */
const StepContent = ({ allSteps, currentStep }: any) => {
  const content = allSteps[currentStep];
  return (
    <div className="text p-1">
      <h2>{content.heading}</h2>
      <div>{content.text}</div>
      <content.component />
    </div>
  );
};

export { StepContent };

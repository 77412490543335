// Contains the analytic views saved for a user
import { observer } from "mobx-react"
import { useState } from "react"
import { Button, Icon, Input, Popup, Select } from "semantic-ui-react"
import { DeleteAnalyticView, SaveAnalyticView } from "../../lib/fetch"
import moment from "moment"
import useStore from "../../Store/Store"
import MessageBox from "../Message"

const localStorageViewIndexKey = 'cagesense-view-index'

const ViewManager = observer(({ views, setAllViews, setActiveViewIndex, activeViewIndex }: { views: any, setAllViews: any, setActiveViewIndex: any, activeViewIndex: any }) => {
  const [showSaveDialogue, setShowSaveDialogue] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { analyticsStore, authStore } = useStore()

  // Get the currently active view
  let currentView = analyticsStore.getViewData()
  let viewIndex = 0
  let existingViewIndex = localStorage.getItem(localStorageViewIndexKey)
  // Overwrite the default userPreferences if one is found from local storage
  if (existingViewIndex) {
    viewIndex = JSON.parse(existingViewIndex)
    setActiveViewIndex(viewIndex)
  }

  const saveView = () => {
    setLoading(true)
    SaveAnalyticView(currentView, authStore)
      .then(response => {
        // Update the view of the selected index, then send back to update all views in parent
        views[activeViewIndex] = response
        setAllViews(views)
        // Indicate that the change has been saved
        setSaved(true)
        setShowSaveDialogue(false)
        // Change the "saved" status back to false after some seconds
        setTimeout(() => {
          setSaved(false)
        }, 2000)
      })
      .catch((e: any) => {
        setError(e.toString())
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const deleteView = () => {
    // Provide a confirmation dialogue before deleting
    if (window.confirm(`Are you sure you want to remove ${currentView?.name}?`)) {
      setLoading(true)
      DeleteAnalyticView(currentView, authStore)
        .then(success => {
          if (success === true) {
            // We need to filter out the deleted view and return the list to parent
            views = views.splice(activeViewIndex, 1)
            // If there are no views left after deletion, add an empty preset to the list
            if (!views.length || views.length === 0) {
              views.push({
                node_key: null,
                name: 'New view',
                graph_settings: {
                  live: true,
                  grouped_graphs: false,
                  data_points: 100,
                  date_from: moment(new Date()).startOf('day').subtract(new Date().getTimezoneOffset(), 'minute').unix(),
                  date_to: moment(new Date()).endOf('day').subtract(new Date().getTimezoneOffset(), 'minute').unix(),
                  graph_size: 'medium',
                },
                sensors: []
              })
            }
            setAllViews(views)
            // After deletion, set the selected view to the first in the list
            setActiveViewIndex(0)
            // Indicate that deletion is done
            setSaved(true)
            setShowSaveDialogue(false)
            // Change the "saved" status back to false after some seconds
            setTimeout(() => {
              setSaved(false)
            }, 2000)
          }
        })
        .catch((e: any) => {
          setError(e.toString())
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  let options = views?.map((view: any, index: number) => {
    return {
      key: `view-option-${index}`,
      value: index,
      text: `${view?.name ? view.name : 'Unnamed view'} ${view?.sensors?.length ? ` - ${view.sensors.length} sensor(s)` : ''}`
    }
  })
  return (
    <div>
      {views?.length > 0
        ?
        <>
          <Select
            placeholder="Select a preset"
            options={options}
            value={viewIndex}
            onChange={(e, { value }) => {
              // This calls the parent set method, changing the index to the selected view/preset
              setActiveViewIndex(value)
              // Save the setting to local storage for later use
              localStorage.setItem(localStorageViewIndexKey, JSON.stringify(value))
            }}
          />
          <Popup
            content='Use saved views to quickly return to previously selected sensors and graph options.'
            trigger={
              <Icon
                className='mx-1'
                name="question circle outline"
              />
            }
          />
        </>
        :
        <div>
          No saved views
        </div>
      }
      {showSaveDialogue ?
        (
          <>
            <Input
              placeholder="Name this analytic view"
              className="ms-1"
              value={currentView?.name}
              onChange={(e, v) => {
                analyticsStore.setViewDataName(v?.value)
              }}
            />
            <Button.Group
              className="ms-1"
            >
              <Button
                onClick={() => setShowSaveDialogue(false)}
                color='grey'
              >
                Cancel
              </Button>
              <Button
                onClick={() => saveView()}
                color='black'
                disabled={loading === true || !currentView?.name || currentView?.name?.length < 1}
              >
                Save
              </Button>
            </Button.Group>
            {currentView?.node_key !== null &&
              <Button
                onClick={() => deleteView()}
                color='red'
                disabled={loading === true}
                className="ms-2"
              >
                Delete
                <Icon
                  name="cancel"
                  className="ms-1"
                />
              </Button>
            }
            {error &&
              <MessageBox
                icon='warning'
                message={`Saving failed. ${error}`}
              />
            }
            {saved === true &&
              <span className="ms-2">
                <Icon
                  name='check circle'
                  color="blue"
                  className="me-1"
                />
                Saved!
              </span>
            }
            {loading === true &&
              <span className="ms-2">
                <Icon name="circle notch" loading />
                Saving...
              </span>
            }
          </>
        )
        :
        <>
          <Button
            onClick={() => setShowSaveDialogue(true)}
            color='black'
          >
            {/* Change the button text depending on whether it's an existing view or new */}
            {currentView?.node_key !== null ? 'Edit' : 'Save'}
          </Button>

        </>
      }

    </div>
  )
})

export default ViewManager
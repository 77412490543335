import { Link } from 'react-router-dom';
import { Segment, List, Label, Button } from 'semantic-ui-react';
import useSWR from 'swr';

import { FactoryResetButton } from '../Components/FactoryResetButton';
import { LEDButton } from '../Components/LEDButton';
import ReleaseNotes from '../Components/ReleaseNotes';
import { VersionDetail, ErrorMessage, LoadingMessage } from '../Components/VersionInfo';
import { AuthFetcher } from '../lib/fetch';

import { observer } from 'mobx-react';
import useStore from '../Store/Store';

interface AboutProps {
  chipid: string;
  hardware_revision: number;
  product_id: number;
  product_name: string;
  variant: string;
  version: string;
  version_short: string;
}

interface IpProps {
  ip: string;
}

const VersionView = () => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/about', (url) => AuthFetcher(url, authStore.getTokenFunction()));

  const aboutData: AboutProps = data;

  const proto = window.location.protocol; // http or https
  const host = window.location.host.split(':')[0]; // Remove any port (if present)
  let updateURL = `${proto}//${host}:9000`;

  if (!aboutData) return <LoadingMessage />;
  else if (error) {
    console.log('error->', error);
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <VersionDetail
      version={aboutData.version}
      versionShort={aboutData.version_short}
      chipid={aboutData.chipid}
      updateURL={updateURL}
    />
  );
};

const CurrentIP = observer(() => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/about/ip', (url) => AuthFetcher(url, authStore.getTokenFunction()));

  if (!data) return <LoadingMessage />;
  else if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  const ipData: IpProps = data;
  return (
    <List relaxed>
      <List.Item>
        <List.Content>
          <List.Header>IP address</List.Header>
          <List.Description>
            <Label>{ipData.ip} </Label>
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
  );
});

const HardwareSection = () => {
  return (
    <div>
      <h2 id="hardware">Hardware</h2>
      <Segment>
        <LEDButton />
        <p />
        <FactoryResetButton />
        <p />
        <CurrentIP />
        <p />
        <Link to="/time">
          <Button>Time setup</Button>
        </Link>
      </Segment>
    </div>
  );
};

interface AboutPageProps {
  showKnowledge?: boolean;
}

const About = observer(({ showKnowledge }: AboutPageProps) => {
  const { appStore } = useStore();
  appStore.setBreadcrumb('About');
  return (
    <Segment>
      <h1>WATER LINKED CAGESENSE</h1>

      <h2>Software</h2>
      <Segment>
        <h3>Version information</h3>
        <VersionView />

        <h3>Browser compatibility</h3>
        <p>The Web GUI has been tested and verified to work with the following browsers:</p>
        <List>
          <List.Item icon="chrome" content="Chrome 99 (Recommended)" />
          <List.Item icon="firefox" content="Firefox 98" />
        </List>
      </Segment>

      <HardwareSection />
      <h3>Release notes</h3>
      <ReleaseNotes />
    </Segment>
  );
});

export default About;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Button, Card, Transition } from 'semantic-ui-react';

import { getSensorSegment, getUnit } from '../../lib/format';
import { findMeasurementsForSensor } from '../../lib/functionality';
import { CageContents, MeasurementPair, SensorContents, SensorLocationContents } from '../../lib/types';

interface AlarmDisplayProps {
  cages: CageContents[];
  setMenuRightVisible: any;
  /*setCages: (cageContents: CageContents[]) => void;*/
}

interface AlarmProps {
  cage: string;
  cageName: string;
  depth: number;
  segment: any;
  scenario_name: string;
  severity: number;
  status: string;
  description: string;
  num: number;
  time: Date;
  sensor_location: string;
  dismissed: boolean;
  measurement: MeasurementPair[];
  setDismissed: (cageId: string, sensorLocation: string, scenarioName: string) => void;
  setMenuRightVisible: any;
  history: any;
  sensor_keys: string[];
}

const findSensorKeyFromAlarm = (sl: SensorLocationContents, measurementPairs: MeasurementPair[]) => {
  let measurements = measurementPairs.map((pair: MeasurementPair) => pair.type);
  return sl.sensors
    .filter((sensor: SensorContents) => {
      let sensorMeasurements = findMeasurementsForSensor(sensor);
      let unionList: string[] = measurements.filter((m) => !sensorMeasurements.includes(m));
      return unionList.length === 0;
    })
    .map((sensor: SensorContents) => sensor.node_key);
};

const AlarmDisplay = ({ cages, setMenuRightVisible }: AlarmDisplayProps) => {
  const history = useHistory();
  const [alarmStack, setAlarmStack] = useState<AlarmProps[]>([]);
  const [dismissAllButton, setDismissAllButton] = useState(false);

  useEffect(() => {
    const setDismissed = (cageId: string, sensorLocation: string, scenarioName: string) => {
      const newCages = [...cages];
      const cage = newCages.find((cage) => cage.node_key === cageId)!;
      const sensor_location = cage.sensor_locations.find(
        (sensor_location) => sensor_location.node_key === sensorLocation
      )!;
      const scenario = sensor_location.scenario_status.find(
        (scenario_status) => scenario_status.name === scenarioName
      )!;

      scenario.dismissed = true;
    };

    let tempalarmStack: AlarmProps[] = [];

    cages &&
      cages.forEach((cage) => {
        cage.sensor_locations.forEach((sLoc) => {
          sLoc.scenario_status.forEach((status) => {
            if (status.severity.level > 0) {
              const depth = sLoc.meta.max_depth - (sLoc.meta.max_depth - sLoc.meta.min_depth) / 2;
              const newAlarm: AlarmProps = {
                cage: cage.node_key,
                cageName: cage.name,
                depth: depth,
                segment: getSensorSegment(sLoc.meta),
                sensor_location: sLoc.node_key,
                scenario_name: status.name,
                status: status.severity.description,
                description: status.description,
                severity: status.severity.level,
                time: status.date ? status.date : new Date(),
                dismissed: status.dismissed,
                num: 1,
                measurement: status.measurements ? status.measurements : [],
                setDismissed: setDismissed,
                setMenuRightVisible: setMenuRightVisible,
                history: history,
                sensor_keys: findSensorKeyFromAlarm(sLoc, status.measurements),
              };
              tempalarmStack.push(newAlarm);
            }
          });
        });
      });

    setAlarmStack(tempalarmStack);
  }, [cages, setMenuRightVisible, history]);

  const showDismissAll = (show: any) => {
    if (show && alarmStack.filter((alarm) => !alarm.dismissed).length > 1) {
      setDismissAllButton(true);
    } else setDismissAllButton(false);
  };

  return (
    <List
      style={{
        width: '300px',
        position: 'absolute',
        right: '1%',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '-webkit-fill-available',
        textAlign: 'center',
        // pointerEvents: 'none',
      }}
      onMouseOver={() => {
        showDismissAll(true);
      }}
      onMouseLeave={() => {
        showDismissAll(false);
      }}
    >
      {dismissAllButton && (
        <Button
          basic
          color="blue"
          style={{ width: 'calc(100% - 30px)' }}
          size="tiny"
          onClick={() => {
            alarmStack.forEach((alarm) => {
              alarm.setDismissed(alarm.cage, alarm.sensor_location, alarm.scenario_name);
            });
          }}
        >
          Dismiss All
        </Button>
      )}
      {alarmStack
        .sort((a, b) => {
          if (a.severity === b.severity) {
            if (a.time.getTime() === b.time.getTime()) {
              if (a.description === b.description) return a.segment?.segmentNr > b.segment?.segmentNr ? 1 : -1;
              return a.description > b.description ? 1 : -1;
            }
            return a.time > b.time ? -1 : 1;
          }
          return a.severity > b.severity ? -1 : 1;
        })
        .map((alarm, index) => (
          <AlarmDisplayElement
            key={alarm.cage + alarm.sensor_location + alarm.scenario_name}
            cage={alarm.cage}
            cageName={alarm.cageName}
            depth={alarm.depth}
            segment={alarm.segment}
            sensor_location={alarm.sensor_location}
            scenario_name={alarm.scenario_name}
            time={alarm.time}
            severity={alarm.severity}
            status={alarm.status}
            description={alarm.description}
            num={alarm.num}
            measurement={alarm.measurement}
            dismissed={alarm.dismissed}
            setDismissed={alarm.setDismissed}
            setMenuRightVisible={setMenuRightVisible}
            history={history}
            sensor_keys={alarm.sensor_keys}
          />
        ))}
    </List>
  );
};

const AlarmDisplayElement = (alarm: AlarmProps) => {
  const classNames = ['alarm'];
  const [visible, setVisible] = useState(!alarm.dismissed);

  classNames.push('severity' + (alarm.severity < 0 ? 0 : alarm.severity));

  if (alarm.num > 1) {
    classNames.push('stacked');
    classNames.push(alarm.num === 2 ? 'two' : 'three');
  }
  const dismiss = () => {
    setVisible(false);
    alarm.setDismissed(alarm.cage, alarm.sensor_location, alarm.scenario_name);
  };
  let measurements: string = alarm.measurement
    .map((m) => {
      return m.type.slice(0, 1).toUpperCase() + m.type.slice(1) + ':' + m.measurement.toFixed(1) + getUnit(m.type);
    })
    .join(', ');

  return (
    <Transition visible={visible} animation="drop" duration={300}>
      <Card className={classNames.join(' ')}>
        <Card.Content>
          <Card.Header>{alarm.scenario_name.toUpperCase()}</Card.Header>
          <Card.Description>{measurements}</Card.Description>
          <Card.Description>
            Cage {alarm.cageName} | {alarm.depth}m {alarm.segment.label} |{' '}
            {alarm.time.toLocaleTimeString('nb-NO', { hour: '2-digit', minute: '2-digit' })}
          </Card.Description>
        </Card.Content>
        <Button.Group basic className="alarm">
          <Button
            className="alarm"
            onClick={() => {
              if (alarm) {
                alarm.history.push('/analytics/' + alarm.sensor_keys[0]);
              }
            }}
          >
            Analytics
          </Button>
          <Button className="alarm" onClick={dismiss}>
            Dismiss
          </Button>
        </Button.Group>
      </Card>
    </Transition>
  );
};

export default AlarmDisplay;

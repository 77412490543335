import { ResizeObserver } from '@juggle/resize-observer';
import { OrbitControls, Sky } from '@react-three/drei';
import { Canvas, ThreeEvent, useFrame } from '@react-three/fiber';
import { observer } from 'mobx-react';

import { Fragment, Suspense, useRef, useState } from 'react';
import THREE, { MathUtils, Spherical, Vector3 } from 'three';
import Cage from '../Components/3D/Objects/Cage';
import FeedBarge from '../Components/3D/Objects/FeedBarge';
import HeightPlane from '../Components/3D/Objects/HeightPlane';
import OceanPlane from '../Components/3D/Objects/OceanPlane';
import AlarmDisplay from '../Components/UIElements/AlarmDisplay';
import CompassRose from '../Components/UIElements/CompassRose';
import { AuthFetcher } from '../lib/fetch';
import { CageContents, SiteContents } from '../lib/types';
import useStore from '../Store/Store';

interface SiteViewProps {
  site: SiteContents | undefined;
  setMenuRightVisible: (r: boolean) => void;
  setPicked: (a: any) => void;
  nodeClicked: (a: string) => void;
  showAlarms: boolean;
  showColors?: boolean;
}

const SiteView = observer(
  ({ site, setPicked, nodeClicked, setMenuRightVisible, showAlarms, showColors }: SiteViewProps) => {
    const { authStore } = useStore();
    const virtualCamera = useRef<THREE.PerspectiveCamera>();
    const [cameraPosition] = useState(new Vector3(10, 150, 200));

    const [rotation, setRotation] = useState(0);

    var dir = new Vector3();
    var sph = new Spherical();
    const Rotation = () => {
      useFrame((state) => {
        state.camera.getWorldDirection(dir);
        sph.setFromVector3(dir);

        const rotate = MathUtils.radToDeg(sph.theta) - 180;

        setRotation(rotate);
      });
      return null;
    };
    return (
      <Fragment>
        <Canvas
          id="mapcanvas"
          style={{ position: 'absolute', width: '100%', height: '100vh' }}
          camera={{ position: cameraPosition, near: 0.1, far: 3000 }}
          resize={{ polyfill: ResizeObserver }}
        >
          {/* Scene setup */}

          <Rotation />
          <OrbitControls
            camera={virtualCamera.current}
            enableZoom
            enableRotate
            rotateSpeed={0.4}
            enableDamping
            dampingFactor={0.25}
            minPolarAngle={0}
            maxPolarAngle={1.2}
            maxDistance={900}
            maxZoom={100}
            minZoom={100}
            target={[120, 0, 0]}
          />

          <fog attach="fog" args={['#006994', 0, 3000]} />
          <ambientLight intensity={0.2} />
          <directionalLight
            position={[1, 5, 4]}
            intensity={1.0}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />
          <Sky sunPosition={[1, 5, 4]} distance={1200} rayleigh={0.2} />

          {/* World objects */}
          {site && site.cages && (
            <Suspense fallback={null}>
              <CageSetup
                cages={site.cages}
                setCage={setPicked}
                cageClicked={nodeClicked}
                cageHover={() => {}}
                setMenuRightVisible={setMenuRightVisible}
                showColor={showColors}
              />
            </Suspense>
          )}

          {site && site.feeding_barge && (
            <Suspense fallback={null}>
              <group
                onClick={(e: ThreeEvent<MouseEvent>) => {
                  if (e.altKey) {
                    e.stopPropagation();
                    AuthFetcher('/api/v1/demo/trigger/cycle/oxygen', authStore.getTokenFunction(), {
                      method: 'PUT',
                    });
                  }
                }}
              >
                <FeedBarge fb={site.feeding_barge} />
              </group>
            </Suspense>
          )}

          <Suspense fallback={null}>
            <HeightPlane position={[0, -100, 0]} />
          </Suspense>
          <Suspense fallback={null}>
            <OceanPlane rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} scale={[1000, 1000, 1]} />
          </Suspense>

          {/* <Suspense fallback={null}>
          <TestObject />
        </Suspense> */}
        </Canvas>

        {/* <MapViewButtons CurrentView={CurrentView} SetCurrentView={SetCurrentView} cages={Cages}/> */}
        {showAlarms && site && <AlarmDisplay cages={site.cages} setMenuRightVisible={setMenuRightVisible} />}
        {/* <Segment style={{ position: 'absolute', left: '3%', top: '3%' }} basic>
        <h1> Anlegg: {SiteName}</h1>
      </Segment> */}

        <CompassRose rotation={rotation} />
      </Fragment>
    );
  }
);

interface CageSetupProps {
  cages: CageContents[];
  cageClicked: any;
  cageHover: any;
  setMenuRightVisible: any;
  setCage: any;
  showColor?: boolean;
}

const CageSetup = ({ cages, cageClicked, setMenuRightVisible, setCage, showColor }: CageSetupProps) => {
  const group = useRef<THREE.Group>();

  const [hoveredCageKey, setHoveredCageKey] = useState(null);
  return (
    <group
      ref={group}
      onPointerMissed={(e) => {
        if (e.type === 'click') {
          setCage(undefined);
          setMenuRightVisible(false);
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        cageClicked(hoveredCageKey);
      }}
    >
      {cages.map((data, index) => (
        <Cage key={index} cageData={data} setHoveredCageKey={setHoveredCageKey} showColor={showColor} />
      ))}
    </group>
  );
};

export default SiteView;

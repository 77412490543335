import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { AlarmContents, TriggerContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';
import AlarmForm from './AlarmForm';
import useAlarmListStore from './AlarmListStore';

const invalidLimits = (mType: string, tType: string, warn: number, crit: number) => {
  switch (tType) {
    default:
      return true;
    case 'less_than':
      return warn <= crit;
    case 'more_than':
      return warn >= crit;
  }
};

const invalidDevianceLimits = (baseline: number, warn: number, crit: number) => {
  return baseline - warn <= baseline - crit || baseline + warn >= baseline + crit;
};

const validAlarm = (alarm: AlarmContents) => {
  if (alarm.name === '') {
    return false;
  }
  return alarm.trigger && validTrigger(alarm.trigger);
};

const validTrigger = (trigger: TriggerContents) => {
  if (!trigger) {
    return false;
  }
  if (trigger.trigger_type === 'one_dimension_deviance') {
    return !invalidDevianceLimits(trigger.baseline_point, trigger.warning_point, trigger.critical_point);
  } else if (trigger.trigger_type === 'one_dimension_point') {
    return !invalidLimits(trigger.measurements, trigger.trigger_action, trigger.warning_point, trigger.critical_point);
  } else {
    return false;
  }
};

const CreateAlarmDialogue = observer(() => {
  const { authStore } = useStore();
  const { alarmListStore } = useAlarmListStore();
  const notyf = useContext(NotyfContext);
  const [alarm, setAlarm] = useState<AlarmContents>();
  const addAlarm = async () => {
    if (alarm && validAlarm(alarm)) {
      let response = await AuthFetcher('/api/v1/alarm/add', authStore.getTokenFunction(), {
        body: alarm,
        method: 'POST',
        raw: true,
      });

      if (response.ok) {
        notyf.success('Added new alarm');
        alarmListStore.showConnectAlarm(false);
        mutate('/api/v1/alarm');
      } else {
        notyf.error('Failed adding new alarm ');
      }
    }
  };

  return (
    <Modal open={alarmListStore.createAlarm}>
      <Modal.Header>Create Alarm</Modal.Header>
      <Modal.Content>
        <AlarmForm setAlarm={setAlarm}></AlarmForm>
      </Modal.Content>
      <Modal.Actions>
        <Button positive disabled={!(alarm && validAlarm(alarm))} onClick={addAlarm}>
          Create
        </Button>
        <Button negative onClick={() => alarmListStore.showCreateAlarm(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default CreateAlarmDialogue;

import { useState, Fragment, useEffect } from 'react';
import { Container, Dimmer, Header, Icon, Loader } from 'semantic-ui-react';
import useSWR from 'swr';

import '../Components/3D/Materials/Cage2DepthSegmentsMaterial';

import { AuthFetcher, SiteHandler, SlAlarmStatusesHandler } from '../lib/fetch';
import { CageContents, SensorLocationContents, ScenarioStatus, SlScenarioStatus, StatusInfo } from '../lib/types';
import SiteView from './SiteView';
import RightMenu from '../Components/UIElements/RightMenu';
import useStore from '../Store/Store';
import { observer } from 'mobx-react';
import RightMenuDashboard from '../Components/Dashboard/RightMenuDashboard';

interface DashboardProps {
  match?: any;
  setBreadCrumb: (text: string) => void;
}

const Dashboard = observer(({ match, setBreadCrumb }: DashboardProps) => {
  const { siteStore, authStore, appStore } = useStore();
  appStore.setBreadcrumb('Dashboard');

  const [cageKey, setCageKey] = useState<string>();
  const [rightMenuVisible, setRightMenuVisible] = useState<boolean>(false);
  const [rightMenuContent, setRightMenuContent] = useState<any>(<Container></Container>);

  if (match?.params?.siteId && siteStore.getSiteKey() !== match.params.siteStore.getSiteKey()) {
    siteStore.setSiteKey(match.params.siteStore.getSiteKey());
  }
  useEffect(() => {
    setRightMenuVisible(false);
  }, [setRightMenuVisible]);

  const { data: site, error: siteError } = useSWR(
    siteStore.getSiteKey() ? '/api/v1/site/' + siteStore.getSiteKey() : null,
    (url) => SiteHandler(AuthFetcher(url, authStore.getTokenFunction())),
    {}
  );
  const { data: siteAlarmStatuses, error: alarmError } = useSWR(
    siteStore.getSiteKey() ? 'api/v1/alarm/site/' + siteStore.getSiteKey() : null,
    (url) => SlAlarmStatusesHandler(AuthFetcher(url, authStore.getTokenFunction())),
    { refreshInterval: 1000 }
  );

  if (alarmError) {
    console.error(alarmError);
  }
  useEffect(() => {
    cageKey &&
      setRightMenuContent(
        <RightMenuDashboard
          cageKey={cageKey}
          setRightMenuVisible={setRightMenuVisible}
          site={site}
        ></RightMenuDashboard>
      );
  }, [cageKey, setRightMenuVisible, setRightMenuContent, siteStore, site]);

  useEffect(() => {
    setRightMenuVisible && setRightMenuVisible(false);
  }, [setRightMenuVisible]);

  useEffect(() => {
    setBreadCrumb && setBreadCrumb('Dashboard');
  }, [setBreadCrumb]);

  if (!site) {
    return <Loader active></Loader>;
  }

  if (!siteAlarmStatuses) {
    console.log('unable to load site alarms');
  }

  if (site?.cages?.length) site.cages.sort((a: CageContents, b: CageContents) => (a.node_key > b.node_key ? 1 : -1));

  if (site && site.cages) {
    site.cages
      .flatMap((cage: CageContents) => cage.sensor_locations)
      .forEach((sl: SensorLocationContents) => {
        if (siteAlarmStatuses) {
          let relatedAlarms = siteAlarmStatuses.find((slStatus: SlScenarioStatus) => slStatus.sl_key === sl.node_key);
          if (relatedAlarms) {
            sl.scenario_status = relatedAlarms.alarm_status;
            if (sl.scenario_status.length > 0) {
              sl.status = sl.scenario_status
                .map((ss: ScenarioStatus) => ss.severity)
                .reduce((prevValue: StatusInfo, currentValue: StatusInfo) => {
                  return prevValue.level > currentValue.level ? prevValue : currentValue;
                });
            }
          }
        }
      });
  }

  return (
    <Dimmer.Dimmable as={Fragment}>
      <RightMenu content={rightMenuContent} visible={rightMenuVisible} />
      <SiteView
        site={site}
        nodeClicked={(key: string) => {
          setCageKey(key);
          setRightMenuVisible(true);
        }}
        setMenuRightVisible={setRightMenuVisible}
        setPicked={setCageKey}
        showAlarms={!rightMenuVisible}
      />
      <Dimmer
        style={{
          zIndex: 100000000 /*Set stupidly high to avoid having the name of the cages hovering over the dimmer */,
        }}
        active={siteError}
      >
        {siteError && (
          <Header as="h2" inverted icon>
            <Icon name="warning"></Icon>
            Unable to load Site data
          </Header>
        )}
      </Dimmer>
    </Dimmer.Dimmable>
  );
});

export default Dashboard;

import { capitalize } from 'lodash';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Button, Dropdown, Grid, Input, Label, Modal } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { SensorContents, SensorListItem, SENSOR_NODE_TYPE } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';

interface AddNewSensorDialogueProps {
  siteKey: string | undefined;
  displayAddSensor: boolean;
  sensors: SensorListItem[];
  setDisplayAddSensor: (b: boolean) => void;
}

const sensorTypeOption = [
  { key: 'tilt', text: 'Tilt/Depth', value: 'tilt' },
  { key: 'oxygen', text: 'Oxygen', value: 'oxygen' },
  { key: 'salinity', text: 'Salinity', value: 'salinity' },
  { key: 'turbidity', text: 'Turbidity', value: 'turbidity' },
];

const makeName = (serialNumber: number, sensorType: string) => {
  return capitalize(sensorType) + '-' + serialNumber;
};

const validSensorId = (sensorId: number | undefined) => {
  return sensorId && sensorId <= 99999 && sensorId >= 0;
};
const validSensor = (sensorType: string, sensorId: number, sensors: SensorListItem[]) => {
  if (!validSensorId(sensorId)) {
    return false;
  }
  let sensorExists = sensors.find((value: SensorListItem) => {
    return value.sensor?.sensor_type === sensorType && value.sensor?.sensor_id === sensorId % 1000;
  });
  return sensorExists === undefined;
};
const AddNewSensorDialogue = observer(
  ({ siteKey, sensors, displayAddSensor, setDisplayAddSensor }: AddNewSensorDialogueProps) => {
    const notyf = useContext(NotyfContext);
    const { authStore } = useStore();
    const [name, setName] = useState<string>('');
    const [sensorId, setSensorId] = useState<number>(0);
    const [sensorType, setSensorType] = useState<string>('');

    const addNewSensor = async () => {
      if (!validSensorId(sensorId)) {
        notyf.error('invalid sensor id ' + sensorId);
        return;
      }
      if (!sensorTypeOption.map((options: any) => options.value).includes(sensorType)) {
        notyf.error('invalid sensor type ' + sensorType);
        return;
      }
      let newName = name;
      if (newName === '') {
        newName = makeName(sensorId, sensorType);
      }

      const newSensor: SensorContents = {
        node_key: '',
        node_kind: SENSOR_NODE_TYPE,
        sensor_id: sensorId % 1000,
        sensor_type: sensorType,
        name: newName,
      };

      const response = await AuthFetcher(`/api/v1/sensor/site/${siteKey}`, authStore.getTokenFunction(), {
        method: 'PUT',
        raw: true,
        body: newSensor,
      });

      if (response.ok) {
        notyf.success('Added new sensor');
        siteKey && mutate(`/api/v1/site/${siteKey}`);
        siteKey && mutate(`/api/v1/sensor/site/${siteKey}`);
        setDisplayAddSensor(false);
        setName('');
        setSensorId(0);
        setSensorType('');
      } else {
        notyf.error('Failed adding sensor. Please try again');
      }
    };

    return (
      <Modal open={displayAddSensor}>
        <Modal.Header>Add Sensor Manually</Modal.Header>
        <Modal.Content>
          <Grid verticalAlign="top" textAlign="justified">
            <Grid.Row columns={5}>
              <Grid.Column>
                <Label>Name</Label>
              </Grid.Column>
              <Grid.Column stretched>
                <Input
                  placeholder={makeName(sensorId, sensorType)}
                  onChange={(e, data) => {
                    setName(data.value);
                  }}
                  value={name}
                ></Input>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5}>
              <Grid.Column>
                <Label>Serial Number</Label>
              </Grid.Column>
              <Grid.Column stretched>
                <Input
                  type="number"
                  onChange={(e, data) => {
                    if (+data.value <= 99999) setSensorId(+data.value);
                  }}
                  value={sensorId}
                ></Input>
              </Grid.Column>
              <Grid.Column stretched>
                <Input label={'Saves as sensor id'} disabled value={sensorId % 1000}></Input>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5}>
              <Grid.Column>
                <Label>Sensor Type</Label>
              </Grid.Column>
              <Grid.Column stretched>
                <Dropdown
                  onChange={(props, context) => {
                    if (context.value) {
                      setSensorType(context.value ? context.value.toString() : '');
                    }
                  }}
                  placeholder={'select sensor type'}
                  options={sensorTypeOption}
                ></Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button positive disabled={!validSensor(sensorType, sensorId, sensors)} onClick={addNewSensor}>
            {' '}
            Add
          </Button>
          <Button
            negative
            onClick={() => {
              setDisplayAddSensor(false);
              setSensorType('');
              setName('');
              setSensorId(0);
            }}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);

export default AddNewSensorDialogue;

import * as THREE from 'three';
import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { Feeding_Barge } from '../../../lib/types';
import { Vector3 } from 'three';

type GLTFResult = GLTF & {
  nodes: {
    Mesh: THREE.Mesh;
    Mesh_1: THREE.Mesh;
    Mesh_2: THREE.Mesh;
    Mesh_3: THREE.Mesh;
    Mesh_4: THREE.Mesh;
    Mesh_5: THREE.Mesh;
    Mesh_6: THREE.Mesh;
    Mesh_7: THREE.Mesh;
    Mesh_8: THREE.Mesh;
    Mesh_9: THREE.Mesh;
    Mesh_10: THREE.Mesh;
    Mesh002: THREE.Mesh;
    Mesh002_1: THREE.Mesh;
  };
  materials: {
    green6028: THREE.MeshStandardMaterial;
    ['Zimbabwe Black']: THREE.MeshStandardMaterial;
    paint_white: THREE.MeshStandardMaterial;
    Rubber: THREE.MeshStandardMaterial;
    BaseMtl: THREE.MeshStandardMaterial;
    shell: THREE.MeshStandardMaterial;
    ['Material #2100472524']: THREE.MeshStandardMaterial;
    ['Material #2100472571']: THREE.MeshStandardMaterial;
    ['Material #2100471636']: THREE.MeshStandardMaterial;
    paint_white2: THREE.MeshStandardMaterial;
    ['Material #выав4']: THREE.MeshStandardMaterial;
    ['BaseMtl.001']: THREE.MeshStandardMaterial;
    ['BaseMtl.002']: THREE.MeshStandardMaterial;
  };
};

interface FeedBargeProps {
  fb: Feeding_Barge;
}
const WATER_LEVEL_MODEL_ADJUSTMENT: number = 4;
const FeedBarge = ({ fb }: FeedBargeProps) => {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF('Models/forflaate.glb') as GLTFResult;
  return (
    <group
      ref={group}
      rotation={[0, (fb.rotation * Math.PI) / 180, 0]}
      position={new Vector3(fb.position[0], fb.position[1] + WATER_LEVEL_MODEL_ADJUSTMENT, fb.position[2])}
    >
      <group
        scale={0.0015}
        onPointerEnter={(e) => {
          console.log(fb.node_key);
        }}
      >
        <mesh geometry={nodes.Mesh.geometry} material={materials.green6028} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_1.geometry} material={materials['Zimbabwe Black']} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_2.geometry} material={materials.paint_white} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_3.geometry} material={materials.Rubber} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_4.geometry} material={materials.BaseMtl} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_5.geometry} material={materials.shell} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_6.geometry} material={materials['Material #2100472524']} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_7.geometry} material={materials['Material #2100472571']} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_8.geometry} material={materials['Material #2100471636']} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_9.geometry} material={materials.paint_white2} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh_10.geometry} material={materials['Material #выав4']} receiveShadow castShadow />
      </group>
      <group scale={0.0015}>
        {/* <mesh geometry={nodes.Mesh002.geometry} material={materials['BaseMtl.001']} receiveShadow castShadow/>*/}
        <mesh geometry={nodes.Mesh002.geometry} material={materials['Zimbabwe Black']} receiveShadow castShadow />
        <mesh geometry={nodes.Mesh002_1.geometry} material={materials['BaseMtl.002']} receiveShadow castShadow />
      </group>
    </group>
  );
};

useGLTF.preload('Models/forflaate.glb');

export default FeedBarge;

import { AuthFetcher } from '../../lib/fetch';
import { observer } from 'mobx-react';
import useSWR from 'swr';
import useStore from '../../Store/Store';
import { Grid, GridColumn, Icon, Message } from 'semantic-ui-react';

const ReceiverStatuses = ({ statuses }: { statuses: any }) => {
  const res = statuses?.map((e: any, key: number) => {
    return (
      <GridColumn key={`receiver-${key}`}>
        <h3>Receiver {e.pos}</h3>
        <Icon
          name={e.is_connected === true ? 'circle' : 'circle outline'}
          color={e.is_connected === true ? 'green' : 'grey'}
          size="big"
        />
      </GridColumn>
    );
  });
  return res;
};

const SetupReceivers = observer(() => {
  const { authStore, firstTimeSetupStore } = useStore();
  const activeGatewayId = firstTimeSetupStore.getActiveGatewayId();
  let receiverStatuses: any = null;
  let numberOfReceivers = 0;
  const minimumNumberOfReceivers = 2;

  // Retrieve the status of the Gateway with the provided Gateway ID
  let { data: gatewayInfo, error: gatewayError } = useSWR(
    `/api/v1/status/${activeGatewayId}`,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {
      refreshInterval: 1000,
    }
  );

  if (!gatewayError && gatewayInfo) {
    receiverStatuses = gatewayInfo?.topside_status?.receivers?.receiver_statuses;
    numberOfReceivers = receiverStatuses?.filter((e: any) => e.is_connected === true).length;
  }

  if (numberOfReceivers >= minimumNumberOfReceivers) {
    firstTimeSetupStore.setStepComplete(firstTimeSetupStore.getCurrentStep());
  } else {
    firstTimeSetupStore.setStepIncomplete(firstTimeSetupStore.getCurrentStep());
  }

  return (
    <div>
      <img
        src="/images/first-time-setup-connect-receivers.jpg"
        alt="Step 4 - Connect receivers"
        className="w-100 rounded mb-3"
      />
      <p className="mb-3">
        Connect at least {minimumNumberOfReceivers} receiver cables (long cables) to the CageSense&trade; Gateway by aligning the connector head to the receiver ports and twisting the cap to lock them into place.
      </p>
      <p>
        Connections may take a minute, and is checked for automatically.
      </p>
      <Grid columns={4} className="text-center my-3 rounded shadow" divided padded>
        <ReceiverStatuses statuses={receiverStatuses} />
      </Grid>
      <Message className="my-3" color={numberOfReceivers >= 2 ? 'grey' : 'red'}>
        <Message.Header>Status</Message.Header>
        <div>
          {numberOfReceivers >= 2 ? (
            <div title={gatewayInfo?.topside_status?.receivers?.message}>
              <Icon name="check" color="green" className="me-1" />
              Looks good, {gatewayInfo?.topside_status?.receivers?.message}.
            </div>
          ) : (
            <div>
              <Icon name="exclamation triangle" color="red" className="me-2" title={gatewayInfo?.cloud?.message} />
              Please connect at least {minimumNumberOfReceivers} receivers to the CageSense&trade; Gateway.
            </div>
          )}
        </div>
      </Message>
    </div>
  );
});

export default SetupReceivers;

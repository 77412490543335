// The main container for Auth app
import 'notyf/notyf.min.css';
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, useHistory, useLocation } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import TopMenu from './TopMenu';
import AppMain from './AppMain';
import { AuthFetcher } from './lib/fetch';
import useStore from './Store/Store';
import useSWR from 'swr';
import LogoutButton from './Components/LogoutButton';
import { Icon, Loader, Message } from 'semantic-ui-react';

interface AppProps {
  devMode: boolean;
  isAdmin: boolean;
}

interface LocationState {
  from: {
    pathname: string;
  };
}

const Container = ({ devMode, isAdmin }: AppProps) => {
  const location = useLocation<LocationState>().pathname.toLocaleLowerCase();
  const history = useHistory();
  const { appStore, authStore, siteStore } = useStore();
  const [menuVisible, setVisible] = useState(false);
  const [menuRightVisible, setMenuRightVisible] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState('Overview Map');
  const isAtWelcomeScreen = location.startsWith('/welcome');
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setVisible(!menuVisible);
  };
  // Retrieve all sites for the user
  const { data: sites, error: siteError } = useSWR('/api/v1/site', (url) => AuthFetcher(url, authStore.getTokenFunction()));

  const { data: firstTimeSetup, error } = useSWR('/api/v1/organization/setup', (url) =>
    AuthFetcher(url, authStore.getTokenFunction())
  );

  useEffect(() => {
    // Save all the sites to app store so they can be easily accessed from anywhere
    siteStore.setSites(sites)
    // There are sites, so set the first one as the active one app-wide
    if (sites?.length > 0 && firstTimeSetup?.first_time_setup_complete === true && !isAtWelcomeScreen) {
      siteStore.setSiteKey(sites[0].node_key)
    }
    // Send users that haven't completed first time setup to that page
    if (firstTimeSetup?.first_time_setup_complete === false) {
      appStore.setBreadcrumb('First time setup')
      history.push('/welcome/');
    }
  },
    [
      sites,
      siteStore,
      appStore,
      firstTimeSetup,
      history,
      isAtWelcomeScreen])

  const errorMessage = (heading: string, error: string) => {
    return (
      <>
        <Message
          icon
          className='mx-auto mt-3'
        >
          <Icon
            name='flag outline'
            size='large'
            className='text-blue'
          />
          <Message.Content>
            <Message.Header className='mb-3'>
              {heading}
            </Message.Header>
            <p>
              Unfortunately an error occurred while retrieving details. More specifically: {error}</p>
            <p>
              Please contact Water Linked support at <a href="mailto:support@waterlinked.com">support@waterlinked.com</a> for assistance.
            </p>
            <LogoutButton />
          </Message.Content>
        </Message>
      </>
    )
  }
  if (error) {
    return errorMessage('Could not retrieve organization details', error.toString())
  }

  if (siteError) {
    return errorMessage('Could not retrieve site details', siteError.toString())
  }

  if (!firstTimeSetup) {
    return <Loader active />
  }

  /**
   * Setup complete is initially null, which indicates indeterminate.
   * This to avoid redirecting before we know if the user has completed
   * the first time setup or not.
   */
  appStore.setUser(firstTimeSetup, null);

  // Only parse url when not at first time setup
  if (!isAtWelcomeScreen) {
    siteStore.setUpdateUrl((siteKey: string) => {
      let pathParts = location.split('/', 10);
      if (pathParts[1] === '') {
        history.push('/dashboard/' + siteKey);
      } else {
        history.push('/' + pathParts[1] + '/' + siteKey);
      }
    });
  }

  return (
    <div>
      <TopMenu
        toggleMenu={toggleMenu}
        breadCrumb={breadCrumb}
        setBreadCrumb={setBreadCrumb}
        displayIcon={!isAtWelcomeScreen}
      />
      {!isAtWelcomeScreen && (
        <SidebarMenu
          showLoginLogout={true}
          showAdmin={isAdmin}
          menuVisible={menuVisible}
          breadCrumb={breadCrumb}
          setMenuRightVisible={setMenuRightVisible}
          setBreadCrumb={setBreadCrumb}
        />
      )}
      <AppMain
        isAdmin={isAdmin}
        menuVisible={menuVisible}
        menuRightVisible={menuRightVisible}
        setMenuRightVisible={setMenuRightVisible}
        setBreadCrumb={setBreadCrumb}
      />
    </div>
  );
};

function App({ devMode, isAdmin }: AppProps) {
  return (
    <Router>
      <Container devMode={devMode} isAdmin={isAdmin} />
    </Router>
  );
}

export default App;

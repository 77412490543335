import useStore from '../../Store/Store';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthFetcher } from '../../lib/fetch';
import { Button, Icon } from 'semantic-ui-react';
import NotyfContext from '../NotyfContext';
import { mutate } from 'swr';

const SetupComplete = () => {
  const { appStore, authStore, firstTimeSetupStore } = useStore();
  const [saving, setSaving] = useState(false);
  const notify = useContext(NotyfContext);
  const history = useHistory();
  const redirectToDashboard = () => {
    history.replace('/');
  };
  let userInfo = appStore.getUser();
  const completeSetup = async (finished: boolean) => {
    if (userInfo.local) {
      setSaving(true);
      userInfo.local.first_time_setup_complete = finished;

      const results = await AuthFetcher('/api/v1/organization/setup', authStore.getTokenFunction(), {
        method: 'PUT',
        body: userInfo.local,
        raw: true,
      });
      if (results.ok) {
        appStore.setUser(userInfo.local, userInfo.auth0);
        // Remove the active gateway ID
        firstTimeSetupStore.setActiveGatewayId('');
        notify.success('User profile and progress updated');
      } else {
        notify.error('Updating failed, unfortunately');
        userInfo.local.first_time_setup_complete = false;
      }
      mutate('/api/v1/organization/setup');
      appStore.setUser(userInfo.local, userInfo.auth0);
      setSaving(false);
    } else {
      notify.error(
        'Saving failed. There is no user information. Please contact support@waterlinked.com for assistance.'
      );
    }
  };

  // Run automatically when the user shows this step
  if (userInfo.local.first_time_setup_complete === false) {
    completeSetup(true);
  }
  return (
    <div>
      <img
        src="/images/first-time-setup-intro.jpg"
        alt="Step 1 - Setup complete"
        className="w-100 rounded mb-3"
      />
      <p>
        Congratulations, first time setup is complete!
      </p>
      <h3>What's next?</h3>
      <p>
        Attach weights to the receiver cables so they don't move around easily in the water.
        We also suggest attaching tension reliefs to the receiver cables so they won't damage the CageSense Gateway&trade; if pulled by sudden force.</p>
      <p>
        Place the end of the receiver cable (the receiver head) well submerged in water and where there are few obstacles between them and the location of your sensors.
        The receiver head should be placed out of harms way from mechanical and moving parts.
      </p>
      <p>
        Lastly, place the sensors in the water where they should go.
      </p>
      <h3>Reading data</h3>
      <p>
        Data is sent from the sensors and arrives pretty quickly, but may in some cases take up to 3 hours.
      </p>
      <p>
        You can view sensor data over time from the analytics page.
      </p>

      {saving && (
        <div className="my-3">
          <Icon name="spinner" loading /> Saving...
        </div>
      )}
      {userInfo.local.first_time_setup_complete === true && (
        <Button
          color="blue"
          className="p-3"
          fluid
          icon="chevron right"
          content="Continue to the overview map"
          labelPosition="right"
          disabled={!userInfo.local.first_time_setup_complete}
          loading={saving}
          onClick={() => redirectToDashboard()}
        />
      )}
    </div>
  );
};

export default SetupComplete;

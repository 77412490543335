import { useGLTF } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import rgba from 'color-normalize';
import { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import hexColors from '../../../lib/colors';
import { CageContents, LICE_SKIRT, SensorLocationContents, SINKER_TUBE } from '../../../lib/types';
import '../Materials/Cage4DepthMaterial';

type BunnringGLTFResult = GLTF & {
  nodes: {
    Torus: THREE.Mesh;
  };
  materials: {};
};

interface BottomRingProps {
  color: [number, number, number];
}

const BottomRingSetup = ({ color }: BottomRingProps) => {
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/bunnring.glb') as BunnringGLTFResult;

  return (
    <group ref={group} dispose={null} scale={105}>
      <mesh geometry={nodes.Torus.geometry} material={nodes.Torus.material} position={[0, -25.5, 0]}>
        <meshStandardMaterial color={color} emissiveIntensity={1} />
      </mesh>
    </group>
  );
};

type ShieldingSkirtGLTFResult = GLTF & {
  nodes: {
    Torus001: THREE.Mesh;
  };
  materials: {};
};

interface ShieldingSkirtProps {
  color: [number, number, number];
}

const ShieldingSkirtSetup = (props: ShieldingSkirtProps) => {
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/ShieldingSkirt.glb') as ShieldingSkirtGLTFResult;

  return (
    <group ref={group} dispose={null} position={[0, -10, 0]} scale={105}>
      <mesh geometry={nodes.Torus001.geometry} material={nodes.Torus001.material} position={[0, -6.5, 0]}>
        <meshStandardMaterial color={props.color} emissiveIntensity={1} />
      </mesh>
    </group>
  );
};

type CageGLTFResult = GLTF & {
  nodes: {
    Cage: THREE.Mesh;
    AnchorPointNE: THREE.Mesh;
    AnchorPointNW: THREE.Mesh;
    AnchorPointSE: THREE.Mesh;
    AnchorPointSW: THREE.Mesh;
    Nett: THREE.Mesh;
    Walkway: THREE.Mesh;
  };
  materials: {};
};

interface CageProps {
  cageData: CageContents;
}

const Cage = ({ cageData }: CageProps) => {
  const group = useRef<THREE.Group>();
  const { nodes } = useGLTF('Models/Cage_Polarcirkel_withNett.glb') as CageGLTFResult;

  const netTexture = useLoader(THREE.TextureLoader, 'CageNet.png');
  netTexture.wrapS = netTexture.wrapT = THREE.RepeatWrapping;
  netTexture.repeat.set(10, 10);

  const [okColor] = useState(rgba(hexColors.green));
  const [warningColor] = useState(rgba(hexColors.yellow));
  const [criticalColor] = useState(rgba(hexColors.red));
  const [inactiveColor] = useState(rgba(hexColors.grey));

  const [depth1Severity, setDepth1Severity] = useState(-1);
  const [depth2Severity, setDepth2Severity] = useState(-1);
  const [depth3Severity, setDepth3Severity] = useState(-1);
  const [depth4Severity, setDepth4Severity] = useState(-1);
  const [ringSeverity, setRingSeverity] = useState(-1);
  const [skirtSeverity, setSkirtSeverity] = useState(-1);

  useEffect(() => {
    cageData.sensor_locations.forEach((sensorLoc: SensorLocationContents) => {
      var hasNoSensors = sensorLoc.sensors.length === 0;
      var level = hasNoSensors ? -1 : sensorLoc.status.level;
      if (sensorLoc.meta.type === 'depth') {
        var depth = sensorLoc.meta.max_depth - 5;
        switch (depth) {
          case 5:
            setDepth1Severity(level);
            break;
          case 15:
            setDepth2Severity(level);
            break;
          case 25:
            setDepth3Severity(level);
            break;
          case 35:
            setDepth4Severity(level);
            break;
          default:
        }
      } else if (sensorLoc.meta.type === SINKER_TUBE) {
        setRingSeverity(level);
      } else if (sensorLoc.meta.type === LICE_SKIRT) {
        setSkirtSeverity(level);
      }
    });
  }, [cageData, okColor, warningColor, criticalColor, inactiveColor]);

  const getColor = (severity: number) =>
    severity === 0 ? okColor : severity === 1 ? warningColor : severity === 2 ? criticalColor : inactiveColor;
  let ringColor = getColor(ringSeverity);
  let skirtColor = getColor(skirtSeverity);

  return (
    <group ref={group} dispose={null} receiveShadow castShadow scale={0.0148} position={[0, 0, 0]}>
      <mesh
        geometry={nodes.Cage.geometry}
        position={[0, -2100, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        receiveShadow
        scale={[1, 1, 1.4]}
      >
        {/* <meshStandardMaterial color={"green"} emissive={new THREE.Color("green")} transparent emissiveIntensity={0.3} opacity={0.8}/> */}
        <cage4DepthMaterial
          depth1Severity={depth1Severity}
          depth2Severity={depth2Severity}
          depth3Severity={depth3Severity}
          depth4Severity={depth4Severity}
          transitionHeight={0.8}
        />
      </mesh>
      <mesh
        geometry={nodes.Walkway.geometry}
        material={nodes.Walkway.material}
        position={[0, 135, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        receiveShadow
      >
        <meshStandardMaterial color={'gray'} />
      </mesh>
      <mesh geometry={nodes.Nett.geometry} position={[0, 150, 0]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial transparent opacity={1.0} color={'#555555'} alphaMap={netTexture} />
      </mesh>
      <BottomRingSetup color={[ringColor[0], ringColor[1], ringColor[2]]} />
      <ShieldingSkirtSetup color={[skirtColor[0], skirtColor[1], skirtColor[2]]} />
    </group>
  );
};

useGLTF.preload('Models/Cage_Polarcirkel_withNett.glb');
useGLTF.preload('Models/ShieldingSkirt.glb');
useGLTF.preload('Models/bunnring.glb');

export default Cage;

import { observer } from 'mobx-react';
import { Table, Icon, Dimmer, Loader, Header, Segment } from 'semantic-ui-react';
import useSWR from 'swr';

import { AuthFetcher } from '../../lib/fetch';
import {
  OXYGEN_SENSOR_TYPE,
  ReceiverProps,
  SALINITY_SENSOR_TYPE,
  SensorReport,
  TILT_SENSOR_TYPE,
  TURBIDITY_SENSOR_TYPE,
} from '../../lib/types';
import useStore from '../../Store/Store';

const MessageList = observer(() => {
  const { authStore } = useStore();
  const { data: latest, error: latestError } = useSWR(
    '/api/v1/diagnostic/latest',
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {
      refreshInterval: 1000,
    }
  );

  if (latestError) {
    console.log('latest error', latestError);
  }

  let dataList: SensorReport[] = latest ? latest : [];
  return (
    <Dimmer.Dimmable>
      <Table compact definition selectable basic style={{ marginBottom: '100px', marginTop: '30px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Serial #</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Battery</Table.HeaderCell>
            <Table.HeaderCell>Values</Table.HeaderCell>
            <Table.HeaderCell>Signal [dBm]</Table.HeaderCell>
            <Table.HeaderCell>Noise [dBm]</Table.HeaderCell>
            <Table.HeaderCell>BER [%]</Table.HeaderCell>
            <Table.HeaderCell>Packet Counter</Table.HeaderCell>
            <Table.HeaderCell>Registered?</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dataList?.length &&
            dataList
              .slice()
              .reverse()
              .map((data, index) => (
                <DetailsRow
                  key={data.report.sensor_type + data.report.sensor_id + data.report.ts + index}
                  meta={data.meta}
                  report={data.report}
                />
              ))}
        </Table.Body>
      </Table>
      <Dimmer active={latestError}>
        {!latestError && <Loader active={!latest} />}
        {latestError && (
          <Segment>
            <Header>Unable to fetch latest messages</Header>
          </Segment>
        )}
      </Dimmer>
    </Dimmer.Dimmable>
  );
});

const getUnit = (measurementType: string) => {
  switch (measurementType) {
    default:
      return measurementType;
    case 'turbidty':
      return '';
    case 'oxygen':
      return '%';
    case 'temperature':
      return 'C\u00b0';
    case 'depth':
      return 'm';
    case 'pitch':
      return '\u00b0';
    case 'pressure':
      return ' Pa';
    case 'salinity':
      return '%';
    case 'resistance':
      return ' Ohm';
    case 'conductivity':
      return ' \u00B5S/cm';
  }
};

const DetailsRow = ({ key, meta, report }: SensorReport) => {
  const measurements = [];

  if (report.sensor_type === TILT_SENSOR_TYPE) {
    measurements.push({ label: 'Tilt', value: Number(report.pitch).toFixed(2), postfix: getUnit('pitch') });
    measurements.push({ label: 'Depth', value: Number(report.depth).toFixed(2), postfix: getUnit('depth') });
    measurements.push({
      label: 'Pressure',
      value: Number(report.pressure).toFixed(2),
      postfix: ' Pa',
    });
  } else if (report.sensor_type === OXYGEN_SENSOR_TYPE) {
    measurements.push({ label: 'O2', value: Number(report.oxygen).toFixed(2), postfix: getUnit(OXYGEN_SENSOR_TYPE) });
    measurements.push({ label: 'Temp', value: Number(report.temperature).toFixed(2), postfix: getUnit('temperature') });
  } else if (report.sensor_type === SALINITY_SENSOR_TYPE) {
    measurements.push({
      label: 'Sal',
      value: Number(report.salinity).toFixed(2),
      postfix: getUnit(SALINITY_SENSOR_TYPE),
    });
    measurements.push({ label: 'Temp', value: Number(report.temperature).toFixed(2), postfix: getUnit('temperature') });
    measurements.push({
      label: 'Conductivity',
      value: Number(report.conductivity),
      postfix: getUnit('conductivity'),
    });
    measurements.push({
      label: 'Resistance',
      value: Number(report.resistance),
      postfix: getUnit('resistance'),
    });
  } else if (report.sensor_type === TURBIDITY_SENSOR_TYPE) {
    measurements.push({
      label: 'Turb',
      value: Number(report.turbidity).toFixed(2),
      postfix: getUnit(TURBIDITY_SENSOR_TYPE),
    });
    measurements.push({ label: 'Temp', value: Number(report.temperature).toFixed(2), postfix: getUnit('temperature') });
  }

  var sensorType = report.sensor_type[0].toUpperCase() + report.sensor_type.slice(1);
  var name = 'Unregistered ' + sensorType + ' ' + report.sensor_id;
  if (meta) {
    name = meta.name;
  }
  var receivers: ReceiverProps[] = [];
  if (!report.receivers || report.receivers?.length === 0) {
    //Sim version
    receivers.push(
      { id: 0, multipaths: 1, nsd: -1000, rssi: -200 },
      { id: 1, multipaths: 1, nsd: -2000, rssi: -200 },
      { id: 2, multipaths: 1, nsd: -3000, rssi: -300 },
      { id: 3, multipaths: 1, nsd: -4000, rssi: -400 }
    );
  } else {
    receivers = report.receivers;
  }
  var minNsd = receivers.reduce((prev, current) => (prev.nsd < current.nsd ? prev : current)).nsd;

  var maxrssi = receivers.reduce((prev, current) => (prev > current ? prev : current)).rssi;
  const bit_error_rate = report.bit_error_rate || 0;

  return (
    <Table.Row verticalAlign="top" key={key}>
      <Table.Cell>{sensorType}</Table.Cell>
      <Table.Cell>{report.sensor_id}</Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{new Date(report.ts).toLocaleTimeString('nb-NO')}</Table.Cell>
      <Table.Cell>
        <Icon
          size="large"
          color={report.battery > 20 ? 'green' : report.battery > 5 ? 'yellow' : 'red'}
          name={
            report.battery > 75
              ? 'battery four'
              : report.battery > 50
              ? 'battery three'
              : report.battery > 25
              ? 'battery two'
              : report.battery > 5
              ? 'battery one'
              : 'battery zero'
          }
        />{' '}
        {report.battery}%
      </Table.Cell>

      <Table.Cell>
        {measurements.map((measurement) => (
          <>
            {measurement.label}: {measurement.value}
            {measurement.postfix}
            <br />
          </>
        ))}
      </Table.Cell>
      <Table.Cell
        className="colored"
        negative={maxrssi <= -100}
        warning={maxrssi <= -85 && maxrssi >= -99}
        positive={maxrssi >= -84}
      >
        {receivers
          .filter((receiver) => receiver.rssi > -200)
          .map((receiver) => (
            <>
              {String(receiver.id + 1)}:{Number(receiver.rssi).toFixed(0)}
              <br />
            </>
          ))}
      </Table.Cell>
      <Table.Cell
        className="colored"
        negative={minNsd >= -84}
        warning={minNsd <= -85 && minNsd >= -99}
        positive={minNsd <= -100}
      >
        {receivers
          .filter((receiver) => receiver.nsd > -200)
          .map((receiver) => (
            <>
              {String(receiver.id + 1)}:{Number(receiver.nsd).toFixed(0)}
              <br />
            </>
          ))}
      </Table.Cell>
      <Table.Cell
        className="colored"
        negative={bit_error_rate >= 20}
        warning={bit_error_rate <= 19 && bit_error_rate >= 10}
        positive={bit_error_rate <= 10}
      >
        {Number(bit_error_rate).toFixed(0)}
      </Table.Cell>
      <Table.Cell>{report.packet_counter}</Table.Cell>
      <Table.Cell
        width={1}
        icon={<Icon name={meta ? 'check' : 'cancel'} color={meta ? 'green' : 'red'} />}
      ></Table.Cell>
    </Table.Row>
  );
};
export default MessageList;

import { extend, ReactThreeFiber } from '@react-three/fiber';
import * as THREE from 'three';

export class Cage2DepthSegmentsMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        hovered: { value: false },
        segmentStatus: { value: [-1, 0, 1, 2, -1, 0, 1, 2] },
        leftBorder: { value: [] },
        rightBorder: { value: [] },
        segLength: { value: 4 },
        transitionLength: { value: 1.0 },
      },

      transparent: true,

      vertexShader: `
                varying vec3 vColor;
                varying float vY;
                varying vec4 localPosition;
                varying vec2 vUv;
                void main()
                {
                    localPosition = vec4(position, 1.0);
                    vec4 worldPosition = modelMatrix * localPosition;
                    gl_Position = projectionMatrix * viewMatrix * worldPosition;
                }`,
      fragmentShader: `
              #define PI 3.14159265358979323844
                const  vec4 okColor=vec4(0.169,0.612,0.416,0.9);
                const  vec4 warningColor=vec4(1.,0.784,0.231,0.9);
                const  vec4 criticalColor=vec4(0.831,0.282,0.294,0.9);
                const  vec4 inactiveColor=vec4(0.8,0.8,0.8,1.0);
                const float conv = float(PI/float(360));


              vec4 pickColor(in int status);

              vec4 pickColor(in int status){
                vec4 color = inactiveColor;
                if (status == 0){
                  color = okColor;
                }else if (status == 1){
                 color = warningColor;
                }else if (status == 2){
                  color = criticalColor;
                }else{
                  color = inactiveColor;
                }
                return(color);
              }


              bool inSegment(in float leftBorder,in float rightBorder,in float theta){
                return(theta>=leftBorder && theta <=rightBorder);
              }

              
              
              


                varying float vY;
                varying vec4 localPosition;

                uniform int[8] segmentStatus; 
                uniform float[8] leftBorder;
                uniform float[8] rightBorder;
                uniform int segLength;

                uniform float transition;

                void main()
                {   
                    float theta = atan(localPosition.z, -localPosition.x);
                    theta += PI;

                    for(int i =0;i<segLength;i++){
                      if (inSegment(leftBorder[i],rightBorder[i],theta)){
                        gl_FragColor= pickColor(segmentStatus[i]);
                        break;
                      } 
                    }
                }`,
    });
  }

  set statuses(statuses: any) {
    this.uniforms.segmentStatus.value = statuses;
    this.uniforms.segLength.value = statuses.length;
  }

  set segments(segments: any) {
    var leftBorders: any[] = [];
    var rightBorders: any[] = [];
    var statuses: any[] = [];
    segments
      .sort((a: { left_border: number }, b: { left_border: number }) => (a.left_border < b.left_border ? 1 : -1))
      .forEach((element: { left_border: any; right_border: any; level: any }) => {
        leftBorders.push(element.left_border);
        rightBorders.push(element.right_border);
        statuses.push(element.level);
      });

    this.uniforms.leftBorder.value = leftBorders;
    this.uniforms.rightBorder.value = rightBorders;
    this.uniforms.segmentStatus.value = statuses;
    this.uniforms.segLength.value = segments.length;
  }

  set colors(colors: any) {
    colors.forEach((color: any, index: number) => {
      const segment = `seg${index + 1}Color`;
      this.uniforms[segment].value = color ? color : [0, 0, 0, 0];
    });
    this.uniforms.segments.value = colors.length;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      cage2DepthSegmentsMaterial: ReactThreeFiber.Object3DNode<
        Cage2DepthSegmentsMaterial,
        typeof Cage2DepthSegmentsMaterial
      >;
    }
  }
}

extend({ Cage2DepthSegmentsMaterial });

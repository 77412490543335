import { IdToken } from '@auth0/auth0-react';
import { ReactComponentLike } from 'prop-types';
import { SemanticICONS } from 'semantic-ui-react';

//#endregion
export const TILT_SENSOR_TYPE = 'tilt';
export const SALINITY_SENSOR_TYPE = 'salinity';
export const OXYGEN_SENSOR_TYPE = 'oxygen';
export const TURBIDITY_SENSOR_TYPE = 'turbidity';

//NodeTypes
export const SITE_NODE_TYPE = 'Site';
export const CAGE_NODE_TYPE = 'Cage';
export const SENSOR_NODE_TYPE = 'Sensor';
export const ALARM_NODE_TYPE = 'Alarm';
export const SENSOR_LOCATION_NODE_TYPE = 'SensorLocation';
export const FEEDING_BARGE_NODE_TYPE = 'FeedingBarge';

//CageMetaTypes
export const STANDARD_CAGE_TYPE = 'standard';

//SensorLocationTypes
export const LICE_SKIRT = 'lice_skirt';
export const SINKER_TUBE = 'sinker_tube';

//MeasurementTypes
export const TEMPERATURE_MEASUREMENT_TYPE = 'temperature';
export const TILT_MEASUREMENT_TYPE = 'pitch';
export const DEPTH_MEASUREMENT_TYPE = 'depth';
export const TURBIDITY_MEASUREMENT_TYPE = 'turbidity';
export const SALINITY_MEASUREMENT_TYPE = 'salinity';
export const OXYGEN_MEASUREMENT_TYPE = 'oxygen';

// Interfaces related to login and user account
export interface localUserInterface {
  node_key: string;
  node_kind: string;
  auth0_user_id: string;
  first_time_setup_complete: boolean | null;
}

export interface userInterface {
  auth0: IdToken | null;
  local: localUserInterface;
}

// Interfaces related to Welcome screen
export interface stepInterface {
  icon: SemanticICONS;
  heading: string;
  completed: Number;
  component: ReactComponentLike;
}

// Interfaces related to login and user account
export interface localUserInterface {
  node_key: string;
  node_kind: string;
  auth0_user_id: string;
  first_time_setup_complete: boolean | null;
}

export interface userInterface {
  auth0: IdToken | null;
  local: localUserInterface;
}

// Interfaces related to Welcome screen
export interface stepInterface {
  icon: SemanticICONS;
  heading: string;
  completed: Number;
  component: ReactComponentLike;
}

export interface SensorMetaData {
  sensor_id: number;
  sensor_type: string;
  name: string;
  node_key: string;
  node_kind: string;
}

export interface SensorPackage {
  type: string;
  path: string;
}

export type SensorData = {
  ts: string; // Timestamp
  sensor_id: number; // Sensor ID
  receivers: ReceiverProps[];
  packet_counter: number;
  is_valid: boolean;
  bit_error_rate: number;
  battery: number;
  sensor_type: string;
};

export interface TiltSensorData extends SensorData {
  sensor_type: 'tilt';
  pressure: number;
  pitch: number;
  depth: number;
}

export interface OxygenSensorData extends SensorData {
  sensor_type: 'oxygen';
  oxygen: number;
  temperature: number;
}

export interface SensorReport {
  report: TiltSensorData | TurbiditySensorData | OxygenSensorData | SalineSensorData;
  meta: SensorMetaData | undefined;
  key?: string;
}

export interface TurbiditySensorData extends SensorData {
  sensor_type: 'turbidity';
  turbidity: number;
  temperature: number;
}

export interface SalineSensorData extends SensorData {
  sensor_type: 'salinity';
  salinity: number;
  temperature: number;
  conductivity: number;
  resistance: number;
}

export interface CombinedTurbiditySensorData {
  report: TurbiditySensorData;
  meta: SensorMetaData;
}

export interface ReceiverProps {
  id: number;
  multipaths: number;
  nsd: number;
  rssi: number;
}

export interface DiagnosticData {
  x_scale: number;
  y_scale: number;
  x_offset: number;
  y_offset: number;
  data: number[][];
}

//#region Rig Setup

export interface SiteContents {
  cages: CageContents[];
  node_key: string;
  node_kind: string;
  camera: Camera;
  feeding_barge: Feeding_Barge;
  name: string;
  topside_id: string;
}

export interface Camera {
  position: number[];
}

export interface Feeding_Barge {
  latitude: number;
  longitude: number;
  node_key: string;
  node_kind: string;
  rotation: number;
  position: number[];
}

export interface CageContents {
  index?: number;
  node_key: string;
  node_kind: string;
  name: string;
  selected?: boolean;
  position: [number, number, number];
  meta: CageContentsMeta;
  sensor_locations: SensorLocationContents[];
}

export interface CageContentsMeta {
  type: string;
  circumference: number;
}

export interface SensorLocationContents {
  name: string;
  node_key: string;
  node_kind: string;
  scenarios: AlarmContents[];
  sensors: SensorContents[];
  status: StatusInfo;
  selected?: boolean;
  meta: SensorLocationMeta;
  scenario_status: ScenarioStatus[];
}

export interface SensorLocationMeta {
  min_depth: number;
  max_depth: number;
  left_border: number;
  right_border: number;
  type: string;
}

export interface AlarmContents {
  node_key: string;
  node_kind: string;
  name: string;
  description: string;
  trigger: TriggerContents;
}

export interface TriggerContents {
  trigger_type: string;
  trigger_action: string;
  measurements: string;
  warning_trigger_distance: number;
  warning_point: number;
  critical_point: number;
  baseline_point: number;
}

export interface Trigger {
  trigger_point: number;
}

export interface ScenarioSeverity {
  description: string;
  level: 2;
}

export interface SensorContents {
  node_key: string;
  node_kind: string;
  sensor_id: number;
  sensor_type: string;
  name: string;
  selected?: boolean;
}

export interface BatteryContents {
  description: string;
  level: number;
}

export interface SlScenarioStatus {
  sl_key: string;
  alarm_status: ScenarioStatus[];
}

export interface ScenarioStatus {
  name: string;
  node_key: string;
  description: string;
  severity: StatusInfo;
  trigger_distance: number;
  date: Date;
  measurements: MeasurementPair[];
  dismissed: boolean;
  prev_trigger_distance: number;
  min_trigger_distance: number;
  max_trigger_distance: number;
}

export interface MeasurementPair {
  type: string;
  measurement: number;
}

export interface Statuses {
  oxygen: StatusInfo;
  salinity: StatusInfo;
  temperature: StatusInfo;
  turbidity: StatusInfo;
}

export interface StatusInfo {
  description: string;
  level: number;
  time: Date;
}

//#endregion

//#region Site Status

export interface SiteStatus {
  rig_key: string;
  cage_statuses: CageStatus[];
}

export interface CageStatus {
  cage_key: string;
  sl_statuses: sLocStatus[];
}

export interface sLocStatus {
  meta: SensorLocationMeta;
  sl_key: string;
  status: SimpleStatus;
}

export interface SimpleStatus {
  description: string;
  level: number;
}

//#endregion

//#region Dashboard Map

export enum MapCageView {
  OXYGEN = 'Oxygen',
  TEMPERATURE = 'Temperature',
  SALINITY = 'Salinity',
  TURBIDITY = 'Turbidity',
  TILT = 'Tilt',
  INACTIVE = 'Inactive',
}

//#endregion

//#region Scenario setup

export interface ScenarioElementProps {
  scenarioType: string;
  severityDescription: string;
  severityLevel: number;
}

export interface ScenarioSLocProps {
  sensorLocationDepth: number;
  scenarios: ScenarioElementProps[];
}

export interface ScenarioCageProps {
  cage: string;
  sLocProps: ScenarioSLocProps[];
}

//#endregion

//#region Cage Graph helper interfaces

export interface SensorInspectProps {
  sensorType: string;
  sensorID: number;
  measurement: string;
  unit: string;
  time: number;
}

export interface CageGraphProps {
  cageId: string;
  sampleSize: number;
  handleRouteChange?: ({ sensorType, sensorID, unit, time }: SensorInspectProps) => void;
  referenceLinePercentage: number;
  setMenuRightVisible: any;
}

export interface CageAlarmProps {
  level: number;
  label: string;
  position: string;
  values: number[];
  warning: number;
  critical: number;
  measurements: MeasurementPair[];
  depth: number;
  description: string;
  setShadows?: () => void;
  alarm: AlarmContents | undefined;
}

export interface CageProps {
  cage: CageContents;
}

export interface AlarmMeasurementProps {
  measurements: MeasurementPair[];
}

//#endregion

//#region Cage Measurement History

export interface MeasurementContent {
  cage: CageContents;
  sensor_location_history: SensorLocationHistory[];
}

export interface SensorLocationHistory {
  measurement_history: MeasurementHistory[];
  sensor_location: SensorLocationContents;
}

export interface MeasurementHistory {
  measurement: { [measurementType: string]: number[] };
  measurement_type: string;
  sensor_id: number;
  sensor_type: string;
}

export interface SelectedMeasurement {
  cageName: string;
  sensorName: string;
  id: number;
  key: string;
  locationDepth: number;
  measurement: string;
  type: string;
  color: string;
  stroke: string;
  samples: number;
  isSelected: boolean;
}

export interface TimelineGraphProps {
  sensorId?: string;
  timeline: Timeline[];
  showLabels?: any;
  referenceLinePercentage?: number;
  hideXaxis?: boolean;
  dx?: number;
  sensors?: any;
  inverted: boolean;
}

export interface Treshold {
  measurement: string;
  top?: number;
  bottom?: number;
  prefix: string;
  postfix: string;
}

//#endregion

//#region

export interface SiteMeta {
  node_key: string;
  name: string;
  default: boolean;
}

export interface Timeline {
  sensor_type: string;
  sensor_id: number;
  measurement_type: string;
  sensorName?: string;
  color: string | undefined;
  style: string;
  data: TimelineData[];
}

export interface GraphColor {
  cssName: string;
  hex: string;
  inverseHex: string;
}

export interface MeasurementProps {
  color: string;
  style: string;
  samples: number;
}

export interface TimelineData {
  ts: number;
  value: number;
}

export interface SiteToSensor {
  site: SiteContents;
  cage: CageContents;
  sl: SensorLocationContents;
  sensor: SensorContents;
  measurements: string[];
  selected: boolean;
  color: string;
}

export interface Organization {
  node_key: string;
  name: string;
  org_id: string;
}

export interface OrganizationAndSites {
  organization: Organization;
  sites: SiteContents[];
}

export interface SensorListItem {
  sensor_id: number;
  sensor: SensorContents | undefined;
  sensor_location: SensorLocationContents | undefined;
  last_message: TiltSensorData | TurbiditySensorData | OxygenSensorData | SalineSensorData | undefined;
}

export interface LocationOption {
  key: string;
  value: string;
  text: string;
  meta: SensorLocationMeta;
}

export interface TopsideStatusContents {
  cloud: StatusContents;
  barometer: StatusContents;
  receivers: StatusContents;
  time_sync: StatusContents;
  sensors: StatusContents;
}

export interface StatusContents {
  status: boolean;
  message: string;
}

export interface ReceiversStatusContents {
  status: boolean;
  message: string;
  receivers: boolean[];
}

export interface ScanResultContents {
  is_scanning: boolean;
  result: SensorDeviceContents[];
}

export interface SensorDeviceContents {
  sensor_type: string;
  serial_number: number;
  in_storage: boolean;
  mac: string;
  is_fully_charged: boolean;
}

export interface SensorSettingsContents {
  node_key: string;
  node_type: string;
  sensor_config: SensorConfigContents;
}

export interface SensorConfigContents {
  name: string;
  info: string;
  device_config: DeviceConfigContents;
  smart_config: SmartConfigcontents[];
}

export interface DeviceConfigContents {
  measurement_period: number;
  diagnostic_period: number;
  transmit_block: number;
  transmit_period: number;
}

export interface SmartConfigcontents {
  measurement: string;
  trigger_points: TriggerPointContents[];
}

export interface TriggerPointContents {
  active: boolean;
  min: number;
  max: number;
  falling: number;
  rising: number;
}

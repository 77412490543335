import { AuthFetcher } from '../../lib/fetch';
import { observer } from 'mobx-react';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  Brush,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { Button, Icon, Image, Popup, Segment } from 'semantic-ui-react';
import { getUnit, measurementColor, formatSensorName } from '../../lib/format';
import AppSettings from '../../Core/AppSettings';
import useStore from '../../Store/Store';
import useSWR, { mutate } from 'swr';
import MessageBox from '../Message';
import { useEffect, useState } from 'react';

const formatToDate = (input: any) => {
  let value
  // The brush formatter sends in an object instead of the timestamp
  if (typeof input === 'object') {
    value = input.ts
  } else {
    value = input
  }
  return new Date(value * 1000).toLocaleString('nb-NO')
};

const formatYAxis = ((value: any) => {
  // Format whatever value (numeric) on the Y axis to have at max 2 decimals
  return Math.round(value).toString()
})

const SingleGraph = observer(({ sensor }: any) => {
  const { analyticsStore, authStore } = useStore()
  // Read settings from analytics store regarding anything related to the current preset
  const viewData = analyticsStore.getViewData()
  const [darkMode, setDarkMode] = useState(analyticsStore.getDarkMode())
  const [graphGrid, setGraphGrid] = useState(analyticsStore.getGraphGrid())
  const data_points = viewData?.graph_settings?.data_points
  var startDate = viewData?.graph_settings?.date_from
  var endDate = viewData?.graph_settings?.date_to
  const url = `/api/v1/sensor/${sensor?.sensor_type}/${sensor?.sensor_id}/history/${sensor?.measurement_type}/from/${startDate}/to/${endDate}?samples=${data_points}`
  let { data: graphData, error: graphDataError } = useSWR(
    url,
    url => AuthFetcher(url, authStore.getTokenFunction()),
    {
      // A refresh interval of 0 means NO refresh, otherwise we update each 5 seconds
      refreshInterval: viewData?.graph_settings.live === true ? AppSettings.graphUpdateInterval : 0
    });

  // Side effect for when dark mode changes
  useEffect(() => {
    window.addEventListener('storage', () => {
      setDarkMode(analyticsStore.getDarkMode())
      setGraphGrid(analyticsStore.getGraphGrid())
    })
  }, [analyticsStore])

  // Sort the graph data ascending by timestamp (ts), verifying that the next item is larger than the previous 
  if (graphData && graphData?.data) {
    graphData.data = graphData.data.sort((a: any, b: any) => (b.ts > a.ts))
  }
  return (
    <div
      className='w-100'
      key={`single-graph-${sensor?.sensor_id}-${sensor.measurement_type}`}
    >
      <div className='text-capitalize text-dark bg-light-gray ps-1 mb-2 rounded d-flex align-items-center'>
        {/* 
        * The image of the type of sensor this graph is of.
        * Please note that this is the type of sensor, not the measurement type
        * which may be confusing. Consider removing.
        */}
        <Image
          src={`/images/sensor-${sensor.sensor_type}.svg`}
          alt="Type of sensor illustration"
          width={15}
          className="me-3 my-1"
        />
        <div>
          <strong>
            {formatSensorName(sensor)}
          </strong>
          {/* Sensor meta data */}
          <p className='text-sm mt-1'>
            <strong>Reading</strong>: {sensor.measurement_type}
            <strong className="ms-1">Location</strong>: {sensor?.cage_name || 'N/A'}
            <strong className="ms-1">ID</strong>: {sensor?.sensor_id || 'N/A'}
            <strong className="ms-1">Depth</strong>: {sensor?.sensor_location_meta?.min_depth}-{sensor?.sensor_location_meta?.max_depth}m
          </p>
        </div>
        <div className='text-right ms-auto'>
          {/* Mutate invalidates the cache for the graph URL, making sure a new fetch is performed */}
          <Popup
            key={`refresh-sensor-${sensor?.sensor_id}`}
            trigger={
              <Button
                onClick={() => mutate(url)}
                className='p-1 me-2'
                color='blue'
                size='tiny'
              >
                <Icon
                  name='refresh'
                  fitted
                />
              </Button>
            }
            content='Manually refresh readings from this sensor'
          />
          <Popup
            key={`toggle-sensor-${sensor?.sensor_id}`}
            trigger={
              <Button
                onClick={() => analyticsStore.toggleSensor(sensor, sensor.measurement_type, false)}
                className='p-1 me-1'
                color='blue'
                size='tiny'
              >
                <Icon
                  name='cancel'
                  fitted
                />
              </Button>
            }
            content='Remove this sensor graph'
          />
        </div>
      </div>
      {graphDataError && (
        <MessageBox
          message={graphDataError}
          icon='exclamation triangle'
          iconColor='red'
        />
      )}
      {viewData && (
        <Segment
          inverted={darkMode}
        >
          {/* Is there data to present? */}
          {graphData && graphData?.data?.length ?
            <ResponsiveContainer
              width={'100%'}
              height={'100%'}
              minHeight={'400px'}
              minWidth={'100%'}
              debounce={500}
            >
              <LineChart
                key={`${graphData?.sensor_id}-${graphData?.sensor_type}`}
                width={700}
                height={400}
                data={graphData?.data}
                margin={{
                  top: 10,
                  right: 10,
                  left: -10,
                  bottom: 10,
                }}
              >
                <CartesianGrid
                  stroke={graphGrid ? 'gray' : 'none'}
                  strokeDasharray={'3 3'}
                />
                <Line
                  key={'sensor-' + graphData?.sensor_id}
                  name={(graphData?.measurement_type)}
                  dataKey='value'
                  xAxisId={'time'}
                  type={'step'}
                  width={5}
                  dot={false}
                  activeDot={{
                    stroke: '#fff',
                    strokeWidth: 3,
                    r: 10
                  }}
                  unit={getUnit(graphData?.measurement_type)}
                  stroke={measurementColor(graphData.measurement_type, darkMode)}
                  isAnimationActive={false}
                />
                <XAxis
                  xAxisId='time'
                  dataKey='ts'
                  tickFormatter={formatToDate}
                  type='number'
                  angle={0}
                  dy={6}
                  dx={5}
                  domain={[
                    'dataMin',
                    'dataMax'
                  ]}
                  interval='preserveStartEnd'
                  fontSize={10}
                />
                <YAxis
                  dataKey='value'
                  domain={[
                    'dataMin - 2',
                    'dataMax + 2'
                  ]}
                  tickFormatter={formatYAxis}
                />
                {/* Brush allows for scrolling and zooming the graph (!) */}
                <Brush
                  x={40}
                  travellerWidth={20}
                  dataKey={formatToDate}
                  height={20}
                />
                <Tooltip
                  labelFormatter={formatToDate}
                  formatter={(value: any, name: any, props: any) => {
                    return [
                      value.toFixed(2),
                      formatSensorName(sensor)
                    ]
                  }}
                  contentStyle={{
                    backgroundColor: darkMode === false ? '#eee' : '#333',
                    color: darkMode === false ? '#222' : '#fff',
                    fontWeight: 'bold'
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
            :
            <MessageBox
              heading='No data available'
              message={(sensor?.name?.length ? sensor.name : `${sensor.sensor_type}`) + ` has no data in the selected date range. Try a different date.`}
              icon='info circle'
              iconColor='grey'
            />
          }
        </Segment>
      )}

    </div>
  )
})

export default SingleGraph
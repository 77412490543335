import {
  DEPTH_MEASUREMENT_TYPE,
  OXYGEN_MEASUREMENT_TYPE,
  OXYGEN_SENSOR_TYPE,
  SALINITY_MEASUREMENT_TYPE,
  SALINITY_SENSOR_TYPE,
  SensorContents,
  SlScenarioStatus,
  TEMPERATURE_MEASUREMENT_TYPE,
  TILT_MEASUREMENT_TYPE,
  TILT_SENSOR_TYPE,
  TURBIDITY_MEASUREMENT_TYPE,
  TURBIDITY_SENSOR_TYPE,
} from './types';

const findHighestAlarmStatus = (slAlarmStatus: SlScenarioStatus[]) => {
  if (!slAlarmStatus || !Array.isArray(slAlarmStatus)) {
    return -1;
  }
  var alarmStauses = slAlarmStatus.flatMap((slStatus) => slStatus.alarm_status).map((status) => status.severity.level);
  if (alarmStauses.length === 0) {
    return -1;
  }
  return alarmStauses.reduce((prev, current) => (prev > current ? prev : current));
};

const findMeasurementsForSensor = (sensor: SensorContents) => {
  switch (sensor.sensor_type) {
    default:
      return [];
    case OXYGEN_SENSOR_TYPE:
      return [OXYGEN_MEASUREMENT_TYPE, TEMPERATURE_MEASUREMENT_TYPE];
    case TILT_SENSOR_TYPE:
      return [DEPTH_MEASUREMENT_TYPE, TILT_MEASUREMENT_TYPE];
    case TURBIDITY_SENSOR_TYPE:
      return [TURBIDITY_MEASUREMENT_TYPE, TEMPERATURE_MEASUREMENT_TYPE];
    case SALINITY_SENSOR_TYPE:
      return [SALINITY_MEASUREMENT_TYPE, TEMPERATURE_MEASUREMENT_TYPE];
  }
};

/**
 * Retrieve the (image) path of a sensor type.
 * @param type The type of sensor you want to retrieve
 * @returns string - The path to the sensor of specified type or a generic one if the type wasn't found
 */
const getSensorImagePath = (type: string) => {
  let validTypes = [
    TEMPERATURE_MEASUREMENT_TYPE,
    TILT_MEASUREMENT_TYPE,
    DEPTH_MEASUREMENT_TYPE,
    TURBIDITY_MEASUREMENT_TYPE,
    SALINITY_MEASUREMENT_TYPE,
    OXYGEN_MEASUREMENT_TYPE
  ]

  if (validTypes.includes(type)) {
    return `/images/sensor-${type}.svg`
  } else {
    return `/images/sensor-generic.svg`
  }
}

const findSensorTypesFromMeasurements = (measurements: string[]) => {
  let sensorTypes: Set<string> = new Set();
  for (let measurement of measurements) {
    if (measurement === OXYGEN_MEASUREMENT_TYPE) {
      sensorTypes.add(OXYGEN_SENSOR_TYPE);
    } else if (measurement === SALINITY_MEASUREMENT_TYPE) {
      sensorTypes.add(SALINITY_SENSOR_TYPE);
    } else if (measurement === DEPTH_MEASUREMENT_TYPE || measurement === DEPTH_MEASUREMENT_TYPE) {
      sensorTypes.add(TILT_SENSOR_TYPE);
    } else if (measurement === TURBIDITY_MEASUREMENT_TYPE) {
      sensorTypes.add(TURBIDITY_SENSOR_TYPE);
    } else if (measurement === TEMPERATURE_MEASUREMENT_TYPE) {
      sensorTypes.add(TURBIDITY_SENSOR_TYPE);
      sensorTypes.add(OXYGEN_SENSOR_TYPE);
      sensorTypes.add(SALINITY_SENSOR_TYPE);
    }
  }

  return Array.from(sensorTypes);
};

export { 
  findHighestAlarmStatus,
  findMeasurementsForSensor,
  findSensorTypesFromMeasurements,
  getSensorImagePath
};

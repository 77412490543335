import fetch from 'unfetch';
import { SiteContents, SlScenarioStatus } from './types';

interface fetchProps {
  method?: string;
  headers?: HeadersInit;
  body?: object | string;
  token?: string;
  scope?: string;
  raw?: boolean;
}

/**
 * Fetches details about a site, identified by siteId
 * @param siteId - string id for the site in question
 * @returns JSON | Promise rejection
 */
const SiteDetailsFetcher = async (siteId: string, auth: any) => {
  const token = await auth.getAccessTokenSilently();
  const url = `/api/v1/site/${siteId}`
  const response = fetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
    .then(data => data)
  return response
}

/**
 * Retrieves all analytic views for the current user
 * @param auth Auth instance
 * @returns Array - list of presets with details for each one
 */
const GetAnalyticViews = async (auth: any) => {
  const token = await auth.getAccessTokenSilently();
  const url = `/api/v1/analytics/views`
  const response = fetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
    .then(data => data)
  return response
}

/**
 * Saves the currently active view/preset for the current user
 * @param auth Auth instance
 * @returns Array - list of presets with details for each one
 */
const SaveAnalyticView = async (viewData: any, auth: any) => {
  const token = await auth.getAccessTokenSilently();
  let method = 'POST'
  let url = `/api/v1/analytics/views`
  /*
  * A fair assumption: If the ID is set, we'll update via PUT.
  * Otherwise use POST to create a new view.
  */
  if (viewData?.node_key && viewData?.node_key !== null) {
    url = `/api/v1/analytics/views/${viewData.node_key}`
    method = 'PUT'
  }
  const response = fetch(
    url,
    {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(viewData)
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
    .then(data => data)
  return response
}

/**
 * Deletes the currently active view/preset for the current user
 * @param auth Auth instance
 * @returns void - 204 response code if delete was successful
 */
const DeleteAnalyticView = async (viewData: any, auth: any) => {
  const token = await auth.getAccessTokenSilently();
  let method = 'DELETE'
  let url = `/api/v1/analytics/views/${viewData?.node_key}`
  const response = fetch(
    url,
    {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(res => {
      if (res.ok) {
        return res.ok
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
    .then(data => data)
  return response
}

const SensorDetailsFetcher = async (siteId: string, auth: any) => {
  const token = await auth.getAccessTokenSilently();
  const url = `/api/v1/sensor/site/${siteId}`
  const response = fetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
    .then(data => data)
  return response
}

const TimelineDataFetcher = async (params: any) => {
  fetch(params.url)
    .then(async (res) => {
      if (res.ok) {
        return await res.json()
      } else {
        const error = new Error(res.statusText)
        return Promise.reject(error)
      }
    })
}

const AuthFetcher = async (url: string, getAccessTokenSilently: () => Promise<string>, props?: fetchProps) => {
  const options: any = {
    method: props?.method || 'GET',
    headers: props?.headers || { Accept: 'application/json', 'Content-Type': 'application/json' },
  };

  if (props?.body) {
    if (options.headers['Content-Type'] === 'application/json') {
      options.body = JSON.stringify(props.body);
    }
  }

  if (!url) {
    return null;
  }
  try {
    let token = await getAccessTokenSilently();
    options.headers.Authorization = `Bearer ` + token;
  } catch (err) {
    console.log('unable to fetch auth token');
    throw err;
  }

  const response = await fetch(url, options);

  if (props?.raw) {
    // Want full control over the response
    return response;
  }

  if (!response.ok) {
    console.log(`The request to ${url} failed. Status code: ${response.status}`);
    throw new Error('An error occurred while fetching the data. Cause: ' + response.status);
  }
  const contentType = response.headers.get('content-type') || '';
  if (contentType.indexOf('json') > -1 || url.indexOf('spectrum') > -1) {
    return response.json();
  } else {
    return response.blob();
  }
};

const SlAlarmStatusesHandler = function (fetched: Promise<any> | null): Promise<SlScenarioStatus[]> | undefined {
  return fetched?.then((value: any) => {
    const ScenarioStatus: SlScenarioStatus[] = value;
    return ScenarioStatus;
  });
};

const SiteHandler = function (fetched: Promise<any> | null): Promise<SiteContents> | undefined {
  return fetched?.then((value: any) => {
    const siteContents: SiteContents = value;
    return siteContents;
  });
};

export {
  GetAnalyticViews,
  SaveAnalyticView,
  DeleteAnalyticView,
  AuthFetcher,
  TimelineDataFetcher,
  SiteHandler,
  SlAlarmStatusesHandler,
  SiteDetailsFetcher,
  SensorDetailsFetcher
};

import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  const onClick = () => {
    try {
      logout({ returnTo: window.location.origin });
    } catch (e) {
      console.log('Failed!', e);
    }
  };

  return (
    <Menu.Item as={Link} to="#" onClick={onClick}>
      Log out
      <Icon
        name="log out"
        className='ms-1'
      />
    </Menu.Item>
  );
};

export default LogoutButton;

import _ from 'lodash';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import {
  Accordion,
  Button,
  ButtonGroup,
  Container,
  DropdownItemProps,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
  Table,
  Transition,
} from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import useSWR, { mutate } from 'swr';
import NotyfContext from '../Components/NotyfContext';
import useSensorConfigStore from '../Components/SensorConfig/SensorConfigStore';
import { AuthFetcher } from '../lib/fetch';
import { SensorConfigContents, SensorDeviceContents, SensorSettingsContents } from '../lib/types';
import useStore from '../Store/Store';

interface SensorDisplayProps {
  device?: SensorDeviceContents;
}
interface ChargingIconProps {
  fullyCharged?: boolean;
}
const ChargingIcon = ({ fullyCharged }: ChargingIconProps) => {
  return (
    <Icon
      color={fullyCharged ? 'green' : 'orange'}
      size="large"
      name={fullyCharged ? 'battery full' : 'lightning'}
    ></Icon>
  );
};

interface CurrentSensorConfigProps {
  mac: string;
}

const CurrentSensorConfig = observer(({ mac }: CurrentSensorConfigProps) => {
  const { authStore, siteStore } = useStore();
  const { sensorConfigStore } = useSensorConfigStore();
  const { data, error } = useSWR('/api/v1/sensorconfig/config/' + siteStore.getSiteKey() + '/' + mac, (url) =>
    AuthFetcher(url, authStore.getTokenFunction())
  );
  if (error) {
    sensorConfigStore.setConfigMac('');
  }
  if (!data) {
    return <Button loading>Get Config</Button>;
  }

  if (sensorConfigStore.getConfigMac() !== mac) {
    return <></>;
  }
  const sensorConfig: SensorConfigContents = data;
  return (
    <Segment>
      <SensorConfigView sensorConfig={sensorConfig}></SensorConfigView>
      <SensorConfigForm mac={mac}></SensorConfigForm>
    </Segment>
  );
});

interface SensorConfigFormProps {
  mac: string;
}

const SensorConfigForm = ({ mac }: SensorConfigFormProps) => {
  const notyf = useContext(NotyfContext);
  const [apply, setApply] = useState<boolean>();
  const [show, setShow] = useState<boolean>();
  const { siteStore, authStore } = useStore();
  const [chosenSensorSetting, chooseSensorSetting] = useState<SensorSettingsContents>();
  const { data, error } = useSWR('/api/v1/sensorconfig', (url) => AuthFetcher(url, authStore.getTokenFunction()));
  const sensorSettings: SensorSettingsContents[] = [];
  const sensorConfigOpts: DropdownItemProps[] = [];
  if (!show) {
    return <Button onClick={() => setShow(true)}>Change</Button>;
  }
  if (data) {
    sensorSettings.push(...data);
    sensorSettings.forEach((ss: SensorSettingsContents) => {
      sensorConfigOpts.push({ key: ss.node_key, value: ss.node_key, text: ss.sensor_config.name });
    });
  }
  if (error) {
    notyf.error('unable to fetch sensor configuration options');
    setShow(false);
  }
  const applyConfig = async () => {
    if (!chosenSensorSetting) {
      notyf.error('no sensor setting has been chosen');
      return;
    }
    setApply(true);
    const resp = await AuthFetcher(
      '/api/v1/sensorconfig/config/' + siteStore.getSiteKey() + '/' + mac + '/' + chosenSensorSetting.node_key,
      authStore.getTokenFunction(),
      { raw: true, method: 'PUT' }
    );
    setApply(false);
    if (resp.ok) {
      notyf.success('applied new settings!');
      mutate('/api/v1/sensorconfig/config/' + siteStore.getSiteKey() + '/' + mac);
    } else {
      notyf.error('unable to apply new settings!');
    }
  };

  return (
    <Container fluid style={{ width: '90%' }} textAlign="left">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h3'}>Sensor Settings</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group inline={sensorSettings.length > 3}>
                <label>Measurement settings</label>
                {sensorSettings.map((v: SensorSettingsContents) => (
                  <Form.Radio
                    key={v.node_key}
                    checked={chosenSensorSetting?.node_key === v.node_key}
                    value={v.node_key}
                    onChange={(e, data) => {
                      if (data.value) {
                        var chosenSensorSetting = sensorSettings.find((value) => value.node_key === data.value);
                        if (chosenSensorSetting) {
                          chooseSensorSetting(chosenSensorSetting);
                        }
                      }
                    }}
                    label={v.sensor_config.name}
                  ></Form.Radio>
                ))}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column>
            <SensorConfigView sensorConfig={chosenSensorSetting?.sensor_config}></SensorConfigView>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button.Group>
              <Button loading={apply} positive onClick={applyConfig}>
                Apply
              </Button>
              <Button negative onClick={() => setShow(false)}>
                Cancel
              </Button>
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

interface PlaySoundButtonProps {
  mac: string;
}

const PlaySoundButton = observer(({ mac }: PlaySoundButtonProps) => {
  const notyf = useContext(NotyfContext);
  const { siteStore, authStore } = useStore();
  const { sensorConfigStore } = useSensorConfigStore();
  const [playSoundWait, setPlaySoundWait] = useState<boolean>(false);
  const playSound = async () => {
    setPlaySoundWait(true);
    const response = await AuthFetcher(
      '/api/v1/sensorconfig/test/' + siteStore.getSiteKey() + '/' + mac,
      authStore.getTokenFunction(),
      { raw: true, method: 'PUT' }
    );
    setPlaySoundWait(false);
    if (response.ok) {
      sensorConfigStore.setConnectedMac(mac);
      notyf.success('connected');
    } else {
      sensorConfigStore.setConnectedMac('');
      notyf.error('unable to connect to device');
    }
    console.log(response);
  };

  return (
    <Button positive loading={playSoundWait} onClick={playSound}>
      Connect
    </Button>
  );
});

const SensorDisplay = observer(({ device }: SensorDisplayProps) => {
  if (!device) {
    return (
      <Segment raised vertical padded style={{ minHeight: '250px', minWidth: '300px', width: '100%' }}>
        <Loader active></Loader>
      </Segment>
    );
  }
  const { sensorConfigStore } = useSensorConfigStore();
  let inStorageColor: SemanticCOLORS = device.in_storage ? 'orange' : 'green';
  const active = sensorConfigStore.getConnectedMac() === device.mac;

  const name = device.serial_number === 0 ? device.mac : _.capitalize(device.sensor_type) + '-' + device.serial_number;
  return (
    <Accordion
      as={Segment}
      raised
      vertical
      padded
      color={inStorageColor}
      style={{ minHeight: '80px', minWidth: '300px', width: '100%' }}
    >
      <Accordion.Title active={active}>
        <Grid style={{ margin: '0 auto', width: '100%' }}>
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left" stretched>
              <Header>{name}</Header>
            </Grid.Column>
            <Grid.Column stretched textAlign="right">
              <Header color={inStorageColor}>{device.in_storage ? 'Storage' : 'Operational'}</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as={'h4'}>Charge status:</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <ChargingIcon fullyCharged={device.is_fully_charged} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button.Group fluid>
                <PlaySoundButton mac={device.mac} />
                <Button
                  negative
                  onClick={() => {
                    sensorConfigStore.setConnectedMac('');
                    sensorConfigStore.setConfigMac('');
                  }}
                >
                  Close
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <SensorConfigSegment mac={device.mac} />
      </Accordion.Content>
    </Accordion>
  );
});

interface SensorConfigSegmentProps {
  mac: string;
}

const SensorConfigSegment = observer(({ mac }: SensorConfigSegmentProps) => {
  const { sensorConfigStore } = useSensorConfigStore();
  if (sensorConfigStore.getConfigMac() === mac) {
    return <CurrentSensorConfig mac={mac} />;
  }
  return <Button onClick={() => sensorConfigStore.setConfigMac(mac)}>Get config</Button>;
});

const fromMStoSec = (ms: number | undefined) => {
  if (!ms) return ms;
  return ms / 1000;
};

interface SensorConfigViewProps {
  sensorConfig: SensorConfigContents | undefined;
}
const SensorConfigView = ({ sensorConfig }: SensorConfigViewProps) => {
  return (
    <>
      <Header>{sensorConfig?.name}</Header>
      <Message>{sensorConfig?.info}</Message>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Value(s)</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>measurement_period</Table.Cell>
            <Table.Cell>{fromMStoSec(sensorConfig?.device_config.measurement_period)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>transmit_block</Table.Cell>
            <Table.Cell>{fromMStoSec(sensorConfig?.device_config.transmit_block)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>transmit_period</Table.Cell>
            <Table.Cell>{fromMStoSec(sensorConfig?.device_config.transmit_period)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

interface SensorConfigurationProps {
  match?: any;
}

interface ScannerProps {
  siteID: string;
}

const Scanner = observer(({ siteID }: ScannerProps) => {
  const { authStore } = useStore();
  const { sensorConfigStore } = useSensorConfigStore();
  const { data } = useSWR(
    sensorConfigStore.shouldScan() ? '/api/v1/sensorconfig/scan/' + siteID : null,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    { refreshInterval: 1000 }
  );
  if (data) {
    sensorConfigStore.setScanning(data.is_scanning);
    sensorConfigStore.addDevices(data.result);
    data.result.sort((a: SensorDeviceContents, b: SensorDeviceContents) => {
      if (a.sensor_type === b.sensor_type) {
        if (a.serial_number === b.serial_number) {
          return a.mac > b.mac ? 1 : -1;
        } else {
          return a.serial_number > b.serial_number ? 1 : -1;
        }
      } else {
        return a.sensor_type > b.sensor_type ? 1 : -1;
      }
    });
  }
  return (
    <Button
      onClick={() => {
        sensorConfigStore.toggleStartScan();
        // setTimeout(()=>sensorConfigStore.setStartScan(false),10000)
      }}
      positive={!sensorConfigStore.shouldScan()}
      negative={sensorConfigStore.shouldScan()}
      loading={sensorConfigStore.shouldScan()}
    >
      {sensorConfigStore.shouldScan() ? 'Stop' : 'Start Scan'}
    </Button>
  );
});

const Sensors = observer(() => {
  const { sensorConfigStore } = useSensorConfigStore();
  const devices = sensorConfigStore.getDevices();
  devices.slice().sort((a: SensorDeviceContents, b: SensorDeviceContents) => {
    if (a.sensor_type === b.sensor_type) {
      if (a.serial_number === b.serial_number) {
        return a.mac > b.mac ? 1 : -1;
      } else {
        return a.serial_number > b.serial_number ? 1 : -1;
      }
    } else {
      return a.sensor_type > b.sensor_type ? 1 : -1;
    }
  });
  return (
    <Segment>
      <Transition.Group animation="scale" duration={500} as={Grid} stackable columns={3}>
        {devices.map((device) => (
          <Grid.Column>
            <SensorDisplay device={device} />
          </Grid.Column>
        ))}
        {sensorConfigStore.shouldScan() && (
          <Grid.Column>
            <SensorDisplay></SensorDisplay>
          </Grid.Column>
        )}
      </Transition.Group>
    </Segment>
  );
});

const SensorConfiguration = observer(({ match }: SensorConfigurationProps) => {
  const { siteStore, appStore } = useStore();
  appStore.setBreadcrumb('Sensor Configuration');
  const siteID: string = match?.params?.siteID ? match.params.siteID : siteStore.getSiteKey();

  return (
    <Segment style={{ marginTop: '50px', minHeight: '300px' }}>
      <Header>Sensor Configuration</Header>
      <ButtonGroup>
        <Scanner siteID={siteID}></Scanner>
      </ButtonGroup>
      <Sensors></Sensors>
    </Segment>
  );
});

export default SensorConfiguration;

import { observer } from 'mobx-react';
import useStore from '../../Store/Store';
import { AuthFetcher } from '../../lib/fetch';
import useSWR from 'swr';
import LeafletOverviewMap from '../OverviewMap/LeafletOverviewMap';

const SiteSetup = observer(() => {
  const { authStore, firstTimeSetupStore, siteStore } = useStore();
  let activeGatewayId = firstTimeSetupStore.getActiveGatewayId();
  const currentStep = firstTimeSetupStore.getCurrentStep();
  let sites: any = [];
  const { data, error } = useSWR('/api/v1/site', (url) => AuthFetcher(url, authStore.getTokenFunction()), {
    revalidateIfStale: true,
    revalidateOnReconnect: true,
  });
  sites = data;

  /**
* Computational getter for what site a gateway belongs to. Uses the siteStore and iterates
* each entry for the provided gatewayName
* 
* @param gatewayName 
*/
  const siteName = (gatewayName: string | null) => {
    if (gatewayName) {
      const results = sites.filter((e: any) => e.topside_id.toLowerCase() === gatewayName.toLowerCase())
      // There must be exactly one site that is connected to this gateway
      return results.length === 1 ? results[0].name : `Not connected to a site (${results.length})`
    }
  }
  if (error) {
    console.error('Unable to fetch sites');
  } else {
    // Site setup is complete if there are at least 1 site
    if (sites && sites?.length > 0) {
      // Find the siteID for the selected gateway
      const results = sites.filter((e: any) => e.topside_id.toLowerCase() === activeGatewayId?.toLowerCase())
      if (results.length === 1) {
        // Set the step to complete
        siteStore.setSiteKey(results[0].node_key);
        firstTimeSetupStore.setStepComplete(currentStep);
      }
    }
  }

  return (
    <div>
      <p>
        Next, we need to know where your site is. Use the map and the "Add site" button below to add one. If a site's
        already been setup, it will be set as the active one.
      </p>
      <div className='d-flex mb-2'>
        <div>
          <strong>Gateway</strong>: {activeGatewayId}
        </div>
        <div className='ms-3'>
          <strong>Site</strong>: {siteName(activeGatewayId)}
        </div>
      </div>
      <LeafletOverviewMap height="50vh" disableGotoSite={true} />
      <p className="mt-3">
        <em>The map can be zoomed and dragged.</em>
      </p>
    </div>
  );
});

export default SiteSetup;

import { useEffect, useState } from 'react';
import { Grid, Label, Input, Radio, Dropdown, Icon } from 'semantic-ui-react';
import { AlarmContents, ALARM_NODE_TYPE, TriggerContents } from '../../lib/types';

const triggerTypeOpts = [
  { key: 'less_than', text: 'less than', value: 'less_than' },
  { key: 'more_than', text: 'more than', value: 'more_than' },
];
const measurementsOpts = [
  { key: 'oxygen', text: 'Oxygen', value: 'oxygen' },
  { key: 'pitch', text: 'Tilt', value: 'pitch' },
  { key: 'salinity', text: 'Salinity', value: 'salinity' },
  { key: 'depth', text: 'Depth', value: 'depth' },
  { key: 'temperature', text: 'Temperature', value: 'temperature' },
  { key: 'turbidity', text: 'Turbidity', value: 'turbidity' },
];

const invalidLimits = (mType: string, tType: string, warn: number, crit: number) => {
  switch (tType) {
    default:
      return true;
    case 'less_than':
      return warn <= crit;
    case 'more_than':
      return warn >= crit;
  }
};

const invalidDevianceLimits = (baseline: number, warn: number, crit: number) => {
  return baseline - warn <= baseline - crit || baseline + warn >= baseline + crit;
};

interface OnePointDevianceAlarmProps {
  setTrigger: (a: TriggerContents) => void;
  defaultTrigger?: TriggerContents;
}

const OnePointDevianceAlarm = ({ setTrigger, defaultTrigger }: OnePointDevianceAlarmProps) => {
  const [mType, setMType] = useState<string>('');
  const [baseline, setBaseline] = useState<number>(0);
  const [crit, setCrit] = useState<number>(0);
  const [warn, setWarn] = useState<number>(0);

  useEffect(() => {
    if (mType !== '' && baseline !== 0 && crit !== 0 && warn !== 0) {
      let newTrigger: TriggerContents = {
        trigger_type: 'one_dimension_point',
        trigger_action: '',
        measurements: mType,
        warning_trigger_distance: 0,
        warning_point: warn,
        critical_point: crit,
        baseline_point: baseline,
      };
      setTrigger(newTrigger);
    } else if (defaultTrigger) {
      setMType(defaultTrigger.measurements);
      setBaseline(defaultTrigger.baseline_point);
      setCrit(defaultTrigger.critical_point);
      setWarn(defaultTrigger.warning_point);
    }
  }, [mType, baseline, crit, warn, setTrigger, defaultTrigger]);
  return (
    <Grid>
      <Grid.Row columns={5}>
        <Grid.Column textAlign="right">
          <Icon name="circle" color="yellow" />
          <Label>Warning limit</Label>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={measurementsOpts}
            value={mType}
            onChange={(e, d) => {
              if (d.value) {
                setMType(d.value.toString());
              }
            }}
          ></Dropdown>
        </Grid.Column>

        <Grid.Column>
          <Input
            error={invalidDevianceLimits(baseline, warn, crit)}
            type="number"
            value={warn}
            onChange={(e, d) => {
              if (+d.value > 0) {
                setWarn(+d.value);
              }
            }}
          ></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" columns={5}>
        <Grid.Column textAlign="right">
          <Icon name="circle" color="red" />
          <Label>Critical limit</Label>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={measurementsOpts}
            value={mType}
            onChange={(e, d) => {
              if (d.value) {
                setMType(d.value.toString());
              }
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            error={invalidDevianceLimits(baseline, warn, crit)}
            type="number"
            value={crit}
            onChange={(e, d) => {
              if (+d.value > 0) {
                setCrit(+d.value);
              }
            }}
          ></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" columns={5}>
        <Grid.Column textAlign="right">
          <Label>Baseline</Label>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={measurementsOpts}
            value={mType}
            onChange={(e, d) => {
              if (d.value) {
                setMType(d.value.toString());
              }
            }}
          ></Dropdown>
        </Grid.Column>
        <Grid.Column>
          <Input
            error={invalidDevianceLimits(baseline, warn, crit)}
            type="number"
            value={baseline}
            onChange={(e, d) => {
              setBaseline(+d.value);
            }}
          ></Input>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface OneDimensionAlarmProps {
  setTrigger: (a: TriggerContents) => void;
  defaultTrigger?: TriggerContents;
}

const OneDimensionAlarm = ({ setTrigger, defaultTrigger }: OneDimensionAlarmProps) => {
  const [mType, setMType] = useState<string>('');
  const [aType, setAType] = useState<string>('');
  const [crit, setCrit] = useState<number>(0);

  const [warn, setWarn] = useState<number>(0);

  useEffect(() => {
    if (mType !== '' && aType !== '' && crit !== 0 && warn !== 0) {
      let newTrigger: TriggerContents = {
        trigger_type: 'one_dimension_point',
        trigger_action: aType,
        measurements: mType,
        warning_trigger_distance: 0,
        warning_point: warn,
        critical_point: crit,
        baseline_point: -1,
      };
      setTrigger(newTrigger);
    } else if (defaultTrigger) {
      setMType(defaultTrigger.measurements);
      setAType(defaultTrigger.trigger_action);
      setCrit(defaultTrigger.critical_point);
      setWarn(defaultTrigger.warning_point);
    }
  }, [mType, aType, crit, warn, setTrigger, defaultTrigger]);
  return (
    <Grid columns={5}>
      <Grid.Row verticalAlign="middle" columns={5}>
        <Grid.Column textAlign="center">
          <Label>Limit type</Label>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Label>Measurement</Label>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Label>More or less</Label>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Label>Limit</Label>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" columns={5}>
        <Grid.Column textAlign="center">
          <Icon name="circle" color="yellow" />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Dropdown
            options={measurementsOpts}
            value={mType}
            onChange={(e, d) => {
              if (d.value) {
                setMType(d.value.toString());
              }
            }}
          ></Dropdown>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Dropdown
            options={triggerTypeOpts}
            value={aType}
            onChange={(e, d) => {
              if (d.value) {
                setAType(d.value.toString());
              }
            }}
          ></Dropdown>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Input
            error={invalidLimits(mType, aType, warn, crit)}
            type="number"
            value={warn}
            onChange={(e, d) => {
              setWarn(+d.value);
            }}
            fluid
          ></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" columns={5}>
        <Grid.Column textAlign="center">
          <Icon name="circle" color="red" />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Dropdown
            options={measurementsOpts}
            value={mType}
            onChange={(e, d) => {
              if (d.value) {
                setMType(d.value.toString());
              }
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Dropdown
            options={triggerTypeOpts}
            value={aType}
            onChange={(e, d) => {
              if (d.value) {
                setAType(d.value.toString());
              }
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign="center" stretched>
          <Input
            error={invalidLimits(mType, aType, warn, crit)}
            type="number"
            value={crit}
            onChange={(e, d) => {
              setCrit(+d.value);
            }}
            fluid
          ></Input>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface AlarmFormProps {
  setAlarm: (a: AlarmContents | undefined) => void;
  defaultAlarm?: AlarmContents;
}

const AlarmForm = ({ setAlarm, defaultAlarm }: AlarmFormProps) => {
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>();
  const [trigger, setTrigger] = useState<TriggerContents>();
  useEffect(() => {
    if (trigger && type !== '' && name !== '') {
      let newAlarm: AlarmContents = {
        node_key: defaultAlarm ? defaultAlarm.node_key : '',
        node_kind: ALARM_NODE_TYPE,
        name: name,
        description: '',
        trigger: trigger,
      };
      setAlarm(newAlarm);
    } else if (defaultAlarm) {
      setName(defaultAlarm.name);
      setType(defaultAlarm.trigger.trigger_type);
      setTrigger(defaultAlarm.trigger);
    }
  }, [name, type, trigger, setAlarm, defaultAlarm]);
  return (
    <Grid>
      <Grid.Row columns={5}>
        <Grid.Column textAlign="right">
          <Label size="large">Name</Label>
        </Grid.Column>
        <Grid.Column>
          <Input onChange={(e, d) => setName(d.value)} value={name} type="text"></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={5}>
        <Grid.Column textAlign="right">
          <Label size="large">Description</Label>
        </Grid.Column>
        <Grid.Column>
          <Input></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={5}>
        <Grid.Column textAlign="right">
          <Label size="large">Alarm Type</Label>
        </Grid.Column>
        <Grid.Column>
          <Radio
            checked={type === 'one_dimension_point'}
            label={'Less or More'}
            onChange={(e, d) => {
              if (d.checked) {
                setType('one_dimension_point');
              }
            }}
          ></Radio>
        </Grid.Column>
        <Grid.Column>
          <Radio
            checked={type === 'one_dimension_deviance'}
            label={'Outside'}
            onChange={(e, d) => {
              if (d.checked) {
                setType('one_dimension_deviance');
              }
            }}
          ></Radio>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        {type === 'one_dimension_point' && (
          <OneDimensionAlarm setTrigger={setTrigger} defaultTrigger={defaultAlarm?.trigger} />
        )}
        {type === 'one_dimension_deviance' && (
          <OnePointDevianceAlarm setTrigger={setTrigger} defaultTrigger={defaultAlarm?.trigger}></OnePointDevianceAlarm>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default AlarmForm;

import React, { useState } from 'react';
import { Popup, Button, Checkbox, Form } from 'semantic-ui-react';
import fetch from 'unfetch';

const FactoryResetButton = () => {
  const [isActive, setIsActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  // Form selection values
  const [isTimeSeries, setIsTimeSeries] = useState(true);
  const [isRelations, setIsRelations] = useState(true);

  const onClick = async () => {
    if (isActive) {
      console.log('in progress. ignoring');
      return;
    }
    setIsSubmitFail(false);
    setIsActive(true);

    const params = new URLSearchParams({
      ...(isTimeSeries && { timeseries: '1' }),
      ...(isRelations && { relations: '1' }),
    });

    const result = await fetch('/api/v1/about/factoryreset?' + params.toString(), {
      method: 'POST',
    });
    if (result.status !== 200) {
      const body = await result.text();
      console.log('Reset failed:', result.status, body);
      setIsSuccess(false);
      setIsSubmitFail(true);
      // Resetting
      setTimeout(() => {
        setIsSubmitFail(false);
      }, 1000);
    } else {
      setIsSuccess(true);
      setIsSubmitFail(false);
    }
    // Resetting
    setTimeout(() => {
      setIsActive(false);
    }, 1000);
  };

  return (
    <Popup
      trigger={
        <Button
          type="button"
          name="cog"
          //color="grey"
          title="Reset to factory defaults"
        >
          Factory reset
        </Button>
      }
      onOpen={() => {
        setIsSubmitFail(false);
        setIsSuccess(false);
      }}
      content={
        <div>
          <p>Are you sure? Stored data will be lost.</p>
          <p>System will automatically reboot after button is pressed.</p>
          <Form>
            <Form.Field>
              <Checkbox
                toggle
                label="Time series data"
                checked={isTimeSeries}
                onChange={() => {
                  setIsTimeSeries(!isTimeSeries);
                }}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                label="Relations data"
                checked={isRelations}
                onChange={() => {
                  setIsRelations(!isRelations);
                }}
              />
            </Form.Field>
            <Button
              disabled={!isTimeSeries && !isRelations}
              color={isSubmitFail ? 'red' : isActive ? 'green' : 'grey'}
              onClick={onClick}
              name={'redo'}
              content="Confirm factory defaults"
            />
          </Form>
          {isSuccess && (
            <p>
              Success. System is now restarting. Please refresh window by clicking:
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                content="Reload"
              />
            </p>
          )}
        </div>
      }
      on="click"
      position="right center"
    />
  );
};

export { FactoryResetButton };

import { extend, ReactThreeFiber } from '@react-three/fiber';
import * as THREE from 'three';

const vertexShader = `varying vec3 vColor;

varying float vY;

void main()
{
    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    vY = worldPosition.y;
    gl_Position = projectionMatrix * viewMatrix * worldPosition;
}`;

const fragmentShader = `precision mediump float;

const  vec4 okColor=vec4(0.169,0.612,0.416,0.9);
const  vec4 warningColor=vec4(1.,0.784,0.231,0.9);
const  vec4 criticalColor=vec4(0.831,0.282,0.294,0.9);
const  vec4 inactiveColor=vec4(0.8,0.8,0.8,1.0);

varying float vY;

uniform float depth1;
uniform float depth2;
uniform float depth3;
uniform float depth4;

uniform float maxDepth;

uniform int depth1Severity;
uniform int depth2Severity;
uniform int depth3Severity;
uniform int depth4Severity;


uniform float transitionHeight;


vec4 pickColor(in int status);

vec4 pickColor(in int status){
  vec4 color = inactiveColor;
  if (status == 0){
    color = okColor;
  }else if (status == 1){
   color = warningColor;
  }else if (status == 2){
    color = criticalColor;
  }else{
    color = inactiveColor;
  }
  return(color);
}

float findTransitionStart(in float firstDepth, in float secondDepth,in float transitionHeight);

float findTransitionStart(in float firstDepth, in float secondDepth,in float transitionHeight){
  return (-((firstDepth+secondDepth)/2.0)+ (transitionHeight/2.0));
}

float findTransitionEnd(in float firstDepth, in float secondDepth,in float transitionHeight);

float findTransitionEnd(in float firstDepth, in float secondDepth,in float transitionHeight){
  return (-((firstDepth+secondDepth) / 2.0) - (transitionHeight / 2.0));
}


void main()
{   
    float trans1Start = findTransitionStart(depth1,depth2,transitionHeight);
    float trans1End = findTransitionEnd(depth1,depth2,transitionHeight);

    float trans2Start = findTransitionStart(depth2,depth3,transitionHeight);
    float trans2End = findTransitionEnd(depth2,depth3,transitionHeight);

    float trans3Start = findTransitionStart(depth3,depth4,transitionHeight);
    float trans3End = findTransitionEnd(depth3,depth4,transitionHeight);

    if (vY > trans1Start) {
        gl_FragColor = pickColor(depth1Severity);
    } else if (vY > trans1End){
        float p = ((trans1End - vY) /transitionHeight);
        gl_FragColor = mix(pickColor(depth2Severity), pickColor(depth1Severity), p);
    } else if (vY > trans2Start) {
        gl_FragColor = pickColor(depth2Severity);
    } else if (vY > trans2End) {
        float p = ((trans2End - vY) / (transitionHeight));
        gl_FragColor = mix(pickColor(depth3Severity), pickColor(depth2Severity), p);
    } else if (vY > trans3Start) {
        gl_FragColor = pickColor(depth3Severity);
    } else if (vY > trans3End) {
        float p = ((trans3End - vY) / (transitionHeight));
        gl_FragColor = mix(pickColor(depth4Severity), pickColor(depth3Severity), p);
    } else if (vY > -maxDepth){
        gl_FragColor = pickColor(depth4Severity);
    } else {
        gl_FragColor = inactiveColor;
    }
}`;

export class Cage4DepthMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        depth1: { value: 5.0 },
        depth2: { value: 15.0 },
        depth3: { value: 25.0 },
        depth4: { value: 35.0 },
        maxDepth: { value: 45.0 },

        transitionHeight: { value: 1.0 },

        hovered: { value: false },

        depth1Severity: { value: -1 },
        depth2Severity: { value: -1 },
        depth3Severity: { value: -1 },
        depth4Severity: { value: -1 },
      },
      transparent: true,

      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
  }

  get depth1() {
    return this.uniforms.depth1.value;
  }

  set depth1(v) {
    this.uniforms.depth1.value = v;
  }

  get depth2() {
    return this.uniforms.depth2.value;
  }

  set depth2(v) {
    this.uniforms.depth2.value = v;
  }

  get depth3() {
    return this.uniforms.depth3.value;
  }

  set depth3(v) {
    this.uniforms.depth3.value = v;
  }

  get depth4() {
    return this.uniforms.depth4.value;
  }

  set depth4(v) {
    this.uniforms.depth4.value = v;
  }

  get depth1Severity() {
    return this.uniforms.depth1Severity.value;
  }

  set depth1Severity(v) {
    this.uniforms.depth1Severity.value = v;
  }

  get depth2Severity() {
    return this.uniforms.depth2Severity.value;
  }

  set depth2Severity(v) {
    this.uniforms.depth2Severity.value = v;
  }

  get depth3Severity() {
    return this.uniforms.depth3Severity.value;
  }

  set depth3Severity(v) {
    this.uniforms.depth3Severity.value = v;
  }

  get depth4Severity() {
    return this.uniforms.depth4Severity.value;
  }

  set depth4Severity(v) {
    this.uniforms.depth4Severity.value = v;
  }
  get hovered() {
    return this.uniforms.hovered.value;
  }

  set hovered(v) {
    this.uniforms.hovered.value = v;
  }

  get transitionHeight() {
    return this.uniforms.transitionHeight.value;
  }

  set transitionHeight(v) {
    this.uniforms.transitionHeight.value = v;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      cage4DepthMaterial: ReactThreeFiber.Object3DNode<Cage4DepthMaterial, typeof Cage4DepthMaterial>;
    }
  }
}

extend({ Cage4DepthMaterial });

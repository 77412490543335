import { Button, Container, Dimmer, Header, Icon, Input, Popup, Segment, Table } from 'semantic-ui-react';
import { AuthFetcher } from '../lib/fetch';
import useSWR, { mutate, useSWRConfig } from 'swr';
import { Organization as CagesenseOrganization, OrganizationAndSites } from '../lib/types';
import { Fragment, useContext, useState } from 'react';
import NotyfContext from '../Components/NotyfContext';
import { observer } from 'mobx-react';
import useStore from '../Store/Store';
import _ from 'lodash';

const InputWithDisable = ({ value, setValue, reset, setReset }: any) => {
  const [enabled, setEnabled] = useState<boolean>();
  if (reset && enabled) {
    setEnabled(false);
    setReset(false);
  }
  if (enabled) {
    return (
      <Container fluid>
        <Input placeholder={value} onChange={(e, data) => setValue(data.value)}></Input>
        <Icon name="remove circle" color="red" size="big" onClick={() => setEnabled(false)}></Icon>
      </Container>
    );
  }
  return <Icon name="plus circle" color="green" size="big" onClick={() => setEnabled(true)}></Icon>;
};
interface EditOrganizationProps {
  oldOrganization: CagesenseOrganization;

  newOrgId: string;
  newName: string;
  canEdit: boolean;
  enableEdit: (e: boolean) => void;
}

const EditOrganization = observer(
  ({ oldOrganization, newOrgId, newName, canEdit, enableEdit }: EditOrganizationProps) => {
    const { authStore } = useStore();
    const notyf = useContext(NotyfContext);
    const editOrganization = async () => {
      if (canEdit) {
        let organization: CagesenseOrganization = {
          node_key: oldOrganization.node_key,
          org_id: oldOrganization.org_id,
          name: oldOrganization.name,
        };
        if (newOrgId !== '') {
          organization.org_id = newOrgId;
        }
        if (newName !== '') {
          organization.name = newName;
        }
        let response = await AuthFetcher('/api/v1/admin/edit/organization', authStore.getTokenFunction(), {
          method: 'PUT',
          body: organization,
          raw: true,
        });
        if (response.ok) {
          notyf.success('Edited ' + oldOrganization.node_key + ' sucessfully');
        } else {
          notyf.error('Failed editing ' + oldOrganization.node_key);
        }
        mutate('/api/v1/admin/users');
      }
      enableEdit(canEdit === false);
    };

    return (
      <Button color={canEdit ? 'green' : undefined} onClick={editOrganization}>
        Edit
      </Button>
    );
  }
);

interface RemoveOrganizationProps {
  nodeKey: string;
}

const RemoveOrganization = observer(({ nodeKey }: RemoveOrganizationProps) => {
  const { authStore } = useStore();
  const { mutate } = useSWRConfig();
  const notyf = useContext(NotyfContext);
  const [open, setOpen] = useState<boolean>(false);
  const removeOrganization = async () => {
    let response = await AuthFetcher('/api/v1/admin/remove/organization/' + nodeKey, authStore.getTokenFunction(), {
      method: 'DELETE',
      raw: true,
    });

    if (response.ok) {
      setOpen(false);
      mutate('/api/v1/admin/users');
      notyf.success('Sucessfully removed organization');
    } else {
      notyf.error('Unable to remove organization');
    }
  };
  return (
    <Popup
      eventsEnabled
      on="click"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color="red">Remove</Button>}
    >
      <Popup.Header>Are you sure?</Popup.Header>
      <Popup.Content>
        <Button onClick={removeOrganization} color="green">
          Yes
        </Button>
        <Button onClick={() => setOpen(false)} color="red">
          No
        </Button>
      </Popup.Content>
    </Popup>
  );
});

const AddOrganization = observer(() => {
  const { authStore } = useStore();
  const { mutate } = useSWRConfig();
  const notyf = useContext(NotyfContext);
  const [name, setName] = useState<string>('');
  const [orgId, setOrgId] = useState<string>('');
  const [reset, setReset] = useState<boolean>(false);
  const addOrganization = async () => {
    if (name.trim() === '') {
      notyf.error('Cannot add organization without a name assigned');
      return;
    }
    let organization: CagesenseOrganization = { node_key: '', org_id: orgId, name: name };
    let response = await AuthFetcher('/api/v1/admin/add/organization', authStore.getTokenFunction(), {
      method: 'PUT',
      body: organization,
      raw: true,
    });
    if (response.ok) {
      mutate('/api/v1/admin/users');
      setName('');
      setOrgId('');
      setReset(true);
      notyf.success('Sucessfully added organization');
    } else {
      notyf.error('unable to add organization');
    }
  };
  return (
    <Table.Row>
      <Table.Cell>
        <Input
          value={name}
          placeholder={name}
          onChange={(e, data) => {
            setName(data.value);
          }}
        ></Input>
      </Table.Cell>
      <Table.Cell>
        <InputWithDisable value={orgId} setValue={setOrgId} reset={reset} setReset={setReset}></InputWithDisable>
      </Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <Button color="green" onClick={() => addOrganization()}>
          Submit
        </Button>
      </Table.Cell>
    </Table.Row>
  );
});

interface OrganizationEntryProps {
  organization: CagesenseOrganization;
}

const OrganizationEntry = observer(({ organization }: OrganizationEntryProps) => {
  const [newOrgId, setNewOrgId] = useState<string>(organization.org_id);
  const [newName, setNewName] = useState<string>(organization.name);
  const [canEdit, enableEdit] = useState<boolean>(false);
  return (
    <Table.Row>
      <Table.Cell>
        <Input
          disabled={!canEdit}
          default={organization.name}
          value={newName}
          onChange={(e, data) => {
            setNewName(data.value);
          }}
        ></Input>
      </Table.Cell>
      <Table.Cell>
        <Input
          disabled={!canEdit}
          default={organization.org_id}
          value={newOrgId}
          onChange={(e, data) => {
            setNewOrgId(data.value);
          }}
        ></Input>
      </Table.Cell>
      <Table.Cell>
        <Input disabled={true} default={organization.node_key} value={organization.node_key}></Input>
      </Table.Cell>
      <Table.Cell>
        <EditOrganization
          oldOrganization={organization}
          newName={newName}
          newOrgId={newOrgId}
          canEdit={canEdit}
          enableEdit={enableEdit}
        />
        <RemoveOrganization nodeKey={organization.node_key}></RemoveOrganization>
      </Table.Cell>
    </Table.Row>
  );
});

const Organizations = observer(() => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/admin/users', (url) => AuthFetcher(url, authStore.getTokenFunction()));

  const users: CagesenseOrganization[] = data === undefined ? [] : data;

  users.sort((a: CagesenseOrganization, b: CagesenseOrganization) => {
    if (a.name === b.name) {
      return a.node_key > b.node_key ? 1 : -1;
    }
    return a.name > b.name ? 1 : -1;
  });
  return (
    <Dimmer.Dimmable as={Fragment}>
      <Table celled>
        <Table.Header>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Organization Id</Table.HeaderCell>
          <Table.HeaderCell>Node Key</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {users &&
            users.map((value: CagesenseOrganization) => {
              return <OrganizationEntry key={value.node_key} organization={value}></OrganizationEntry>;
            })}
          <AddOrganization />
        </Table.Body>
      </Table>
      <Dimmer active={error}>
        <Header as={'h2'} inverted>
          Unable to load users
        </Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
});

const Sites = observer(() => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/admin/organizations/sites', (url) => AuthFetcher(url, authStore.getTokenFunction()));

  if (error) {

  }

  const organizations: OrganizationAndSites[] = data === undefined ? [] : data;

  organizations.sort((a: OrganizationAndSites, b: OrganizationAndSites) => {
    if (a.organization.name === b.organization.name) {
      return a.organization.node_key > b.organization.node_key ? 1 : -1;
    }
    return a.organization.name > b.organization.name ? 1 : -1;
  });
  return (<>
    {organizations ? organizations.map((oas, index) => <SitesView key={oas.organization.node_key} orgAndSites={oas}></SitesView>) : <></>}
  </>
  );
});

interface SitesViewProps {
  orgAndSites: OrganizationAndSites
}

const deleteNode = async (siteKey: string, tokenFunction: any, notyf: any) => {
  let response = await AuthFetcher("/api/v1/delete/node/key/" + siteKey + "/kind/Site", tokenFunction, { raw: true, method: "DELETE" })
  if (response.ok) {
    mutate('/api/v1/admin/organizations/sites')
    notyf.success("Removed site")
  } else {
    console.log(response)
    notyf.error("Failed to remove site")
  }
}

const SitesView = observer(({ orgAndSites }: SitesViewProps) => {
  const { authStore } = useStore()
  const notyf = useContext(NotyfContext)
  return <>
    <Header>{_.capitalize(orgAndSites.organization.name)}</Header>
    <Table>
      <Table.Header>
        <Table.HeaderCell>Site Name</Table.HeaderCell>
        <Table.HeaderCell>Delete</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {orgAndSites.sites && orgAndSites.sites.map((site) => {
          return <Table.Row key={site.node_key}>
            <Table.Cell>
              {_.capitalize(site.name)}
            </Table.Cell>
            <Table.Cell>
              <Button negative onClick={() => deleteNode(site.node_key, authStore.getTokenFunction(), notyf)} >Delete
              </Button>
            </Table.Cell>
          </Table.Row>
        })}
      </Table.Body>

    </Table>
  </>
})

const Admin = observer(() => {
  const { appStore } = useStore();
  appStore.setBreadcrumb('Admin');
  return (
    <Segment inverted style={{ marginTop: '50px' }}>
      <Header>Cagesense Admin</Header>
      <Organizations />
      <Sites />
    </Segment>
  );
});

export default Admin;

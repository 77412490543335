import { runInAction, makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import moment from 'moment';

import { localUserInterface, userInterface, SelectedMeasurement } from '../lib/types';

interface SiteInterface {
  default: boolean;
  name: string;
  node_key: string;
  topside_id: string;
}
//Contains params related to the whole app
export class AppStore {
  private breadcrumb: string = '';
  private user: userInterface = {
    auth0: null,
    local: {
      node_key: '',
      node_kind: '',
      auth0_user_id: '',
      first_time_setup_complete: null,
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  setBreadcrumb(breadcrumb: string) {
    if (this.breadcrumb !== breadcrumb) {
      this.breadcrumb = breadcrumb;
    }
  }

  getBreadcrumb() {
    return this.breadcrumb;
  }

  /**
   * Sets the user object with data based on where we got the information from
   *
   * @param {localUserInterface} local - Info about the user stored in _our_ database
   * @param {object|null} auth0 - Info about the user stored at Auth0
   * @returns {void}
   */
  setUser(local?: localUserInterface | null, auth0?: any | null): void {
    if (local) {
      this.user.local = local;
    }
    if (auth0) {
      this.user.auth0 = auth0;
    }
  }
  /**
   * Retrieves info about the logged in user
   *
   * @returns {userInterface}
   */
  getUser(): userInterface {
    return this.user;
  }
}

export class AuthStore {
  private getAccessTokenSilently: () => Promise<string>;
  private user: Object | undefined;

  constructor() {
    makeAutoObservable(this);
    this.getAccessTokenSilently = () =>
      Promise.resolve('Token token')
  }

  setTokenFunction(getAccessTokenSilently: () => Promise<string>) {
    this.getAccessTokenSilently = getAccessTokenSilently;
  }

  getTokenFunction() {
    return this.getAccessTokenSilently;
  }
}

export class AnalyticsStore {
  // Set default preset in case the user have none
  private viewData: ViewDetailsInterface = {
    node_key: null,
    name: 'Default view',
    graph_settings: {
      live: true,
      grouped_graphs: false,
      data_points: 100,
      date_from: moment(new Date()).startOf('day').subtract(new Date().getTimezoneOffset(), 'minute').unix(),
      date_to: moment(new Date()).endOf('day').subtract(new Date().getTimezoneOffset(), 'minute').unix(),
      graph_size: 'medium',
    },
    sensors: [],
  }

  constructor() {
    makeAutoObservable(this);
  }

  setViewData(preset: ViewDetailsInterface) {
    this.viewData = preset
  }

  getViewData() {
    return this.viewData;
  }

  /**
   * Groups all selected sensors by type of measurement, so i.e all oxygen
   * measurements are placed in their respective grouped list.
   * @param sensors - List of sensors currently selected
   * @returns array - Array of all selected sensors grouped by type of measurement. I.e all selected sensors with depth measurement are placed in their own sub-array
   */
  getSensorsGroupedByMeasurementType = () => {
    let grouped: any = {};
    if (this.viewData?.sensors?.length) {
      this.viewData?.sensors.forEach((e: any) => {
        // There exists some items in the measurement list for this selected measurement, so add this one to the list
        if (grouped[e.measurement_type]?.length) {
          return grouped[e.measurement_type].push(e);
        } else {
          // There are no items in the measurement list for this selected measurement, so create a new array and add the item to that
          return grouped[e.measurement_type] = [e];
        }
      })
    }
    return grouped
  };

  setViewDataName(name: string) {
    runInAction(() => {
      this.viewData.name = JSON.parse(JSON.stringify(name))
    })
  }

  toggleSensor(sensor: any, measurementType: string, checked: boolean | undefined, sensorLocationMeta?: any, locationName?: string) {
    runInAction(() => {
      // Add the sensor to the list, if it doesn't already is there
      const sensorIndex = this.isSensorAndMeasurementSelected(sensor.sensor_id, sensor.sensor_type, measurementType);
      if (checked === true) {
        if (sensorIndex < 0) {
          const newSensorObject = {
            node_key: sensor.node_key,
            sensor_type: sensor.sensor_type,
            sensor_id: sensor.sensor_id,
            measurement_type: measurementType,
            name: sensor.name,
            sensor_location_meta: sensorLocationMeta || [],
            cage_name: locationName || 'N/A'
          };
          this.viewData.sensors.push(newSensorObject);
        }
      } else {
        if (sensorIndex > -1) {
          // Remove 1 element at the sensor index location
          this.viewData.sensors.splice(sensorIndex, 1);
        }
      }
    })
  }

  isSensorAndMeasurementSelected(sensorId: number, sensorType: string, measurementType: string) {
    // Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
    return this.viewData?.sensors?.findIndex((e: any) => e.sensor_id === sensorId && e.sensor_type === sensorType && e.measurement_type === measurementType);
  }

  isSensorSelected(sensorId: number) {
    // Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
    return this.viewData?.sensors?.findIndex((e: any) => e.sensor_id === sensorId);
  }

  setDateFrom(timestamp: number) {
    this.viewData.graph_settings.date_from = timestamp;
  }

  setDateTo(timestamp: number) {
    this.viewData.graph_settings.date_to = timestamp;
  }

  setGraphSize(size: string) {
    this.viewData.graph_settings.graph_size = size;
  }

  setDataPoints(points: number) {
    runInAction(() => {
      this.viewData.graph_settings.data_points = points;
    });
  }

  toggleLiveData() {
    this.viewData.graph_settings.live = !this.viewData.graph_settings.live;
  }

  setSelectedSensors(sensors: Array<SelectedMeasurement>) {
    runInAction(() => {
      this.viewData.sensors = sensors;
    });
  }

  getSelectedSensors() {
    return this.viewData.sensors;
  }

  setLiveData(live: boolean) {
    this.viewData.graph_settings.live = live;
  }

  getLiveData() {
    return this.viewData.graph_settings.live;
  }

  toggleDisplayGroupedGraph() {
    this.viewData.graph_settings.grouped_graphs = !this.viewData.graph_settings.grouped_graphs;
  }

  // Local storage methods
  getDarkMode() {
    let value = localStorage.getItem('cagesense-darkmode')
    if (value !== null) {
      return JSON.parse(value)
    } else {
      return false
    }
  }

  toggleDarkMode() {
    const key = 'cagesense-darkmode'
    let darkMode = localStorage.getItem(key)
    let updatedValue = false

    if (darkMode !== null) {
      darkMode = JSON.parse(darkMode)
      updatedValue = !darkMode
    }
    localStorage.setItem(key, JSON.stringify(updatedValue))
    // More info: https://michalkotowski.pl/how-to-refresh-a-react-component-when-local-storage-has-changed/
    window.dispatchEvent(new Event('storage'))
  }

  getGraphGrid() {
    let value = localStorage.getItem('cagesense-graph-grid')
    if (value !== null) {
      return JSON.parse(value)
    } else {
      return false
    }
  }

  toggleGraphGrid() {
    const key = 'cagesense-graph-grid'
    let grid = localStorage.getItem(key)
    let updatedValue = false

    if (grid !== null) {
      grid = JSON.parse(grid)
      updatedValue = !grid
    }
    localStorage.setItem(key, JSON.stringify(updatedValue))
    // More info: https://michalkotowski.pl/how-to-refresh-a-react-component-when-local-storage-has-changed/
    window.dispatchEvent(new Event('storage'))
  }
}

export class SetupStore {
  private currentStep = 0;
  private setupStarted = false;
  private activeGatewayId: string | null = null;
  private steps = [
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  getSetupStarted() {
    return this.setupStarted;
  }

  setSetupStarted(val: boolean) {
    this.setupStarted = val;
  }

  getCurrentStep(): number {
    return this.currentStep;
  }

  setCurrentStep(index: number) {
    this.currentStep = index;
  }

  getCurrentStepStatus() {
    return this.steps[this.getCurrentStep()].completed;
  }

  setActiveGatewayId(id: string) {
    this.activeGatewayId = id;
  }

  getActiveGatewayId() {
    return this.activeGatewayId;
  }

  navigate(direction: string) {
    switch (direction) {
      case 'next':
        // Ensure the next step isn't going out of boundary and only update progress when we're moving forwards.
        if (this.currentStep in this.steps) {
          this.setStepComplete(this.currentStep);
          this.currentStep++;
        }
        break;
      case 'prev':
        // Decrease and ensure the previous step isn't negative
        if (!(this.currentStep-- in this.steps) || this.currentStep < 0) {
          // Increase the step counter up one
          this.currentStep++;
        }
        break;
      default:
        throw new Error('Invalid direction provided. Must be either "next" or "prev".');
    }
  }
  /**
   * Completes a specific step by setting the provided step index to true
   * @param stepIndex number - The step index which to set as completed
   */
  setStepComplete(stepIndex: number) {
    // Ensure the step actually exists
    if (stepIndex in this.steps) {
      this.steps[stepIndex].completed = true;
    } else {
      throw new Error(`The step ${stepIndex} doesn't exist in the setup steps.`);
    }
  }

  setStepIncomplete(stepIndex: number) {
    // Ensure the step actually exists
    if (stepIndex in this.steps) {
      this.steps[stepIndex].completed = false;
    } else {
      throw new Error(`The step ${stepIndex} doesn't exist in the setup steps.`);
    }
  }
  /**
   * Returns whether all steps have been completed or not
   * @returns boolean
   */
  allStepsCompleted() {
    return this.steps.every((e) => e.completed === true);
  }
  /**
   * Retrieves all steps in the setup wizard
   * @returns Array
   */
  getSteps() {
    return this.steps;
  }
  /**
   * Retrieves a specific step in the setup wizard
   * @param stepIndex number - The step index which to retrieve
   * @returns Object
   */
  getStep(stepIndex: number) {
    // Ensure the step actually exists
    if (stepIndex in this.steps) {
      return this.steps[stepIndex];
    } else {
      throw new Error(`The step ${stepIndex} doesn't exist in the setup steps.`);
    }
  }
}

export class SiteStore {
  private siteKey: string | undefined = undefined;
  private activeSiteKey: string | undefined = undefined;
  private updateUrl: (s: string) => void = () => {
    return;
  };

  private sites: Array<SiteInterface> = [];
  private fetchSiteFromLocation: () => string = () => {
    return 'local';
  };
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Sets the active site key
   * @param siteKey Site identifier (usually in hex)
   */
  setActiveSiteKey(siteKey: string) {
    this.activeSiteKey = siteKey;
  }

  /**
   * Retrieves the active site key
   * @return {string|undefined}
   */
  getActiveSiteKey(): string | undefined {
    return this.activeSiteKey;
  }

  /**
   * Sets the update url
   * @param updateUrl the url to update
   */
  setUpdateUrl(updateUrl: (s: string) => void) {
    this.updateUrl = updateUrl;
  }

  setFetchSiteFromLocation(getSite: () => string) {
    this.fetchSiteFromLocation = getSite;
  }

  /**
   * Sets the current site key
   * @param siteKey
   */
  setSiteKey(siteKey: string) {
    this.siteKey = siteKey;
    if (this.updateUrl !== undefined) {
      this.updateUrl(this.siteKey);
    }
  }

  /**
   * Retrieves the current site key
   * @returns string
   */
  getSiteKey() {
    if (this.siteKey === undefined) {
      return this.fetchSiteFromLocation();
    }
    return this.siteKey;
  }

  /**
   * Sets all the sites the user has on their account
   * @param sites Array of sites
   */
  setSites(sites: Array<SiteInterface>) {
    this.sites = sites;
  }

  /**
   * Retrieves all sites the user has on their account
   * @returns Array
   */
  getSites() {
    return this.sites;
  }
}

interface ViewDetailsInterface {
  node_key: string | null;
  name: string;
  sensors: Array<any>;
  graph_settings: {
    live: boolean;
    grouped_graphs: boolean;
    data_points: number;
    date_from: any;
    date_to: any;
    graph_size: string;
  };
}

const storeContext = createContext({
  appStore: new AppStore(),
  authStore: new AuthStore(),
  firstTimeSetupStore: new SetupStore(),
  siteStore: new SiteStore(),
  analyticsStore: new AnalyticsStore(),
});

const useStore = () => useContext(storeContext);

export default useStore;

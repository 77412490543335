import React, { useState, useEffect, Fragment } from 'react';
import { Range } from 'react-range';
import { Accordion, Grid, Icon, Label, Container, Segment, Message, Header } from 'semantic-ui-react';
import { getSensorDepth } from '../../lib/format';

import { CageAlarmProps, AlarmMeasurementProps, CageContents } from '../../lib/types';

interface CageOverviewAlarmsProps {
  cage: CageContents;
  className?: string;
}

const CageOverviewAlarms = ({ cage, className }: CageOverviewAlarmsProps) => {
  const [alarms, setAlarms] = useState<CageAlarmProps[]>([]);
  useEffect(() => {
    const alarms = cage.sensor_locations
      .map((sensorLocation) => {
        return sensorLocation.scenario_status.map((scenarioStatus) => {
          let alarm = sensorLocation.scenarios.find((alarm) => alarm.node_key === scenarioStatus.node_key);
          let warningTrigger = Math.round(alarm ? alarm.trigger.warning_trigger_distance : 75);
          let criticalTrigger = 100;
          let values = [
            warningTrigger,
            criticalTrigger,
            Math.round(scenarioStatus.min_trigger_distance),
            Math.round(scenarioStatus.max_trigger_distance),
            Math.round(scenarioStatus.prev_trigger_distance),
            Math.round(scenarioStatus.trigger_distance),
          ];
          //let values = [warningTrigger,  criticalTrigger,25,50,100,0];
          return {
            label: scenarioStatus.name,
            position: getSensorDepth(sensorLocation.meta),
            values: values,
            level: scenarioStatus.severity.level,
            measurements: scenarioStatus.measurements,
            depth: sensorLocation.meta.max_depth - 5,
            locationId: sensorLocation.node_key,
            warning: warningTrigger,
            critical: criticalTrigger,
            description: scenarioStatus.description,
            alarm: alarm,
          };
        });
      })
      .flat()
      .sort((a, b) => {
        if (a.depth === b.depth) {
          if (a.label === b.label) {
            return a.values[5] > b.values[5] ? -1 : 1;
          }
          return a.label > b.label ? 1 : -1;
        }
        if (a.depth === -1) {
          return 1;
        }
        return a.depth > b.depth ? 1 : -1;
      });

    setAlarms(alarms);
  }, [cage]);

  const onScroll = () => {
    setShadows();
  };
  const setShadows = () => {
    const alarmContainer = document.getElementById('alarmcontainer');
    if (alarmContainer) {
      const offset = alarmContainer.scrollHeight - alarmContainer.offsetHeight - alarmContainer.scrollTop;
      const opasity = offset > 100 ? 1 : offset <= 0 ? 0 : offset / 100;
      alarmContainer.style.boxShadow = `inset -10px -50px 50px -35px rgba(0, 0, 0, ${opasity})`;
    }
  };

  return (
    <Fragment>
      {/*<Header inverted>Alarms</Header>*/}
      <div
        id="alarmcontainer"
        className={className ? className : 'alarm-container'}
        style={{ minHeight: '80px' }}
        onScroll={onScroll}
      >
        {alarms.length ? (
          <Accordion>
            {alarms.map((alarm, index) => {
              return (
                <CageAlarm
                  setShadows={setShadows}
                  key={index}
                  warning={alarm.warning}
                  critical={alarm.critical}
                  level={alarm.level}
                  label={alarm.label}
                  position={alarm.position}
                  values={alarm.values}
                  measurements={alarm.measurements}
                  depth={alarm.depth}
                  description={alarm.description}
                  alarm={alarm.alarm}
                />
              );
            })}
          </Accordion>
        ) : (
          <Message color="blue" style={{ top: '20px', margin: '0 20px' }}>
            <Icon name="info" />
            This cage does not have any alarms
          </Message>
        )}
      </div>
    </Fragment>
  );
};

const CageAlarm = (alarm: CageAlarmProps) => {
  useEffect(() => {
    alarm.setShadows && alarm.setShadows();
  }, [alarm]);

  const min = -1; //TODO: This is a band-aid fix for now, look into better ways to handle impossible values
  const max = 105;
  var marginLeft: string = 0.95 * alarm.values[2] + '%';
  var marginRight: string = 100 - 0.95 * alarm.values[3] + '%';

  return (
    <Accordion className={alarm.level === 2 ? 'red' : alarm.level === 1 ? 'yellow' : 'none'}>
      <Grid style={{ marginLeft: 0 }}>
        <Grid.Row verticalAlign="middle" style={{ height: '70px', margin: 'auto' }}>
          <Grid.Column width={4} style={{ whiteSpace: 'nowrap' }}>
            <Header as={'h4'} inverted>
              {alarm.label}
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Label style={{ background: 'none', color: 'white' }}>{alarm.position}</Label>
          </Grid.Column>
          <Grid.Column width={2} style={{ padding: '0px' }}>
            <AlarmMeasurementDisplay measurements={alarm.measurements}></AlarmMeasurementDisplay>
          </Grid.Column>
          <Grid.Column width={8}>
            <Range
              values={alarm.values}
              onChange={() => {}}
              min={min}
              max={max}
              step={1}
              allowOverlap={true}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    width: '96%',
                    height: '15px',
                    // Green / Yellow / Red
                    background: `linear-gradient(90deg, rgba(75, 239, 143, 0.8) 4%, rgba(233, 238, 12, 0.8)${alarm.warning}%, rgba(255, 0, 0, 0.8) ${alarm.critical}%)`,
                  }}
                >
                  {children}

                  <div
                    {...props}
                    style={{
                      ...props.style,
                      display: 'flex',
                      marginLeft: marginLeft,
                      marginRight: marginRight,
                      background: 'none',
                      height: '15px',
                    }}
                  >
                    <div
                      style={{
                        background: '#222',
                        height: '4px',
                        width: '100%',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                  </div>
                </div>
              )}
              renderThumb={({ props, value, index }) => {
                props.style.cursor = 'auto';
                var show: boolean = index === 5;
                return (
                  <div {...props} className="ui input slider thumb">
                    <TriggerDistanceIcon prevTriggerDistance={alarm.values[4]} triggerDistance={value} show={show} />
                  </div>
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Accordion>
  );
};

interface TriggerDistanceIconProps {
  prevTriggerDistance: number;
  triggerDistance: number;
  show: boolean;
}

const TriggerDistanceIcon = (props: TriggerDistanceIconProps) => {
  if (props.show) {
    return <MainThumbIcon triggerDistance={props.triggerDistance} prevTriggerDistance={props.prevTriggerDistance} />;
  } else {
    return <Fragment />;
  }
};

interface TriggerDistanceProps {
  prevTriggerDistance: number;
  triggerDistance: number;
}

const MainThumbIcon = (props: TriggerDistanceProps) => {
  var diff: number = props.triggerDistance - props.prevTriggerDistance;
  if (diff > 10) {
    return (
      <Container className="show">
        <Icon size="big" style={{ position: 'absolute', textAnchor: 'center', top: '-13px' }} name="circle" inverted />
        <Icon
          size="large"
          style={{ position: 'absolute', textAnchor: 'center', top: '-10px', left: '5px' }}
          name="angle double right"
        />
      </Container>
    );
  } else if (diff > 2) {
    return (
      <Container className="show">
        <Icon size="big" style={{ position: 'absolute', top: '-12px' }} name="circle" color="grey" inverted />
        <Icon
          size="large"
          style={{ position: 'absolute', textAnchor: 'center', top: '-9px', left: '5px' }}
          color="black"
          name="angle right"
        />
      </Container>
    );
  } else if (diff < -10) {
    return (
      <Container className="show">
        <Icon size="big" style={{ position: 'absolute', top: '-12px' }} name="circle" color="grey" inverted />
        <Icon
          size="large"
          style={{ position: 'absolute', textAnchor: 'center', top: '-9px', left: '3px' }}
          color="black"
          name="angle double left"
        />
      </Container>
    );
  } else if (diff < -2) {
    return (
      <Container className="show">
        <Icon size="big" style={{ position: 'absolute', top: '-12px' }} name="circle" color="grey" inverted />
        <Icon
          size="large"
          style={{ position: 'absolute', textAnchor: 'center', top: '-9px', left: '3px' }}
          color="black"
          name="angle left"
        />
      </Container>
    );
  }
  // Default fallback if no of the rules above match
  return (
    <Container className="show">
      <Icon
        size="big"
        style={{ position: 'absolute', textAnchor: 'center', top: '-13px' }}
        className="thumb circle"
        color="grey"
        name="circle"
        inverted
      />
      <Icon
        size="tiny"
        style={{ position: 'absolute', textAnchor: 'center', top: '-3px', left: '12px' }}
        color="black"
        name="circle"
      />
    </Container>
  );
};

const getUnit = (measurementType: string) => {
  switch (measurementType) {
    default:
      return measurementType;
    case 'oxygen':
      return '%';
    case 'temperature':
      return 'C\u00b0';
    case 'depth':
      return 'm';
    case 'pitch':
      return '\u00b0';
  }
};

const AlarmMeasurementDisplay = (props: AlarmMeasurementProps) => {
  console.log(props);
  return (
    <Segment.Group horizontal inverted="true" style={{ display: 'contents' }}>
      {props.measurements &&
        props.measurements.length &&
        props.measurements?.map((pair, index) => {
          return (
            <Segment key={index} inverted compact style={{ paddingRight: '0px', paddingLeft: '0px' }}>
              {pair.measurement.toFixed(0)}
              {getUnit(pair.type)}
            </Segment>
          );
        })}
    </Segment.Group>
  );
};

export default CageOverviewAlarms;

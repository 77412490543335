import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  Label,
  Legend,

  CartesianGrid,
} from 'recharts';

import { getSensorName, getUnit } from '../lib/format';
import {
  DEPTH_MEASUREMENT_TYPE,
  OXYGEN_MEASUREMENT_TYPE,
  TEMPERATURE_MEASUREMENT_TYPE,
  TILT_MEASUREMENT_TYPE,
  Timeline,
  TimelineData,
  TimelineGraphProps,
} from '../lib/types';

interface YaxisProps {
  measurement: string;
  domain: any[];
  showLabels: boolean;
  label: string;
  dx: number;
  inverted: boolean;
  ticks: number[];
}

const formatTickYAxis = (seconds: any) => {
  return new Date(seconds * 1000).toLocaleString('nb-NO');
};

const getYaxisDomainFromTimeline = (timeline: Timeline[], measurement: string) => {
  var relevantTimelines: Timeline[] = timeline.filter((tl) => tl.measurement_type === measurement);
  return findYaxisDomainFromTimeline(relevantTimelines, measurement);
};

const padDomain = (min: number, max: number, measurement: string) => {
  let range: number = max - min;
  let padding = range * 0.1;
  switch (measurement) {
    case OXYGEN_MEASUREMENT_TYPE:
      return [min - padding, max + padding];
    case TEMPERATURE_MEASUREMENT_TYPE:
      return [min - padding, max + padding];
    case TILT_MEASUREMENT_TYPE:
      return [min - padding, max + padding];
    case DEPTH_MEASUREMENT_TYPE:
      return [min - padding > 0 ? min - padding : -1, max + padding];
  }
  return [min, max];
};

const findYaxisDomainFromTimeline = (timeline: Timeline[], mT: string) => {
  if (!timeline || timeline.length === 0) {
    return ['dataMin', 'dataMax'];
  }
  var min: number = Infinity;
  var max: number = -Infinity;
  timeline.forEach((tl) => {
    const values = tl.data.map((value: TimelineData) => value.value);
    var tlMin: number = Math.min(...values);
    var tlMax: number = Math.max(...values);
    if (tlMin < min) {
      min = tlMin;
    }
    if (tlMax > max) {
      max = tlMax;
    }
  });

  return padDomain(min, max, mT);
};

const calculateTicks = (min: number, max: number, interval: number, measurement: string) => {
  const padding = padDomain(min, max, measurement);
  const padMin = padding[0];
  const padMax = padding[1];
  const ticks = [];
  const step = (padMax - padMin) / (interval - 1);
  if (step === 0) {
    ticks.push(padMin)
    ticks.push(padMax)
    return ticks
  }
  for (let i = padMin; i <= padMax; i = i + step) {
    ticks.push(i);
  }

  return ticks;
};

const makeYaxies = (timeline: Timeline[], inverted: boolean) => {
  var groupedTimelines = new Map<string, Timeline[]>();

  timeline
    .filter((tl) => tl.data.length > 0)
    .forEach((tl) => {
      if (!groupedTimelines.has(tl.measurement_type)) {
        groupedTimelines.set(tl.measurement_type, [tl]);
      } else {
        groupedTimelines.get(tl.measurement_type)?.push(tl);
      }
    });
  var yaxisProps: YaxisProps[] = [];
  let dx: number = groupedTimelines.size > 1 ? 0 : -20;
  groupedTimelines.forEach((timelines: Timeline[], key: string) => {
    const values = timelines.flatMap((t) => t.data).map((d) => d.value);
    const max = Math.max(...values);
    const min = Math.min(...values);
    const ticks = calculateTicks(min, max, 5, key);
    yaxisProps.push({
      measurement: key,
      domain: getYaxisDomainFromTimeline(timeline, key),
      showLabels: true,
      label: _.capitalize(key === 'pitch' ? 'tilt' : key),
      dx: dx,
      inverted: inverted,
      ticks: ticks,
    });
  });
  return (
    <>
      {yaxisProps
        .filter((props) => props.measurement)
        .map((props: YaxisProps) => {
          return (
            <YAxis
              key={props.measurement}
              interval="preserveStartEnd"
              yAxisId={props.measurement}
              allowDecimals={false}
              ticks={props.ticks}
              tickFormatter={(tick) => tick.toFixed(1)}
              reversed={props.measurement === 'depth'}
              domain={props.domain ? props.domain : undefined}
              label={
                <Label
                  className="measurement"
                  value={props.label}
                  angle={-90}
                  //position="outside"
                  fill={inverted ? 'white' : 'black'}
                  dx={props.dx}
                  // dy={0}
                  style={{ fontSize: '14px' }}
                ></Label>
              }
            ></YAxis>
          );
        })}
    </>
  );
};

const TimelineGraph = observer(({ timeline, hideXaxis, dx, inverted }: TimelineGraphProps) => {
  // const [refAreaLeft, setRefAreaLeft] = useState<any>();
  // const [refAreaRight, setRefAreaRight] = useState<any>();
  if (timeline.length === 0) {
    return <></>;
  }
  return (
    <>
      {/* Display a zoom out button if the graph has been zoomed in */}

      {
        <ResponsiveContainer
          minWidth={'100px'}
          minHeight={'100px'}
          debounce={300}
        >
          <LineChart
            data={timeline}
            syncMethod="value"
          // onMouseDown={(e: any) => {
          //   if (e) {
          //     setRefAreaLeft(e.activeLabel);
          //   }
          // }}
          // onMouseMove={(e: any) => {
          //   if (e && refAreaLeft) {
          //     setRefAreaRight(e.activeLabel);
          //   }
          // }}
          // onMouseUp={(e: any) => {
          //   if (!refAreaLeft || !refAreaRight || refAreaLeft === refAreaRight) {
          //     setRefAreaLeft(undefined);
          //     setRefAreaRight(undefined);
          //     return;
          //   } else if (refAreaLeft > refAreaRight) {
          //     setXDomain([refAreaRight, refAreaLeft]);
          //   } else {
          //     setXDomain([refAreaLeft, refAreaRight]);
          //   }
          //   setRefAreaLeft(undefined);
          //   setRefAreaRight(undefined);
          // }}
          >
            <CartesianGrid stroke="gray" />
            {timeline.map((tl, index) => {
              let name =
                (tl.sensorName ? tl.sensorName : getSensorName(tl.sensor_type, tl.sensor_id, tl.sensorName)) +
                ' - ' +
                (tl.measurement_type === 'pitch' ? 'tilt' : tl.measurement_type);
              return (
                <>
                  <Line
                    key={name + tl.measurement_type}
                    yAxisId={tl.measurement_type}
                    name={name}
                    xAxisId="time"
                    type='step'
                    data={tl.data}
                    dataKey={'value'}
                    dot={false}
                    activeDot={{
                      stroke: '#fff',
                      strokeWidth: 3, r: 10
                    }}
                    unit={getUnit(tl.measurement_type)}
                    stroke={tl.color}
                    isAnimationActive={false}
                  />
                  {timeline.length === 1 ? (
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="radial"
                      content={() => (
                        <div
                          style={inverted ? { color: 'white', fontSize: '20px' } : { color: 'black', fontSize: '20px' }}
                        >
                          {tl.data.length > 0 ? tl.data[tl.data.length - 1].value.toFixed(2) : ''}
                        </div>
                      )}
                    />
                  ) : null}
                </>
              );
            })}
            {timeline ? makeYaxies(timeline, inverted) : null}
            {timeline && timeline.flatMap((tl) => tl.data).length > 0 ? (
              <>
                <XAxis
                  xAxisId={'time'}
                  allowDataOverflow
                  tick={!hideXaxis}
                  dataKey="ts"
                  angle={45}
                  height={hideXaxis ? 5 : 120}
                  type="number"
                  domain={['datamin', 'datamax' + 10]}
                  dy={50}
                  dx={dx ? dx : 20}
                  interval="preserveStartEnd"
                  tickFormatter={formatTickYAxis}
                  allowDuplicatedCategory={false}
                />
                <Tooltip
                  labelFormatter={formatTickYAxis}
                  formatter={(value: any, name: any, props: any) => {
                    return [value.toFixed(2), name];
                  }}
                  contentStyle={{ backgroundColor: '#2F4858' }}
                />
              </>
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      }
    </>
  );
});

export default TimelineGraph;

import _ from 'lodash';
import {
  SensorLocationMeta,
  TEMPERATURE_MEASUREMENT_TYPE,
  TILT_MEASUREMENT_TYPE,
  DEPTH_MEASUREMENT_TYPE,
  TURBIDITY_MEASUREMENT_TYPE,
  SALINITY_MEASUREMENT_TYPE,
  OXYGEN_MEASUREMENT_TYPE
} from './types';

/**
 * Formats the name of the sensor as a computed property
 * @param sensorItem - Object containing node_key, node_kind, sensor_type and sensor_id amongst other things
 * @returns string - Formatted sensor name
 */
const formatSensorName = (sensorItem: any) => {
  if (sensorItem) {
    // If the sensor has a name, return that, otherwise use "SensorType sensor", i.e "Oxygen sensor". Suffix with ID.
    return sensorItem?.name?.length ? sensorItem.name : `${sensorItem.sensor_type} sensor (ID: ${sensorItem.sensor_id})`
  } else {
    return 'N/A'
  }
}

/**
 * Returns the name of a color as string for the type of measurement requested
 * @param measurement string - The measurement type
 * @returns string - Color representing this measurement type
 */
const measurementColor = (measurement: string, darkMode: boolean = false) => {
  let color = 'black'
  if (darkMode === true) {
    color = 'white'
  }
  switch (measurement) {
    case OXYGEN_MEASUREMENT_TYPE:
      color = 'green'
      if (darkMode === true) {
        color = '#4bef8f'
      }
      break;
    case TURBIDITY_MEASUREMENT_TYPE:
      color = '#ffc107'
      break;
    case SALINITY_MEASUREMENT_TYPE:
      color = 'grey'
      if (darkMode === true) {
        color = 'white'
      }
      break;
    case TEMPERATURE_MEASUREMENT_TYPE:
      color = 'blue'
      if (darkMode === true) {
        // Lighter blue
        color = '#78e0ff'
      }
      break;
    default:
      break;
  }
  return color
}

function formatDecimals(val: number, digits = 2) {
  return val.toLocaleString(navigator.language, { minimumFractionDigits: digits, maximumFractionDigits: digits });
}

function RoundDecimalPlaces(val: number, digits = 2) {
  let pow = Math.pow(10, digits);
  return Math.round(val * pow) / pow;
}

const getSensorNameFromLastMessage = (last_message: any, sensor: any | undefined, maxLength?: number) => {
  if (last_message) {
    let name = _.capitalize(last_message.sensor_type);
    if (sensor) {
      name = name + ' - ' + sensor.name;
    } else {
      name = name + '-' + padSensorId(last_message.sensor_id);
    }
    return name;
  }
  return '';
};
const getSensorName = (sensor_type: string, sensor_id: number, sensor_name?: string, maxLenght?: number) => {
  const name: string =
    sensor_name && sensor_name !== '' ? sensor_name : _.capitalize(sensor_type) + ' - ' + padSensorId(sensor_id);
  return maxLenght && name.length > maxLenght ? name.substring(0, maxLenght) + '...' : name;
};

const padSensorId = (sensorId: number) => {
  return String(sensorId).padStart(3, '0');
};

const getSensorSegmentType = ({ type }: SensorLocationMeta) => {
  if (type === 'depth') {
    return '';
  }
  let formattedType = type.charAt(0).toUpperCase() + type.slice(1);
  formattedType = formattedType.replaceAll('_', ' ');
  return formattedType;
};

const getSensorDepth = ({ max_depth, min_depth, type }: SensorLocationMeta) => {
  return min_depth === max_depth ? max_depth + 'm' : min_depth + ' - ' + max_depth + 'm';
};

const getSensorSegment = ({ left_border, right_border }: any) => {
  if (left_border === 0 && right_border === 360) {
    //full circle
    return {
      segmentNr: -1,
      label: '',
    };
  } else if (left_border === 0) {
    //North facing segment
    return {
      segmentNr: 0,
      label: 'North',
    };
  } else if (left_border === 90 && right_border <= 270) {
    return {
      segmentNr: 1,
      label: 'East',
    };
  } else if (left_border === 180) {
    return {
      segmentNr: 2,
      label: 'South',
    };
  } else if (left_border === 270) {
    return {
      segmentNr: 2,
      label: 'West',
    };
  }
  return {
    segmentNr: -1,
    label: '',
  };
};

const getUnit = (measurementType: string) => {
  switch (measurementType) {
    default:
      return '';
    case OXYGEN_MEASUREMENT_TYPE:
      return '%';
    case TEMPERATURE_MEASUREMENT_TYPE:
      return '\u00b0C';
    case DEPTH_MEASUREMENT_TYPE:
      return 'm';
    case TILT_MEASUREMENT_TYPE:
      return '\u00b0';
    case TURBIDITY_MEASUREMENT_TYPE:
      return 'NTU';
    case SALINITY_MEASUREMENT_TYPE:
      return 'PSU'
  }
};

const getStrokeForGraphFromType = (measurementType: string) => {
  switch (measurementType) {
    default:
      return '1 0';
    case OXYGEN_MEASUREMENT_TYPE:
      return '2 2';
    case TEMPERATURE_MEASUREMENT_TYPE:
      return '7 3';
    case DEPTH_MEASUREMENT_TYPE:
      return '4 4';
    case TILT_MEASUREMENT_TYPE:
      return '5 5';
    case TURBIDITY_MEASUREMENT_TYPE:
      return '6 6';
  }
};

const NoValue = -1e6;

export {
  formatSensorName,
  getSensorSegmentType,
  measurementColor,
  formatDecimals,
  RoundDecimalPlaces,
  getSensorName,
  getSensorNameFromLastMessage,
  getSensorDepth,
  getSensorSegment,
  padSensorId,
  NoValue,
  getUnit,
  getStrokeForGraphFromType,
};

import { OXYGEN_SENSOR_TYPE, SALINITY_SENSOR_TYPE, TILT_SENSOR_TYPE, TURBIDITY_SENSOR_TYPE } from './types';

const hexColors = {
  green: '#2B9C6A',
  teal: '#00B5AD',
  yellow: '#FFC83B',
  red: '#D4484B',
  pink: '#ff40c9',
  orange: '#ea8843',
  sunglow: '#ffc83b',
  xanadu: '#6b7d5f',
  gold: '#8e7f49',
  cgBlue: '#337793',
  maximumBlue: '#48b8d0',
  middleBlue: '#59bed4',
  darkBlue: '#0E1A2B',
  menuBlue: '#162840',
  glue: '#1d3556',
  grey: '#ccc',
};

const getColor = (sensorType: string, hex?: boolean) => {
  let color: string = '#000000';
  switch (sensorType) {
    case OXYGEN_SENSOR_TYPE:
      color = '#2B9C6A';

      break;
    case SALINITY_SENSOR_TYPE:
      color = '#FFFFFF';

      break;
    case TILT_SENSOR_TYPE:
      color = '#666';

      break;
    case TURBIDITY_SENSOR_TYPE:
      color = '#FFE21F';

      break;
  }
  return color;
};

const getColorForSeverity = (level: number) => {
  switch (level) {
    default:
      return hexColors.grey;
    case 0:
      return '#2b9c6a';
    case 1:
      return hexColors.yellow;
    case 2:
      return hexColors.red;
  }
};

const graphSecondaryColors: string[] = ['#FF976f', '#FF8CD7', '#FFD700', '#00FF00', '#00FFFF'];
const getGraphColor = function (index: number): string {
  if (index < graphSecondaryColors.length) {
    return graphSecondaryColors[index];
  }
  return getGraphColor(index - graphSecondaryColors.length);
};

export default hexColors;
export { getColor, getGraphColor, getColorForSeverity };

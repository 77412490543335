import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Message, Icon, Loader, Table, Segment, Button, Container, Dropdown } from 'semantic-ui-react';
import useSWR from 'swr';

import TimelineGraph from '../TimelineGraph';
import { AuthFetcher } from '../../lib/fetch';
import { getSensorDepth, getSensorName, getSensorSegment } from '../../lib/format';
import { CageContents, CageGraphProps, SensorContents, SensorLocationContents, Timeline } from '../../lib/types';
import SensorIcon from '../UIElements/SensorIcon';
import { getColor } from '../../lib/colors';
import useStore from '../../Store/Store';
import { observer } from 'mobx-react';

interface CageMeasurementHistory {
  cage: CageContents;
  sensor_location_history: SensorLocationHistory[];
}
interface SensorLocationHistory {
  sensor_location: SensorLocationContents;
  measurement_history: Timeline[];
}

interface SensorHistory {
  sensor_id: number;
  sensor_type: string;
  timelines: Timeline[];
}


const MakeSlMeasurementGraph = (
  sensor_key: string,
  sensorName: string,
  slDepth: string,
  sensorSegment: string,
  sh: SensorHistory,
  sampleSize: number,
  hours: number,
  setMenuRightVisible: any,
  history: any
) => {
  let sensorDisplayName = getSensorName(sh.sensor_type, sh.sensor_id, sensorName);

  return (
    <Fragment key={sh.sensor_type + '-' + sh.sensor_id}>
      <Table inverted color="blue" style={{ paddingBottom: '20px' }}>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ paddingTop: '15px' }}>
              <SensorIcon sensorType={sh.sensor_type} />
              <div style={{ marginLeft: '35px' }}>
                {sensorDisplayName} | {slDepth}
                {' | '}
                {sensorSegment}
              </div>
            </Table.Cell>
            <Table.Cell style={{ paddingTop: '15px' }} textAlign="right">
              <Button
                icon
                inverted
                basic
                size="mini"
                style={{ marginLeft: '20px' }}
                onClick={() => {
                  setMenuRightVisible(false);
                  history.push('/analytics/' + sensor_key);
                }}
              >
                <Icon name="line graph" />
                Analytics
              </Button>
            </Table.Cell>
          </Table.Row>
          {sh.timelines.map((tl: Timeline) => (
            <Measurements key={tl.sensor_type + "-" + tl.sensor_id + "-" + tl.measurement_type} measurements={[tl]} samples={sampleSize} hours={hours} />
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};

const GroupSensorsFromMeasurementHistory = (measurementHistory: Timeline[]) => {
  let groupedSensors: SensorHistory[] = [];
  measurementHistory.forEach((tl: Timeline) => {
    let sensor: SensorHistory | undefined = groupedSensors.find(
      (sh: SensorHistory) => sh.sensor_id === tl.sensor_id && sh.sensor_type === tl.sensor_type
    );
    if (sensor) {
      sensor.timelines.push(tl);
    } else {
      groupedSensors.push({
        sensor_id: tl.sensor_id,
        sensor_type: tl.sensor_type,
        timelines: [tl],
      });
    }
  });
  return groupedSensors;
};

const MakeSlCageGraphGrid = (slH: SensorLocationHistory, setMenuRightVisible: any, history: any) => {
  let slDepth: string = getSensorDepth(slH.sensor_location.meta);
  let sensorSegment: string = getSensorSegment(slH.sensor_location.meta).label;

  slH.measurement_history.sort((a: Timeline, b: Timeline) => {
    if (a.sensor_id === b.sensor_id) {
      if (a.sensor_type === b.sensor_type) {
        return a.measurement_type > b.measurement_type ? 1 : -1;
      } else {
        return a.sensor_type > b.sensor_type ? 1 : -1;
      }
    } else {
      return a.sensor_id > b.sensor_id ? 1 : -1;
    }
  });
  //add color
  slH.measurement_history.forEach((tl: Timeline) => {
    tl.color = getColor(tl.sensor_type);
  });
  let groupedSensors: SensorHistory[] = GroupSensorsFromMeasurementHistory(slH.measurement_history);
  const slMeasurementGraphs = []

  slMeasurementGraphs.push(...groupedSensors.map((sh: SensorHistory) => {
    let sensor: SensorContents | undefined = slH.sensor_location.sensors.find(
      (sensor) => sensor.sensor_id === sh.sensor_id && sensor.sensor_type === sh.sensor_type
    );
    let sensorKey: string = sensor && sensor !== null ? sensor.node_key : sh.sensor_type + '-' + sh.sensor_id;

    let sensorName: string = sensor && sensor !== null ? sensor.name : '';

    return MakeSlMeasurementGraph(
      sensorKey,
      sensorName,
      slDepth,
      sensorSegment,
      sh,
      100,
      24,
      setMenuRightVisible,
      history
    );
  }))

  return (
    <Fragment key={slH.sensor_location.node_key}>
      {slMeasurementGraphs}
    </Fragment>
  );
};

const orderSensorLocationHistory = (a: SensorLocationHistory, b: SensorLocationHistory) => {
  if (a.sensor_location.meta.max_depth === b.sensor_location.meta.max_depth) {
    return a.sensor_location.meta.left_border > b.sensor_location.meta.left_border ? 1 : -1;
  } else {
    return a.sensor_location.meta.max_depth > b.sensor_location.meta.max_depth ? 1 : -1;
  }
}

const CageGraphGrid = observer(({ cageId, sampleSize, setMenuRightVisible }: CageGraphProps) => {
  const { siteStore, authStore } = useStore();
  const siteKey: string | undefined = siteStore.getSiteKey();
  const history = useHistory();
  const [hours, setHours] = useState(24);
  const { data, error } = useSWR(
    siteKey && cageId ? '/api/v1/site/' + siteKey + '/cage/' + cageId + '/measurement/hour/' + hours + '' : null,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {
      refreshInterval: 5000,
    }
  );
  if (!data) {
    return <Loader active inverted />;
  }
  if (error) {
    return <Fragment>An error has occured.Please reload the page</Fragment>;
  }
  const cmh: CageMeasurementHistory = data;

  if (cmh === undefined) {
    return <><Loader active inverted></Loader></>
  }


  const slHistory = []
  if (cmh.sensor_location_history) {
    cmh.sensor_location_history.sort(orderSensorLocationHistory)

    let mapped = cmh.sensor_location_history.map((slHist: SensorLocationHistory) => MakeSlCageGraphGrid(slHist, setMenuRightVisible, history))
    slHistory.push(...mapped)
  }

  if (cmh.cage) {

    return (
      <>
        <Container textAlign="right" style={{ background: '#0E1A2B', padding: '15px', borderRadius: '5px' }}>
          <Dropdown
            icon="clock"
            // inverted
            floating
            labeled
            // style={{ margin: 0 }}
            button
            className="icon inverted mini basic"
            options={[
              { key: 1, text: 'Last hour', value: 1 },
              { key: 2, text: 'Last 24 hours', value: 24 },
              { key: 3, text: 'Last 7 days', value: 24 * 7 },
            ]}
            value={hours}
            onChange={(e, { value }) => {
              if (typeof value === 'number') setHours(value);
            }}
          ></Dropdown>
        </Container>
        {slHistory}
      </>

    )
  }

  return (
    <>
      <Message color="blue" style={{ top: '20px', margin: '0 20px' }}>
        <Icon name="info" />
        This cage does not have any measurements
      </Message>
    </>
  );
});
interface MeasurementsProps {
  measurements: Timeline[];
  samples: number;
  hours: number;
}
const Measurements = ({ measurements, samples, hours }: MeasurementsProps) => {

  return (
    <Table.Row className="overview_graph">
      <Table.Cell colSpan={2}>
        {measurements.map((measurement: Timeline, index: number) => (
          <MeasurementGraph
            key={measurement.sensor_type + "-" + measurement.measurement_type}
            timeline={measurement}
            samples={samples}
          />
        ))}
      </Table.Cell>
    </Table.Row>
  );
};

interface CageGraphElementProps {
  timeline: Timeline;
  highlight?: boolean;
  samples: number;
  redLine?: number;
  yellowLine?: number;
  referenceLinePercentage?: number;
}

const MeasurementGraph = ({ timeline, referenceLinePercentage }: CageGraphElementProps) => {
  return (
    <Segment
      key={timeline.sensor_type + "-" + timeline.sensor_id}
      textAlign="center"
      style={{
        backgroundColor: 'rgba(0,0,0,0)',
        height: '100px',
        paddingLeft: 0,
      }}
    >
      {timeline ? (
        <TimelineGraph
          inverted={true}
          sensorId={timeline.sensor_id.toString()}
          timeline={[timeline]}
          referenceLinePercentage={referenceLinePercentage}
          hideXaxis={true}
          dx={-20}
        />
      ) : (
        <Loader active inverted />
      )}
    </Segment>
  );
};

export default CageGraphGrid;

import { useEffect, useState } from "react";
import useStore from "../../Store/Store";
import useSWRInfinite from 'swr/infinite';
import { observer } from "mobx-react-lite";
import {
  LineChart,
  ResponsiveContainer,
  Line,
  Brush,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import AppSettings from "../../Core/AppSettings";
import { Icon, Image, Message, Popup, Segment } from "semantic-ui-react";
import { AuthFetcher } from "../../lib/fetch";
import { getUnit, measurementColor } from "../../lib/format";

const formatYAxis = ((value: any) => {
  // Format whatever value (numeric) on the Y axis to have at max 2 decimals
  return Math.round(value).toString()
})

const formatToDate = (input: any) => {
  let value
  // The brush formatter sends in an object instead of the timestamp
  if (typeof input === 'object') {
    if ('data' in input && input?.data?.length > 0) {
      value = input?.data[0].ts
    } else {
      value = input.ts
    }
  } else {
    value = input
  }
  if (value) {
    return new Date(value * 1000).toLocaleString('nb-NO')
  }
  return ''
};

// This graph groups all sensor readings by type and displays them in _one_ graph.
const GroupedByTypeGraph = observer(({ sensors, measurementType }: { sensors: any, measurementType: string }) => {
  const { analyticsStore, authStore } = useStore()
  const [darkMode, setDarkMode] = useState(analyticsStore.getDarkMode())
  const [graphGrid, setGraphGrid] = useState(analyticsStore.getGraphGrid())
  const viewData = analyticsStore.getViewData()

  const startDate = viewData.graph_settings.date_from
  const endDate = viewData.graph_settings.date_to
  const numberOfDataPoints = viewData.graph_settings.data_points
  /**
  * First, create a list of URLs which we'll use to fetch each sensor's readings
  * The request key (index) is what SWR uses to know what data (index in the array) to retrieve.
  * The initial value of the request key is 0, so we have to update the number of sensors selected before each request.
  */
  /**
   *  View data - Each Sensor Structure
   *  [
   *    {
   *      "name": "Some name for the sensor",
   *      "measurement_type": "oxygen",
   *      "sensor_id": 12,
   *      "sensor_type": "tilt",
   *      "sensor_location_meta": [...],
   *      "cage_name": "Some name"
   *    },
   *    ...
   *  ]
   */

  const { data, setSize } = useSWRInfinite(index => {
    const url =
      startDate && endDate && sensors[index]
        ? `/api/v1/sensor/${sensors[index].sensor_type}/${sensors[index].sensor_id}/history/${sensors[index].measurement_type}/from/${startDate}/to/${endDate}?samples=` + numberOfDataPoints
        : '';

    return {
      url: url,
      // A refresh interval of 0 means NO refresh, otherwise we update as per app setting
      refreshInterval: viewData.graph_settings.live === true ? AppSettings.graphUpdateInterval : 0
    };
  },
    params => {
      // This sets up the request prior to each sensor, setting the Bearer token for authentication
      return AuthFetcher(params.url, authStore.getTokenFunction())
    },
    {
      revalidateAll: false,
      // A refresh interval of 0 means NO refresh, otherwise we update as per app setting
      refreshInterval: viewData.graph_settings.live === true ? AppSettings.graphUpdateInterval : 0
    })

  useEffect(() => {
    window.addEventListener('storage', () => {
      setDarkMode(analyticsStore.getDarkMode())
      setGraphGrid(analyticsStore.getGraphGrid())
    })
    setSize(sensors.length)
    analyticsStore.setDateFrom(viewData.graph_settings.date_from)
    analyticsStore.setDateTo(viewData.graph_settings.date_to)
  },
    [
      setSize,
      sensors,
      analyticsStore,
      viewData.graph_settings.date_from,
      viewData.graph_settings.date_to,
      sensors?.length
    ]
  )

  let timelineData: any = []

  data?.map((e: any) => {
    return timelineData.push(e)
  })
  /**
   * Filter through each timeline data and see if there are entries with data.
   * Entries with no data are removed.
   */
  const sensorsWithoutData = timelineData?.filter((e: any) => e?.data?.length === 0)
  timelineData = timelineData?.filter((e: any) => e?.data?.length > 0)
  return (
    <div key={`grouped-graph-${measurementType}`}>
      {
        sensorsWithoutData.length > 0
          ?
          <Message
            color="blue"
          >
            <Icon name="info circle" />
            Some of the selected sensors have no data and are not shown in the graph:
            <span className="text-capitalize ms-1 text-bold">
              {sensorsWithoutData.map((e: any) => e?.name).join(', ')}
            </span>
          </Message>
          : ''
      }
      <div className='text-capitalize-fl text-dark bg-light-gray ps-1 mb-2 rounded d-flex align-items-center'>
        {/*
          * Image of the type of sensor this graph is of.
          * Note: There's a diff between sensor type and measurement
          * we have images of each sensor type, but not each measurement.
          * Grouped graphs only know about that kind of measurement we're looking at, 
          * not the type of sensor it is until the date is later iterated.
          * That makes it somewhat error prone when only checking against the measurement type.
          * Instead, we just select the first sensor (if present) and use the sensor_type from
          * that, which indicates what all readings are for. 
          * Not very elegant, but is error proof.
          */}
        <Image
          src={`/images/sensor-${sensors[0].sensor_type}.svg`}
          alt="Type of sensor illustration"
          width={15}
          className="me-3 my-1"
        />
        <div>
          <strong>
            <span className="text-capitalize">{measurementType}</span> readings for {(sensors?.length)} selected sensor(s)
          </strong>
          <Popup
            trigger={
              <Icon
                name="question circle"
                className="ms-1"
              />
            }
            content={`This graph has grouped all ${measurementType} readings of the same measurement type and placed them in one graph.`}
          />
          <p className='text-capitalize text-sm mt-1'>
            {/* Format the type and all sensor IDs that have been selected/grouped in this graph */}
            <strong>Reading</strong>: {measurementType} | <strong>Sensor(s)</strong>: {timelineData.map((e: any) => e.name).join(', ')}
          </p>
        </div>
      </div>
      {
        timelineData?.length > 0 && viewData.sensors?.length > 0
          ?
          <Segment
            inverted={darkMode}
          >
            <ResponsiveContainer
              width={'100%'}
              height={'100%'}
              minHeight={'500px'}
              minWidth={'100%'}
              debounce={300}
            >
              <LineChart
                key={`grouped-graph-${measurementType}`}
                width={700}
                height={400}
                data={timelineData}
                margin={{
                  top: 10,
                  right: 10,
                  left: -10,
                  bottom: 10,
                }}
              >
                <CartesianGrid
                  stroke={graphGrid ? 'gray' : 'none'}
                />
                {timelineData.map((sensorReadout: any, sensorReadoutIndex: number) => {
                  return (
                    <Line
                      key={`sensor-${sensorReadoutIndex}`}
                      /**
                       * Since we must use timeline data to correctly populate data
                       * for eact respective line, we have to find more detailed info
                       * about the sensor via the selectedSensors. Their indexes map 1 to 1.
                      */
                      name={sensorReadout?.name?.length ? sensorReadout.name : `ID: ${sensorReadout.sensor_id}`}
                      data={sensorReadout.data}
                      dataKey='value'
                      connectNulls={true}
                      xAxisId={'time'}
                      type={'step'}
                      dot={false}
                      activeDot={{
                        stroke: '#fff',
                        strokeWidth: 3,
                        r: 10
                      }}
                      unit={getUnit(measurementType)}
                      stroke={measurementColor(measurementType, darkMode)}
                      isAnimationActive={false}
                    />
                  )
                })}
                <XAxis
                  xAxisId='time'
                  dataKey='ts'
                  tickFormatter={formatToDate}
                  type='number'
                  angle={0}
                  dy={6}
                  dx={5}
                  // This adds some graph area at the start and end of the X axis
                  domain={[
                    'dataMin - 2',
                    'dataMax + 2'
                  ]}
                  interval='preserveStartEnd'
                />
                <YAxis
                  dataKey='value'
                  // This adds some graph area at the bottom and top Y axis
                  domain={[
                    'dataMin - 2',
                    'dataMax + 2'
                  ]}
                  tickFormatter={formatYAxis}
                />
                {/* Brush allows for scrolling and zooming the graph (!) */}
                <Brush
                  x={40}
                  travellerWidth={40}
                  dataKey={formatToDate}
                  height={20}
                />
                <Tooltip
                  labelFormatter={formatToDate}
                  formatter={(value: any, name: any, props: any) => {
                    return [value.toFixed(2), name]
                  }}
                  contentStyle={{
                    backgroundColor: darkMode === false ? '#eee' : '#333',
                    color: darkMode === false ? '#222' : '#fff',
                    fontWeight: 'bold'
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Segment>
          :
          <Message>
            <Icon name='info circle' size='large' />
            There is not data for the selected date range. Try selecting a different time range.
          </Message>
      }

    </div>
  )
})

export default GroupedByTypeGraph
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { getSensorDepth, getSensorSegment } from '../../lib/format';
import { SensorListItem } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';

interface DisconnectSensorDialogueProps {
  siteKey: string | undefined;
  display: boolean;
  setDisplay: (b: boolean) => void;
  sli: SensorListItem | undefined;
}

const makeContentForDisconnectingSensor = (sli: SensorListItem | undefined) => {
  if (!sli) {
    return '';
  }

  let sensorName: string = sli.sensor ? sli.sensor.name : '' + String(sli.sensor_id).padStart(3, '0');
  let sensorType: string = sli.sensor ? sli.sensor.sensor_type : sli.last_message ? sli.last_message.sensor_type : '';
  let placement: string = sli.sensor_location
    ? getSensorDepth(sli.sensor_location.meta) + ' ' + getSensorSegment(sli.sensor_location.meta)?.label
    : '';
  return (
    'Are you sure you want to disconnect ' +
    _.capitalize(sensorType) +
    ' Sensor ' +
    (sensorName ? '(' + sensorName + ')' : '') +
    'from ' +
    placement
  );
};

const DisconnectSensorDialogue = observer(({ siteKey, sli, display, setDisplay }: DisconnectSensorDialogueProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore } = useStore();
  const unassign = () => {
    AuthFetcher(
      `/api/v1/sensor/site/${siteKey}/connect/sensor/${sli?.sensor?.node_key}/sensorlocation/${sli?.sensor_location?.node_key}`
      ,
      authStore.getTokenFunction(),

      { method: 'DELETE' }
    )
      ?.then((res) => {
        mutate(`/api/v1/site/${siteKey}`);
        mutate(`/api/v1/sensor/site/${siteKey}`);
        res.error ? notyf.error('Could not disconnect sensor') : notyf.success('Successfully disconnected sensor');
        setDisplay(false);
      })
      .catch((err) => {
        notyf.error('Could not unpair sensor.');
        setDisplay(false);
      });
  };
  return (
    <Modal open={display} size="tiny" dimmer="blurring" className="blue">
      <Modal.Header style={{ color: 'black' }}>Are you sure?</Modal.Header>
      <Modal.Content>{makeContentForDisconnectingSensor(sli)}</Modal.Content>
      <Modal.Actions>
        <Button onClick={(e) => setDisplay(false)} negative>
          Cancel
        </Button>
        <Button onClick={(e) => unassign()} positive>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default DisconnectSensorDialogue;

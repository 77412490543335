import { Sidebar } from 'semantic-ui-react';

interface RightMenuProps {
  visible: boolean;
  content: any;
}

const RightMenu = ({ visible, content }: RightMenuProps) => {
  return (
    <Sidebar
      animation="overlay"
      direction="right"
      visible={visible}
      style={{
        boxShadow: '0px 0px 30px ',
        width: '35%',
        minWidth: '600px',
        backgroundColor: 'rgba(29, 53, 86, 0.7)',
        zIndex: 100000000 /*Set stupidly high to avoid having the name of the cages hovering over the menu */,
      }}
      content={content}
    ></Sidebar>
  );
};

export default RightMenu;

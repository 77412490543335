import { observer } from "mobx-react"
import { Button, Grid, Icon, Message, Popup } from "semantic-ui-react"
import { DateRangePicker } from 'react-dates';
import { useState } from "react";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import useStore from "../../Store/Store"
import moment from "moment";
import CsvDownload from "../../Routes/CsvDownload";
import AppSettings from "../../Core/AppSettings";

const minDate = moment().subtract(12, 'month').startOf('month');
const maxDate = moment();

const GraphOptions = observer(({ viewData }: any) => {
  const { analyticsStore } = useStore()
  const [startDate, setStartDate] = useState<any>(moment().startOf('day'));
  const [endDate, setEndDate] = useState<any>(moment());
  const [focusedInput, setFocusedInput] = useState<any>(null);

  const todayDateNow = moment(Date.now())
  /*
  * Remember: Date objects in Moment are mutable, so using references to any previously declared date object,
  * will update the original definition as well (!)
  */
  const todayDateStart = moment(Date.now()).startOf('day')
  const todayDateEnd = moment(Date.now()).endOf('day')

  const handleDatesChange = ({ startDate, endDate }: any) => {
    if (startDate) {
      // Write the date changes to store as unix timestamps to the currently active preset
      viewData.graph_settings.date_from = startDate.unix()
      // Satisfy the requirements of the date range selector
      setStartDate(startDate)
    }
    if (endDate) {
      viewData.graph_settings.date_to = endDate.unix()
      setEndDate(endDate)
      // If the end date is either at the end of today or greater than the current time, graph data is live
      if (endDate.unix() >= todayDateNow.unix() || endDate.unix() === todayDateEnd.unix()) {
        viewData.graph_settings.live = true
      } else {
        viewData.graph_settings.live = false
      }
    }
  };

  // Toggle live data on/off. If on, the to date must be set to today
  const toggleLiveData = () => {
    viewData.graph_settings.live = !viewData.graph_settings.live
    if (viewData.graph_settings.live === true) {
      // Set the start time from the start of today
      viewData.graph_settings.date_from = todayDateStart.unix()
      // Set the end time to the current time
      viewData.graph_settings.date_to = todayDateNow.unix()
      setStartDate(todayDateStart)
      setEndDate(todayDateNow)
    }
  }
  // List of presets that are shown as buttons on the date range chooser
  const RenderDatePresets = () => {
    return (
      <Button.Group
        color="blue"
        inverted
        className="p-3"
        fluid={true}
      >
        <Button
          onClick={() => {
            handleDatesChange(
              {
                startDate: moment(new Date()).subtract(1, 'hour'),
                endDate: moment(new Date())
              }
            )
          }}
        >
          Last hour
        </Button>
        <Button
          onClick={() => {
            handleDatesChange(
              {
                startDate: moment(new Date()).startOf('day'),
                endDate: moment(new Date())
              }
            )
          }}
        >
          Today
        </Button>
        <Popup
          key={'date-select-yesterday'}
          trigger={
            <Button
              onClick={() => {
                handleDatesChange(
                  {
                    startDate: moment(new Date()).subtract(1, 'day').startOf('day'),
                    endDate: moment(new Date()).subtract(1, 'day').endOf('day')
                  }
                )
              }}
            >
              Yesterday
            </Button>
          }
          content="Changing dates to the past will disable live updates."
        />
        <Button
          onClick={() => {
            handleDatesChange(
              {
                startDate: moment(new Date()).startOf('isoWeek'),
                endDate: moment(new Date()).endOf('day')
              }
            )
          }}
        >
          This week
        </Button>
        <Popup
          key={'date-select-last-week'}
          trigger={
            <Button
              onClick={() => {
                handleDatesChange(
                  {
                    startDate: moment(new Date()).subtract(1, 'week').startOf('isoWeek'),
                    endDate: moment(new Date()).subtract(1, 'week').endOf('isoWeek')
                  }
                )
              }}
            >
              Last week
            </Button>
          }
          content="Changing dates to the past will disable live updates."
        />
      </Button.Group>
    );
  };

  const dataPointOptions = [50, 100, 200, 500]
  const graphDisplayOptions = ['small', 'medium', 'large']
  return (
    <div>
      <Grid
        className="bg-dark-blue my-3 px-2 text-white"
        verticalAlign={'middle'}
      >
        {/* Buttons related to graph options, group graphs and download reports */}
        <Grid.Column
          computer={4}
          mobile={8}
        >
          <div className="mb-1">
            <strong>Graph options</strong>
          </div>
          <Button.Group
            size="small"
          >
            <Popup
              key={'toggle-live-data'}
              content="Toggle live data. When on, the date is set to today."
              position="bottom left"
              trigger={
                <Button
                  color={(viewData.graph_settings.live) ? 'green' : 'grey'}
                  onClick={() => toggleLiveData()}
                  className={viewData.graph_settings.live === true ? 'text-dark' : 'text-light'}
                >
                  <Icon
                    name="heartbeat"
                    className={((viewData.graph_settings.live) ? 'pulse ' : '') + 'bg-none'}
                  />
                  Live
                </Button>
              }
            />
            <Popup
              key={'group-sensor-readings'}
              content="Group sensor readings by type. When on, measurements of the same type for all selected sensors are placed in the same graph. When off, each sensor's reading is displayed in a separate graph."
              position="bottom left"
              trigger={
                <Button
                  onClick={() => analyticsStore.toggleDisplayGroupedGraph()}
                  active={viewData.graph_settings.grouped_graphs === true}
                  color={viewData.graph_settings.grouped_graphs === true ? 'green' : 'grey'}
                  className={viewData.graph_settings.grouped_graphs === true ? 'text-dark' : 'text-light'}
                >
                  <Icon
                    name="sort amount down"
                  />
                  Group
                </Button>
              }
            />
            <CsvDownload
              sensors={viewData.sensors}
              to={moment(endDate).endOf('day').unix()}
              from={moment(startDate).unix()}
            />
          </Button.Group>
        </Grid.Column>
        {/* Buttons related to change of number of data points */}
        <Grid.Column
          computer={4}
          mobile={8}
        >
          <div className="mb-1">
            <strong>Graph points</strong>
          </div>
          <Button.Group
            size="small"
          >
            {
              dataPointOptions.map((value: number, key: any) => {
                return (
                  <Popup
                    key={`change-data-points-${value}`}
                    content={`Change the number of data points shown in each graph to ${value}. Higher values mean more granularity. If you're experiencing performance issues, try lowering this value.`}
                    position="bottom center"
                    trigger={
                      <Button
                        inverted
                        onClick={() => analyticsStore.setDataPoints(value)}
                        active={viewData.graph_settings.data_points === value}
                      >
                        {value}
                      </Button>
                    }
                  />
                )
              })
            }
          </Button.Group>
        </Grid.Column>
        {/* Options related to the size of each graph square/size */}
        <Grid.Column
          computer={3}
          mobile={8}
        >
          <div className="mb-1">
            <strong>Graph sizes</strong>
          </div>
          <Button.Group
            size="small"
          >
            {
              graphDisplayOptions.map((value: string, key: number) => {
                return (
                  <Popup
                    key={`graph-size-${value}`}
                    content={`Change graph size to ${value}.`}
                    position='bottom center'
                    trigger={
                      <Button
                        key={`graphsize-${value}-${key}`}
                        inverted
                        className="text-capitalize"
                        onClick={() => analyticsStore.setGraphSize(value)}
                        active={viewData.graph_settings.graph_size === value.toString()}
                      >
                        {value}
                      </Button>
                    }
                  />
                )
              })
            }
          </Button.Group>
        </Grid.Column>
        {/* The date range chooser */}
        <Grid.Column
          computer={4}
          mobile={8}
          className='ms-auto'
        >
          <div className="mb-1">
            <strong>Date (from <Icon name='arrow right' /> to)</strong>
          </div>
          <DateRangePicker
            firstDayOfWeek={1}
            maxDate={moment().add(1, 'hour')}
            minDate={moment().subtract(2, 'year')}
            hideKeyboardShortcutsPanel
            // Seems this date range component requires milliseconds...
            startDate={moment(viewData.graph_settings.date_from * 1000)}
            endDate={moment(viewData.graph_settings.date_to * 1000)}
            startDateId="analytics-start-date"
            endDateId="analytics-end-date"
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat="DD/MM/YYYY"
            minimumNights={0}
            isOutsideRange={(date) => date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day')}
            renderCalendarInfo={RenderDatePresets}
          />
        </Grid.Column>
      </Grid>
      {
        <Message
          color={viewData.graph_settings.live === true ? 'blue' : 'grey'}
          className='mx-2 my-2'
        >
          {viewData.graph_settings.live === true
            ?
            <>
              <Icon
                name="heartbeat"
              />
              Live update is on. Data is updated every {AppSettings.graphUpdateInterval / 1000} seconds.
            </>
            :
            <>
              <Icon
                name="info circle"
              />
              Live update is off. Click the "Live" button to enable live updates.
            </>
          }
        </Message>
      }
    </div>
  )
})

export default GraphOptions
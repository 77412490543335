import { Plane, OrbitControls, Sky } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { observer } from 'mobx-react';
import { useRef, Suspense, useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useHistory } from 'react-router-dom';
import { Button, Segment, Grid, Tab, Icon, Dropdown } from 'semantic-ui-react';
import useSWR, { Key } from 'swr';
import * as THREE from 'three';

import Cage from '../Components/3D/Objects/CageOverviewCage';
import OceanPlane from '../Components/3D/Objects/OceanPlane';
import CageGraphGridNew from '../Components/CageOverview/CageGraphGridNew';
import CageOverviewAlarms from '../Components/CageOverview/CageOverviewAlarms';
import { AuthFetcher } from '../lib/fetch';
import { CageContents, SensorInspectProps, CageProps } from '../lib/types';
import useStore from '../Store/Store';

const timeZones = ['Europe/Oslo'];

interface CagePaneProps {
  siteKey: string | undefined;
  cage: CageContents;
  currentTimeView: string;
  setCurrentTimeView: React.Dispatch<React.SetStateAction<string>>;
  currentTimeValue: number;
  setCurrentTimeValue: React.Dispatch<React.SetStateAction<number>>;
  setMenuRightVisible: any;
  handleRouteChange: ({ sensorType, sensorID, measurement, unit, time }: SensorInspectProps) => void;
}

interface OverviewProps {
  match?: any;
  setMenuRightVisible: any;
}

const CageOverview = observer(({ match, setMenuRightVisible }: OverviewProps) => {
  const { params } = match || {};
  const [cages, setCages] = useState<CageContents[]>([]);
  const [cage, setCage] = useState<CageContents>();
  const [cageId, setCageId] = useState(params?.cageID ? params.cageID : 0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentTimeView, setCurrentTimeView] = useState('Hour');
  const [currentTimeValue, setCurrentTimeValue] = useState(100);
  const history = useHistory();
  const { appStore, siteStore, authStore } = useStore();

  const siteID: string = match?.params?.siteID || siteStore.getSiteKey();
  const [url, setUrl] = useState<Key>(null);

  const { data } = useSWR(url, (url: any) => AuthFetcher(url, authStore.getTokenFunction()), {
    refreshInterval: 2000,
  });

  appStore.setBreadcrumb('Cage Overview');

  useEffect(() => {
    siteID && setUrl('/api/v1/site/' + siteID);
  }, [siteID]);

  useEffect(() => {
    if (params?.cageID) {
      setCageId(params.cageID);
    }
  }, [params]);

  useEffect(() => {
    if (data && data.cages) {
      const cages = data.cages
        .sort((a: { node_key: number }, b: { node_key: number }) => {
          return a.node_key > b.node_key ? 1 : -1;
        })
        .map((cage: any, index: any) => ({ ...cage, index: index }));
      setCages(cages);

      if (!cage) {
        const newCage = cageId ? cages.find((cage: { node_key: any }) => cage.node_key === cageId) : cages[0];
        if (newCage) {
          setCage(newCage);
          setCageId(newCage.node_key);
          setActiveIndex(newCage?.index ? newCage.index : 0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const HandleRouteChange = ({ sensorType, sensorID, measurement, unit, time }: SensorInspectProps) => {
    history.push(
      '/analytics/' +
        siteID +
        '/' +
        cage?.node_key +
        '/' +
        sensorType +
        '/' +
        sensorID +
        '/' +
        measurement +
        '/' +
        unit +
        '/' +
        time
    );
  };

  const onTabChange = (_: any, { activeIndex }: any) => {
    setActiveIndex(activeIndex);
    setCage(cages.find((cage) => cage.index === activeIndex));
  };

  const panes = cages
    .sort((a: CageContents, b: CageContents) => (a.node_key > b.node_key ? 1 : -1))
    .map((cage: CageContents, index: number) => {
      return {
        menuItem: cage.name,
        node_key: cage.node_key,
        render: () => (
          <Tab.Pane key={index} attached={false} className="blue">
            {cage && (
              <CageDisplay
                siteKey={siteID}
                cage={cage}
                currentTimeView={currentTimeView}
                setCurrentTimeView={setCurrentTimeView}
                currentTimeValue={currentTimeValue}
                setCurrentTimeValue={setCurrentTimeValue}
                handleRouteChange={HandleRouteChange}
                setMenuRightVisible={setMenuRightVisible}
              />
            )}
          </Tab.Pane>
        ),
      };
    });
  var defaultActive: number = 0;

  if (cageId && panes) {
    panes.forEach((pane: any, index: number) => {
      if (pane.node_key === cageId) {
        defaultActive = index;
      }
    });
  }

  return (
    <Segment basic>
      <Tab
        activeIndex={activeIndex !== -1 ? activeIndex : defaultActive}
        menu={{ inverted: true, secondary: true, pointing: true }}
        panes={panes}
        onTabChange={onTabChange}
      />
    </Segment>
  );
});

const CageDisplay = ({ cage, currentTimeValue, handleRouteChange, setMenuRightVisible }: CagePaneProps) => {
  const historyOptions = [
    { text: 'Hourly', value: 'minute/60' },
    { text: 'Daily', value: 'hour/24' },
    { text: 'Weekly', value: 'hour/168' },
    { text: 'Monthly', value: 'hour/730' },
  ];
  const [selectedHistoryOption, setSelectedHistoryOption] = useState(historyOptions[0].value);

  const [cageId, setCageId] = useState(cage.node_key);

  useEffect(() => {
    if (cage && cage.node_key && cage.node_key !== cageId) {
      setCageId(cage.node_key);
    }
  }, [cage, cageId]);

  const [now, setNow] = useState(new Date());
  const [value, setValue] = useState(currentTimeValue);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [time, setTime] = useState(false);

  const intervalRef1: { current: NodeJS.Timeout | null } = useRef(null);
  const timerRef1: { current: NodeJS.Timeout | null } = useRef(null);
  useEffect(() => {
    intervalRef1.current = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => {
      intervalRef1.current && clearTimeout(intervalRef1.current);
    };
  }, []);

  useEffect(() => {
    if (!time) {
      setTime(true);
      timerRef1.current = setTimeout(() => {
        setTime(false);
      }, 1000);
      const from = new Date();
      from.setHours(from.getHours() - 1);
      setDates([from, now]);
      const newCurrentTime = dates[0].getTime() + (dates[1].getTime() - dates[0].getTime()) * (value / 100);
      const newCurrentDate = new Date(newCurrentTime);
      setCurrentDate(newCurrentDate);
    }
  }, [now, time, dates, value]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [myInterval, setMyInterval] = useState<any>();
  let [count, setCount] = useState(0);

  const play = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (value >= 100 && !isPlaying) {
        count = 0;
        setValue(count);
      }
      const newInterval = setInterval(() => {
        count += 1;
        setCount(count);
        if (count >= 100) {
          clearInterval(newInterval);
          setIsPlaying(false);
          setValue(100);
        } else {
          setValue(count);
        }
      }, 100);
      setMyInterval(newInterval);
    } else {
      clearInterval(myInterval);
      setIsPlaying(false);
    }
  };

  return (
    <Segment className="blue">
      <Grid>
        <Grid.Row columns={16}>
          <Grid.Column width={6}>
            <CageCanvas cage={cage} />
          </Grid.Column>
          <Grid.Column width={10}>
            <Button
              labelPosition="right"
              color="red"
              style={{
                position: 'absolute',
                top: '-65px',
                right: '10px',
              }}
            >
              Live
              <Icon name="dot circle outline" />
              &nbsp;
              {dates[1].toLocaleTimeString('no-NO', { timeZone: timeZones[0] })}
            </Button>

            <CageOverviewAlarms cage={cage} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            paddingTop: '60px',
            marginTop: '60px',
            backgroundColor: value === 100 ? '' : 'rgba(255,255,255,0.1)',
            transition: 'all 0.25s linear',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <Grid.Column style={{ width: '10%' }}>
            <Button.Group color="blue">
              <Button>
                <Icon name="clock" />
                &nbsp;&nbsp;{historyOptions.find((option) => option.value === selectedHistoryOption)?.text}
              </Button>
              <Dropdown
                className="button icon"
                floating
                options={historyOptions}
                trigger={<></>}
                onChange={(_: any, { value }: any) => {
                  setSelectedHistoryOption(value);
                }}
              />
            </Button.Group>
          </Grid.Column>
          <Grid.Column
            style={{ width: '5%', cursor: 'pointer' }}
            verticalAlign={'middle'}
            textAlign={'center'}
            onClick={play}
          >
            <Icon name={isPlaying ? 'pause' : 'play'} size={'big'} color={'green'} />
          </Grid.Column>
          <Grid.Column style={{ width: '79%' }}>
            <Button
              onClick={() => {
                setValue(100);
                if (isPlaying) play();
              }}
              labelPosition="right"
              color={value === 100 ? 'red' : 'grey'}
              style={{
                position: 'absolute',
                top: '-40px',
                right: '-7%',
                opacity: value === 100 ? 1 : 0.5,
              }}
            >
              Live
              <Icon name={value === 100 ? 'dot circle outline' : 'play'} />
              &nbsp;
              {value === 100 ? dates[1].toLocaleTimeString('no-NO', { timeZone: timeZones[0] }) : ''}
            </Button>

            <Range
              values={[value]}
              onChange={(values) => {
                setValue(values[0]);
                //updateDates();
              }}
              onFinalChange={(values) => {
                if (isPlaying) play();
                setCount(values[0]);
              }}
              min={0}
              max={100}
              step={0.1}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    width: '100%',
                    height: '4px',
                    position: 'absolute',
                    top: '20px',
                    float: 'left',
                    clear: 'both',
                    background: getTrackBackground({
                      values: [value],
                      colors: ['#2B9C6A', '#ccc'],
                      min: 0,
                      max: 100,
                    }),
                    borderRadius: '5px',
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '22px',
                    width: '22px',
                    backgroundColor: '#4BEF8F',
                    borderRadius: '20px',
                    // transition: 'all 0.1s linear',
                  }}
                >
                  <div
                    style={{
                      display: value === 100 ? 'none' : 'block',
                      position: 'absolute',
                      top: value === 100 ? '-65px' : '-50px',
                      left: value === 100 ? '-25px' : '-17px',
                      color: '#fff',
                      textAlign: 'center',
                      backgroundColor: value === 100 ? 'red' : 'grey',
                      padding: '10px',
                      borderRadius: '5px',
                      fontWeight: 700,
                    }}
                  >
                    {currentDate.toLocaleTimeString('no-NO', { timeZone: timeZones[0] }).substr(0, 5)}
                  </div>
                </div>
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            paddingTop: 0,
            backgroundColor: value === 100 ? '' : 'rgba(255,255,255,0.1)',
            transition: 'all 0.35s linear',
          }}
        >
          <Grid.Column style={{ width: '15%' }}></Grid.Column>
          <Grid.Column style={{ width: '81%' }}>
            <Grid>
              <Grid.Column floated="left" width={5}>
                {dates[0].toLocaleString('no-NO', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                  hour12: false,
                  timeZone: timeZones[0],
                })}
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right" width={5}>
                {dates[1].toLocaleString('no-NO', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                  hour12: false,
                  timeZone: timeZones[0],
                })}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            backgroundColor: value === 100 ? '' : 'rgba(255,255,255,0.1)',
            transition: 'all 0.35s linear',
          }}
        >
          <Grid.Column>
            <CageGraphGridNew
              cageId={cage.node_key}
              sampleSize={value}
              handleRouteChange={handleRouteChange}
              referenceLinePercentage={value}
              setMenuRightVisible={setMenuRightVisible}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const CageCanvas = ({ cage }: CageProps) => {
  const virtualCamera = useRef<THREE.Camera>();

  return (
    <Canvas
      id="cageviewcanvas"
      style={{ borderRadius: '10px', width: '100%', height: '100%' }}
      shadows
      camera={{ position: [-60, -20, 0] }}
    >
      <OrbitControls
        camera={virtualCamera.current}
        enablePan={false}
        enableZoom={false}
        enableRotate={true}
        target={[0, -20, 0]}
      />
      <ambientLight intensity={0.1} />
      <Sky sunPosition={[1, 5, 1]} distance={100} />
      <directionalLight
        position={[0, 10, 5]}
        intensity={0.5}
        castShadow
        shadow-mapSize-height={256}
        shadow-mapSize-width={256}
      />
      <fog attach="fog" args={['#050545', 0, 200]} />

      <Suspense fallback={null}>
        <Cage cageData={cage} />
        <OceanPlane rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} scale={[100, 100, 1]} />

        <Plane // Ocean floor
          receiveShadow
          args={[10000, 10000]}
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -100, 0]}
        >
          <meshStandardMaterial attach="material" color="gray" />
        </Plane>
      </Suspense>
    </Canvas>
  );
};

export default CageOverview;

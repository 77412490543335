import LeafletOverviewMap from '../Components/OverviewMap/LeafletOverviewMap';

// import SVGOverViewMap from "../Components/SVGOverviewMap"

// Class for setting up a Leaflet side ofr the Overview Map
const OverViewMap = () => {
  return (
    // <SVGOverViewMap setBreadCrumb={setBreadCrumb} />
    <LeafletOverviewMap />
  );
};

export default OverViewMap;

import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { SensorDeviceContents } from '../../lib/types';

export class SensorConfigStore {
  private startScan: boolean = false;
  private scanning: boolean = false;
  private devices: SensorDeviceContents[] = [];
  private connectedMac: string = '';
  private configMac: string = ''; //sets which mac is searching for. If error in fetching then setting it to empty again
  constructor() {
    makeAutoObservable(this);
  }

  addDevice(device: SensorDeviceContents) {
    this.devices.push(device);
  }
  addDevices(devices: SensorDeviceContents[]) {
    this.devices = devices;
  }

  getDevices() {
    return this.devices;
  }

  isScanning() {
    return this.scanning;
  }

  setScanning(b: boolean) {
    this.scanning = b;
  }

  shouldScan() {
    return this.startScan;
  }

  setStartScan(b: boolean) {
    this.startScan = b;
  }

  toggleStartScan() {
    this.startScan = !this.startScan;
  }

  setConnectedMac(s: string) {
    this.connectedMac = s;
  }

  getConnectedMac() {
    return this.connectedMac;
  }

  setConfigMac(s: string) {
    this.configMac = s;
  }

  getConfigMac() {
    return this.configMac;
  }
}

const sensorConfigContext = createContext({ sensorConfigStore: new SensorConfigStore() });

const useSensorConfigStore = () => useContext(sensorConfigContext);
export default useSensorConfigStore;

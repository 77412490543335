import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { AlarmContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';
import AlarmForm from './AlarmForm';
import useAlarmListStore from './AlarmListStore';

interface EditAlarmDialogueProps {
  siteKey: string | undefined;
  alarm: AlarmContents | undefined;
}

const EditAlarmDialogue = observer(({ siteKey, alarm }: EditAlarmDialogueProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore } = useStore();
  const { alarmListStore } = useAlarmListStore();
  const [newAlarm, setNewAlarm] = useState<AlarmContents>();
  useEffect(() => {
    if (alarm && !newAlarm) {
      setNewAlarm(JSON.parse(JSON.stringify(alarm)));
    }
  }, [alarm, newAlarm]);
  if (!alarm) {
    return <></>;
  }

  const save = async () => {
    console.log('new ALarm->', newAlarm);
    if (newAlarm) {
      let response = await AuthFetcher('/api/v1/alarm/edit', authStore.getTokenFunction(), {
        body: newAlarm,
        method: 'PUT',
        raw: true,
      });

      if (response.ok) {
        notyf.success('Alarm is changed');
        alarmListStore.showEditAlarm(false);
        setNewAlarm(undefined);
        mutate('/api/v1/alarm');
        mutate('/api/v1/site/' + siteKey);
      } else {
        notyf.error('Failed changing the alarm ');
      }
    }
  };
  return (
    <Modal open={alarmListStore.editAlarm}>
      <Modal.Header>Edit alarm</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AlarmForm setAlarm={setNewAlarm} defaultAlarm={alarm} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => save()}>
          {' '}
          Submit
        </Button>
        <Button
          negative
          onClick={() => {
            alarmListStore.showEditAlarm(false);
            setNewAlarm(undefined);
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default EditAlarmDialogue;

import _ from 'lodash';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Modal, Grid, Select, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { CageContents, LocationOption, SensorListItem, SensorLocationContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';
import SegmentPicker from '../UIElements/SegmentPicker';

const makeTitleForConnectingSensor = (cageName: string, sli: SensorListItem | undefined) => {
  if (!cageName || !sli) {
    return '';
  }

  let sensorName = sli.sensor ? sli.sensor.name : '' + String(sli.sensor_id).padStart(3, '0');
  return (
    'Connect ' +
    _.capitalize(sli.sensor ? sli.sensor.sensor_type : sli.last_message ? sli.last_message.sensor_type : '') +
    ' Sensor (' +
    sensorName +
    ') to Cage ' +
    cageName
  );
};

interface ConnectSensorDialogueProps {
  siteKey: string | undefined;
  cageName: string;
  display: boolean;
  setDisplay: (b: boolean) => void;
  sli: SensorListItem | undefined;
  cage: CageContents | undefined;
}

const ConnectSensorDialogue = observer(
  ({ siteKey, cageName, display, sli, cage, setDisplay }: ConnectSensorDialogueProps) => {
    const notyf = useContext(NotyfContext);
    const { authStore } = useStore();
    const [activeSegment, setActiveSegment] = useState<string>();
    const [selectedLocation, setSelectedLocation] = useState<any>();

    var locationOptions: LocationOption[] = [];
    if (cage) {
      cage.sensor_locations?.sort((a: SensorLocationContents, b: SensorLocationContents) =>
        a.meta.min_depth > b.meta.min_depth ? 1 : -1
      );
      locationOptions = cage.sensor_locations.map((location: SensorLocationContents) => {
        const depth = location?.meta?.max_depth
          ? location.meta.type === 'depth'
            ? location.meta.min_depth + ' - ' + location.meta.max_depth
            : location.meta.max_depth - (location.meta.max_depth - location.meta.min_depth) / 2
          : 0;
        const description = location.meta?.type
          ? location.meta.type === 'depth'
            ? depth + ' meters, Cage '
            : depth + ' meters, ' + _.upperFirst(location.meta.type).replace('_', ' ')
          : 'Unknown';
        return {
          key: location.node_key,
          value: location.node_key,
          text: description,
          // type: location.meta.type,
          meta: location.meta,
        };
      });
    }
    var segments: LocationOption[] = [];
    if (locationOptions.length > 0) {
      const location = locationOptions.find((option: LocationOption) => option.key === selectedLocation);
      segments = locationOptions.filter(
        (o: LocationOption) =>
          o.meta?.min_depth === location?.meta?.min_depth &&
          o.meta.type === location.meta.type &&
          o.meta.max_depth === location.meta.max_depth
      );
    }

    const assign = async () => {
      if (!sli) {
        return;
      }
      let sensorkey = sli.sensor?.node_key;
      const locationKey = activeSegment ? activeSegment : selectedLocation;
      if (!sensorkey) {
        const response: any = await AuthFetcher(`/api/v1/sensor/site/${siteKey}`, authStore.getTokenFunction(), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            sensor_id: sli.sensor_id,
            sensor_type: sli.last_message?.sensor_type,
          },
          raw: true,
        });
        const sensor = await response.json();
        if (sensor?.node_key) {
          notyf.success('Sensor added to site');
          sensorkey = sensor.node_key;
        } else notyf.error('Could not add sensor to site');
      }
      if (!sensorkey) {
        notyf.error('Sensor has no node_key. ');
        return;
      }
      AuthFetcher(
        `/api/v1/sensor/site/${siteKey}/connect/sensor/${sensorkey}/sensorlocation/${locationKey}`,
        authStore.getTokenFunction(),
        {
          method: 'PUT',
        }
      )
        ?.then((res) => {
          res.error
            ? notyf.error('Could not pair sensor. Error code: ' + res.status + ' ' + res.statusText)
            : notyf.success('Successfully connected sensor');
          setSelectedLocation(false);
          setDisplay(false);
          mutate(`/api/v1/site/${siteKey}`);
          mutate(`/api/v1/sensor/site/${siteKey}`);
        })
        .catch((err) => {
          notyf.error('Could not pair sensor.');
        });
    };

    return (
      <Modal open={display} size="tiny" dimmer className="blue">
        <Modal.Header>{makeTitleForConnectingSensor(cageName, sli)}</Modal.Header>
        <Modal.Content style={{ color: 'black' }}>
          <Grid stackable columns={2}>
            <Grid.Column width={9}>
              <Select
                fluid
                placeholder="Select placement"
                options={locationOptions.filter((location: any) => location.meta?.left_border === 0)}
                onChange={(e, { value }) => {
                  setActiveSegment(undefined);
                  setSelectedLocation(value);
                }}
                value={selectedLocation}
              />
            </Grid.Column>
            <Grid.Column
              width={7}
              style={{
                height: '115px',
                marginTop: '-30px',
                transform: 'translateY(0%) translateZ(-50px) rotateX(-65deg)',
              }}
            >
              <SegmentPicker segments={segments} activeSegment={activeSegment} setActiveSegment={setActiveSegment} />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(e) => {
              setActiveSegment(undefined);
              setDisplay(false);
            }}
            negative
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              if (selectedLocation) assign();
              else notyf.error('No placement selected.');
            }}
            positive
          >
            Connect
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);

export default ConnectSensorDialogue;

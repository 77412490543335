import L, { LatLng } from 'leaflet';
import icon from '../../images/marker.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { observer } from 'mobx-react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer, CircleMarker, useMapEvents, Marker } from 'react-leaflet';
import { Grid, Segment, Icon, Loader, Input, Button, Dimmer, Header, Popup } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import useSWR, { mutate } from 'swr';

import { AuthFetcher, SiteHandler, SlAlarmStatusesHandler } from '../../lib/fetch';
import { findHighestAlarmStatus } from '../../lib/functionality';

import { FEEDING_BARGE_NODE_TYPE, SiteContents, SiteMeta, SITE_NODE_TYPE } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 39],
  iconAnchor: [14, 39],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface SiteMarkerProps {
  siteKey: string;
  siteName: string;
  GoToSite: (siteID: string, siteName: string) => void;
}

const findColorFromStatus = (status: number) => {
  switch (status) {
    case -1:
      return 'grey';

    case 0:
      return 'green';

    case 1:
      return 'yellow';

    case 2:
      return 'red';

    default:
      return 'grey';
  }
};

const testRadius = 14;

const SiteMarker = observer(({ siteKey, siteName, GoToSite }: SiteMarkerProps) => {
  const { authStore } = useStore();
  const { data: site, error } = useSWR(
    '/api/v1/site/' + siteKey,
    (url) => SiteHandler(AuthFetcher(url, authStore.getTokenFunction())),
    {}
  );
  const { data: siteAlarms, error: alarmerror } = useSWR(
    '/api/v1/alarm/site/' + siteKey,
    (url) => SlAlarmStatusesHandler(AuthFetcher(url, authStore.getTokenFunction())),
    { refreshInterval: 2000 }
  );
  if (!site) {
    return <Segment>Loading...</Segment>;
  } else if (error) {
    return <Segment>Something Went Wrong</Segment>;
  }

  if (!siteAlarms) {
    return <Segment>Loading...</Segment>;
  } else if (alarmerror) {
    return <Segment>Something Went Wrong</Segment>;
  }
  const OnGoToSiteClick = () => {
    GoToSite(siteKey, siteName);
  };

  let highestAlarmValue = findHighestAlarmStatus(siteAlarms);
  let color = findColorFromStatus(highestAlarmValue);
  return (
    <CircleMarker
      center={[site.feeding_barge.latitude, site.feeding_barge.longitude]}
      radius={testRadius}
      pathOptions={{ fillColor: color, color: color, fillOpacity: 1 }}
      eventHandlers={{
        click: OnGoToSiteClick,
      }}
    ></CircleMarker>
  );
});

interface OverviewTabProps {
  siteKeys: SiteMeta[];
  GoToSite: (siteID: string, siteName: string) => void;
  position: LatLng | undefined;
  showNewSite: boolean;
  setShowNewSite: (sns: boolean) => void;
}

const OverviewTab = ({ siteKeys, position, GoToSite, showNewSite, setShowNewSite }: OverviewTabProps) => {
  return (
    <Segment
      style={{
        position: 'absolute',
        right: '10px',
        top: 0,
        minWidth: '200px',
        maxWidth: '400px',
        backgroundColor: '#0E1A2B',
        borderRadius: '10px',
        zIndex: '1000',
      }}
    >
      <Grid>
        <Grid.Row centered>
          <h1 className="text-lg" style={{ color: '#4bef8f' }}>
            {!showNewSite ? `Sites` : 'Add new site'}
          </h1>
        </Grid.Row>
        {!showNewSite &&
          siteKeys.length &&
          siteKeys.map((siteKey, index) => (
            <Grid.Row
              centered
              className="p-0 mb-1"
              key={index}
              onClick={() => GoToSite(siteKey.node_key, siteKey.name)}
            >
              <OverviewTabElement siteKey={siteKey.node_key} siteName={siteKey.name} GoToSite={GoToSite} />
            </Grid.Row>
          ))}
        {showNewSite && (
          <Grid.Row centered>
            <NewSiteTabElement hideNewSite={() => setShowNewSite(false)} position={position} />
          </Grid.Row>
        )}
        {!showNewSite && (
          <Grid.Row centered className="mb-1 p-0" textAlign="right">
            <Button
              color="green"
              className="my-1"
              onClick={() => {
                setShowNewSite(!showNewSite);
                //history.push('/sitesetup');
              }}
            >
              <span className="text-blue">Add new site </span>
            </Button>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

interface NewSiteTabElementProps {
  hideNewSite: () => void;
  position: LatLng | undefined;
  // setPosition: (latLng: LatLng) => void;
}

const NewSiteTabElement = observer(({ position, hideNewSite }: NewSiteTabElementProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore, firstTimeSetupStore } = useStore();
  const [siteName, setSiteName] = useState<string>('');
  const [topsideId, setTopsideId] = useState<any>('');

  // If an active gateway has been set (via the first time setup) and topside ID is not already set, set it as active
  if (firstTimeSetupStore.getActiveGatewayId() && !topsideId) {
    setTopsideId(firstTimeSetupStore.getActiveGatewayId());
  }
  const addSite = async () => {
    if (siteName === '') {
      notyf.error('Please set the site name');
      return;
    }
    if (topsideId === '') {
      notyf.error('Please set the topside Id');
      return;
    }
    if (!position) {
      notyf.error('No position chosen');
      return;
    }
    var site: SiteContents = {
      cages: [],
      node_key: '',
      node_kind: SITE_NODE_TYPE,
      camera: { position: [0, 0, 0] },
      feeding_barge: {
        latitude: position ? position.lat : 0.0,
        rotation: 0,
        longitude: position ? position.lng : 0.0,
        position: [0, 0, 0],
        node_key: '',
        node_kind: FEEDING_BARGE_NODE_TYPE,
      },
      name: siteName,
      topside_id: topsideId,
    };
    var response = await AuthFetcher('/api/v1/add/site', authStore.getTokenFunction(), {
      method: 'PUT',
      body: site,
      raw: true,
    });
    if (response.ok) {
      mutate('/api/v1/site');
      hideNewSite();
    } else {
      if (response) notyf.error('Unable to add site. Response ' + response.status);
      console.error();
    }
  };
  // Returns the form for setting up a new site
  return (
    <div className="text-white p-1" style={{ width: '90%', cursor: 'pointer' }}>
      <Grid>
        {position && (
          <Fragment>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <Popup
                  trigger={
                    <h5>
                      Site name *
                      <Icon name="question circle" className="ms-1" />
                    </h5>
                  }
                  content="Provide the name of your site so it's easy to find it later in the overview."
                  position="left center"
                />
                <Input
                  fluid
                  onChange={(e, d) => setSiteName(d.value)}
                  value={siteName}
                  placeholder="Enter the name for this site"
                ></Input>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <Popup
                  trigger={
                    <h5>
                      Gateway ID *
                      <Icon name="question circle" className="ms-1" />
                    </h5>
                  }
                  content="You can find the ID by looking at label on the outside of the Gateway case."
                  position="left center"
                />
                <Input
                  fluid
                  placeholder="Enter the ID of your gateway"
                  onChange={(e, d) => setTopsideId(d.value)}
                  value={topsideId}
                ></Input>
              </Grid.Column>
            </Grid.Row>
          </Fragment>
        )}
        {!position && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Popup
                trigger={
                  <div>
                    <strong>Click anywhere on the map to place your site</strong>
                    <Icon name="question circle" className="ms-1" size="large" />
                  </div>
                }
                content="Click anywhere on the map to place the site. You can scroll and zoom the map to make placement easier."
                position="top center"
              />
            </Grid.Column>
            <Grid.Column>
              <Button color="grey" className="mt-3" onClick={() => hideNewSite()} icon="cancel">
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        {position && (
          <>
            <Popup
              trigger={
                <h4 className="m-0 mt-1">
                  Location details
                  <Icon name="question circle" className="ms-1" />
                </h4>
              }
              content="The longitude and latitude coordinates of the currently placed marker. This updates as you click on the map."
              position="left center"
            />
            <Grid.Row columns={2}>
              <Grid.Column textAlign="left">
                <strong>Lat:</strong> {position ? position.lat.toFixed(7) : 'N/A'}
                {/* <Input
              fluid
              label={"Lat."}
              value={position ? position.lat : undefined}>
            </Input> */}
              </Grid.Column>
              <Grid.Column textAlign="left">
                <strong>Long:</strong> {position ? position.lng.toFixed(7) : 'N/A'}
                {/* <Input
              fluid
              label={"Long."}
              value={position ? position.lng : undefined}>
            </Input> */}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {position && (
          <Grid.Row>
            <Grid.Column>
              <Button color="grey" onClick={() => hideNewSite()} icon="cancel">
                Cancel
              </Button>
              <Button
                color="green"
                className="ms-2"
                onClick={addSite}
                icon="checkmark"
                disabled={!position || !topsideId || !siteName}
                title={!position || !topsideId || !siteName ? 'Please enter all details for this site' : 'Save'}
              >
                <span className="text-blue">Save</span>
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
});

interface OverviewTabElementProps {
  siteKey: string;
  siteName: string;
  GoToSite: (siteID: string, siteName: string) => void;
}

const OverviewTabElement = observer(({ siteKey, siteName, GoToSite }: OverviewTabElementProps) => {
  const { authStore } = useStore();
  const { data: site, error } = useSWR(
    '/api/v1/site/' + siteKey,
    (url) => SiteHandler(AuthFetcher(url, authStore.getTokenFunction())),
    {}
  );
  const { data: siteAlarms, error: alarmerror } = useSWR(
    '/api/v1/alarm/site/' + siteKey,
    (url) => SlAlarmStatusesHandler(AuthFetcher(url, authStore.getTokenFunction())),
    { refreshInterval: 2000 }
  );

  if (!site) {
    return (
      <Segment inverted>
        <Loader inverted active></Loader>
      </Segment>
    );
  } else if (error) {
    return (
      <Segment>
        <Grid>
          <Grid.Column style={{ width: '100%' }} verticalAlign="middle">
            <h5>No sites found</h5>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  let color = siteAlarms && !alarmerror ? findColorFromStatus(findHighestAlarmStatus(siteAlarms)) : 'grey';

  // Returns a list of sites already setup
  return (
    <div style={{ width: '90%', cursor: 'pointer' }} className="text-white my-1">
      <Grid>
        <Grid.Column style={{ width: '80%' }} verticalAlign="middle" textAlign="left" className="p-1">
          <h5>{site.name}</h5>
        </Grid.Column>
        <Grid.Column
          style={{ width: '20%', cursor: 'pointer' }}
          onClick={() => GoToSite(siteKey, siteName)}
          verticalAlign="middle"
          className="p-0"
        >
          <Icon name="circle" color={color as SemanticCOLORS} size="large" />
        </Grid.Column>
      </Grid>
    </div>
  );
});
interface CoordinatesProps {
  setPosition: (ll: LatLng) => void;
}

const Coordinates = ({ setPosition }: CoordinatesProps) => {
  useMapEvents({
    click(e) {
      // console.log(e.latlng)
      setPosition(e.latlng);
    },
  });
  return <Segment></Segment>;
};

const LeafletOverviewMap = observer(({ setBreadCrumb, SetCurrentSite, width, height, disableGotoSite }: any) => {
  const { authStore, siteStore } = useStore();
  const [position, setPosition] = useState<LatLng>();
  const [showNewSite, setShowNewSite] = useState<boolean>(false);
  const { data, error } = useSWR('/api/v1/site', (url) => AuthFetcher(url, authStore.getTokenFunction()), {
    // refreshInterval: 2000,
  });
  useEffect(() => {
    if (!showNewSite) {
      setPosition(undefined);
    }
  }, [showNewSite, setPosition]);
  // if (error) {
  //   return <div style={{ color: '#ffffff' }}>Something went wrong</div>;
  // }
  const GoToSite = (siteID: string, siteName: string) => {
    // setCurrentSiteKey(siteID);
    if (!disableGotoSite) {
      siteStore.setSiteKey(siteID);
    }
    //history.push('/dashboard/' + siteID);
  };
  const siteKeys: SiteMeta[] = [];
  if (data) {
    siteKeys.push(...data);
  }

  return (
    <Dimmer.Dimmable>
      <Fragment>
        <MapContainer
          center={[64.8478, 12.869]}
          zoom={5}
          minZoom={3}
          scrollWheelZoom={true}
          style={{ width: width || '100%', height: height || '95vh' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            noWrap
          />
          {showNewSite && <Coordinates setPosition={setPosition}></Coordinates>}
          {siteKeys.length &&
            siteKeys.map((siteKey, index) => (
              <SiteMarker key={index} siteKey={siteKey.node_key} siteName={siteKey.name} GoToSite={GoToSite} />
            ))}
          {position && <Marker position={position} />}
        </MapContainer>
        <OverviewTab
          siteKeys={siteKeys}
          GoToSite={GoToSite}
          position={position}
          showNewSite={showNewSite}
          setShowNewSite={setShowNewSite}
        />
      </Fragment>
      <Dimmer active={!data || error}>
        {error && (
          <div>
            <Header as={'h2'}>Unable to fetch sites.</Header>
            <p>{error.toString()}</p>
          </div>
        )}
        {data && <Loader active />}
      </Dimmer>
    </Dimmer.Dimmable>
  );
});

export default LeafletOverviewMap;

import { floor } from "lodash";
import { observer } from "mobx-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Dimmer, Grid, Header, Icon, Input, Loader, Message, Modal, Popup, Segment, Tab } from "semantic-ui-react";
import useSWR, { mutate } from "swr";
import NotyfContext from "../Components/NotyfContext";
import useStore from "../Store/Store";
import { AuthFetcher, SiteHandler } from "../lib/fetch";
import { getSensorDepth, getSensorSegment, getSensorSegmentType } from "../lib/format";
import { CAGE_NODE_TYPE, CageContents, Feeding_Barge, LICE_SKIRT, SENSOR_LOCATION_NODE_TYPE, SINKER_TUBE, STANDARD_CAGE_TYPE, SensorLocationContents, SensorLocationMeta, SiteContents } from "../lib/types";
import SiteView from "./SiteView";

const makeSinkerTube = (depth: number) => {
  let sl: SensorLocationContents = {
    name: 'someName',
    node_key: 'sensorlocation-' + (Math.random() + 1).toString(36).substring(7),
    node_kind: SENSOR_LOCATION_NODE_TYPE,
    scenarios: [],
    sensors: [],
    status: { description: 'something', level: makeLevel(2), time: new Date() },
    meta: { min_depth: depth, max_depth: depth, left_border: 0, right_border: 360, type: SINKER_TUBE },
    scenario_status: [],
  };

  return sl;
};

const makeShieldingSkirt = (depth: number) => {
  let sl: SensorLocationContents = {
    name: 'someName',
    node_key: 'sensorlocation-' + (Math.random() + 1).toString(36).substring(7),
    node_kind: SENSOR_LOCATION_NODE_TYPE,
    scenarios: [],
    sensors: [],
    status: { description: 'something', level: makeLevel(2), time: new Date() },
    meta: { min_depth: depth, max_depth: depth, left_border: 0, right_border: 360, type: LICE_SKIRT },
    scenario_status: [],
  };

  return sl;
};

interface EditCageProps {
  cage: CageContents;
  cages: CageContents[];
  setCages: (cc: CageContents[]) => void;
}
const EditCage = ({ cage, cages, setCages }: EditCageProps) => {
  const [name, setName] = useState<string>(cage.name);
  const [x, setX] = useState<number>(cage.position[0]);
  const [y, setY] = useState<number>(cage.position[2]);
  const [circ, setCirc] = useState<number>(cage.meta.circumference);
  const submitChanges = (x: number, y: number, name: string, circ: number) => {
    cage.position[0] = x;
    cage.position[2] = y;
    cage.name = name;
    cage.meta.circumference = circ;
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      cages[cageIndex] = cage;
      setCages([...cages]);
    }
  };
  const removeCage = () => {
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      cages.splice(cageIndex, 1);
      setCages([...cages]);
    }
  };

  const addSinkerTube = () => {
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      let maxDepth = cages[cageIndex].sensor_locations.reduce(
        (prev, curr) => (prev > curr.meta.max_depth ? prev : curr.meta.max_depth),
        0
      );
      let sinkerTubeSl = makeSinkerTube(maxDepth);
      cages[cageIndex].sensor_locations.push(sinkerTubeSl);
      setCages([...cages]);
    }
  };

  const removeSinkerTube = () => {
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      let stIndex = cages[cageIndex].sensor_locations.findIndex((sl) => {
        return sl.meta.type === SINKER_TUBE;
      });
      if (stIndex > -1) {
        cages[cageIndex].sensor_locations.splice(stIndex, 1);
        setCages([...cages]);
      }
    }
  };

  const addShieldingSkirt = () => {
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      let sinkerTubeSl = makeShieldingSkirt(10);
      cages[cageIndex].sensor_locations.push(sinkerTubeSl);
      setCages([...cages]);
    }
  };

  const removeShieldingSkirt = () => {
    let cageIndex = cages.findIndex((c: CageContents) => c.node_key === cage.node_key);
    if (cageIndex > -1) {
      let stIndex = cages[cageIndex].sensor_locations.findIndex((sl) => {
        return sl.meta.type === LICE_SKIRT;
      });
      if (stIndex > -1) {
        cages[cageIndex].sensor_locations.splice(stIndex, 1);
        setCages([...cages]);
      }
    }
  }

  const hasShieldingSkirt = cage.sensor_locations.findIndex((sl) => (sl.meta.type === LICE_SKIRT)) > -1
  const hasSinkerTube = cage.sensor_locations.findIndex((sl) => (sl.meta.type === SINKER_TUBE)) > -1
  // Form displayed after "Add cage" is clicked
  return (<Segment className="bg-blue text-white mb-3">
    <Button
      color="red"
      content="Remove"
      circular
      size="tiny"
      onClick={removeCage}
      className="position-absolute top-right m-1"
      style={{ zIndex: 1 }}
    />
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={16}>
          <h3>Cage Details</h3>
          <p className="mb-3">Enter details for the cage.</p>
        </Grid.Column>
        <Grid.Column width={16}>
          <h5>Name</h5>
          <Input
            onChange={(e, data) => { setName(data.value); submitChanges(x, y, data.value, circ) }}
            value={name}
          />
        </Grid.Column>
        <Grid.Column className="my-3">
          <h5>Position (East / West)</h5>
          <Input
            type="number"
            onChange={(e, data) => { setX(+data.value); submitChanges(+data.value, y, name, circ) }}
            value={x}
            fluid
          />
        </Grid.Column>
        <Grid.Column className="my-3">
          <h5>Position (North / South)</h5>
          <Input
            type="number"
            onChange={(e, data) => { setY(+data.value); submitChanges(x, +data.value, name, circ) }}
            value={y}
            fluid
          />
        </Grid.Column>
        <Grid.Column
          width={16}
        >
          <h5>Cage Circumference</h5>
          <Input
            type="number"
            label={{ basic: true, content: "metres" }}
            labelPosition="right"
            onChange={(e, data) => { setCirc(+data.value); submitChanges(x, y, name, +data.value) }}
            value={circ}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>Sensor Locations</h3>
          <Segment>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Checkbox checked={hasSinkerTube} onChange={(e, data) => { if (data.checked) { addSinkerTube() } else { removeSinkerTube() } }} toggle label={"Sinker tube"} />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                    checked={hasShieldingSkirt}
                    onChange={(e, data) => { data.checked ? addShieldingSkirt() : removeShieldingSkirt() }}
                    toggle
                    label={"Lice skirt"} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {cage.sensor_locations.map((sl: SensorLocationContents) => {
            return <EditSensorLocation cage={cage} setCages={setCages} sl={sl}></EditSensorLocation>
          })}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>)
}

interface EditSensorLocationProps {
  cage: CageContents;
  setCages: (c: CageContents[]) => void;
  sl: SensorLocationContents;
}

const EditSensorLocation = ({ cage, setCages, sl }: EditSensorLocationProps) => {
  let segment = getSensorSegment(sl.meta);
  let depth = getSensorDepth(sl.meta);
  return <Segment>{depth + (segment.label ? '-' + segment.label : '') + ' ' + getSensorSegmentType(sl.meta)}</Segment>;
};

interface SidebarMenuProps {
  site: SiteContents | undefined;
  pickedNode: CageContents | undefined;
  cages: CageContents[];
  setCages: (cc: CageContents[]) => void;
  setSite: (site: SiteContents) => void;
  feedingBarge: Feeding_Barge | undefined;
  setFeedingBarge: (fb: Feeding_Barge) => void;
  showSaveSiteDialog: () => void;
}

const SideBarMenu = ({ site, pickedNode, cages, setCages, setSite, feedingBarge, setFeedingBarge, showSaveSiteDialog }: SidebarMenuProps) => {
  const [siteName, setSiteName] = useState<string | undefined>();
  const updateSite = () => {
    if (site && siteName) {
      site.name = siteName
      setSite(site)
    }
  }
  return (
    <div className="dashboard_menu mw-500px position-absolute top-right vh-100 bg-blue-opaque w-50 p-3">
      <Grid className="mb-1">
        <Grid.Column floated="left" width={5}>
          <Button
            color='green'
            onClick={showSaveSiteDialog}
          >
            <span className="text-blue">Save</span>
          </Button>
        </Grid.Column>
        <Grid.Column
          floated="right"
          width={5}
          textAlign="right"
        >
          <Button
            inverted
            circular
            icon="times"
            className="close_menu"
            onClick={() => { }}
          />
        </Grid.Column>
      </Grid>
      <Tab
        menu={{
          color: 'blue',
          inverted: true,
          attached: true,
          tabular: true
        }}
        panes={
          [
            {
              menuItem: 'Site',
              render: () => (
                <Tab.Pane>
                  <h5>Site Name</h5>
                  <Input
                    onChange={(e, data) => { setSiteName(data.value); updateSite() }}
                    value={site?.name}
                    fluid
                  />
                </Tab.Pane>
              )
            },
            {
              menuItem: 'Cages',
              render: () => (
                <Tab.Pane className="inverted bg-none cage_panel">
                  {cages.map((c: CageContents) => {
                    return (
                      <EditCage
                        key={c.node_key}
                        cage={c}
                        cages={cages}
                        setCages={setCages}
                      ></EditCage>
                    )
                  })}
                  <AddCage
                    cages={cages}
                    setCages={setCages}
                  ></AddCage>
                </Tab.Pane>
              )
            },
            {
              menuItem: 'Feeding Barge',
              render: () => (feedingBarge && (
                <Tab.Pane className="inverted bg-none cage_panel">
                  <EditFeedingBarge
                    feedingBarge={feedingBarge}
                    setFeedingBarge={setFeedingBarge}
                  ></EditFeedingBarge>
                </Tab.Pane>)
              )
            }
          ]
        }
      />
      {/* <div className="text-center">
        <Button
          color='green'
          onClick={() => showSaveSiteDialog()}
          className="w-100 mt-3"
        >
          <span className="text-blue">Save</span>
        </Button>
      </div> */}
    </div>)
}

interface EditFeedingBargeProps {
  feedingBarge: Feeding_Barge;
  setFeedingBarge: (fb: Feeding_Barge) => void;
}

const EditFeedingBarge = ({ feedingBarge, setFeedingBarge }: EditFeedingBargeProps) => {
  const [x, setX] = useState<number>(feedingBarge.position[0]);
  const [y, setY] = useState<number>(feedingBarge.position[2]);
  const [rot, setRot] = useState<number>(feedingBarge.rotation);
  const [long, setLong] = useState<number>(feedingBarge.longitude);
  const [lat, setLat] = useState<number>(feedingBarge.latitude);
  const changeFeedingBarge = (x: number, y: number, rot: number, long: number, lat: number) => {
    feedingBarge.position = [x, 0, y];
    feedingBarge.rotation = rot
    feedingBarge.longitude = long
    feedingBarge.latitude = lat
    setFeedingBarge({ ...feedingBarge })
  }
  return <Segment className="bg-blue text-white">
    <h3>Feeding Barge</h3>
    <p>Change the position and orientation of the feeding barge.</p>
    <h5>Position (East / West)</h5>
    <Input
      type="number"
      onChange={(e, data) => { setX(+data.value); changeFeedingBarge(+data.value, y, rot, long, lat) }}
      value={x}
    />
    <h5>Position (North / South)</h5>
    <Input
      type="number"
      onChange={(e, data) => { setY(+data.value); changeFeedingBarge(x, +data.value, rot, long, lat) }}
      value={y}
    />
    <h5>Rotation (Degrees)</h5>
    <Input
      type="number"
      onChange={(e, data) => { setRot(+data.value); changeFeedingBarge(x, y, +data.value, long, lat) }}
      value={rot}
    />
    <h5>Longitude</h5>
    <Input
      type="number"
      onChange={(e, data) => { setLong(+data.value); changeFeedingBarge(x, y, rot, +data.value, lat) }}
      value={long}
    />
    <h5>Latitude</h5>
    <Input
      type="number"
      onChange={(e, data) => { setLat(+data.value); changeFeedingBarge(x, y, rot, long, +data.value) }}
      value={lat}
    />


  </Segment>
}

interface AddCageProps {
  cages: CageContents[];
  setCages: (cc: CageContents[]) => void;
}

const AddCage = ({ cages, setCages }: AddCageProps) => {
  const notyf = useContext(NotyfContext);
  const [amount, setAmount] = useState(1);
  const [amountMade, setMade] = useState(0);
  const [maxDepth, setMaxDepth] = useState(40);
  const [noDepths, setNoDepths] = useState(4);
  // const [newCageMode, setNewCageMode] = useState<boolean>(false)
  const [showCageAccessories, setCageAccessories] = useState<boolean>(false)
  const [noSegments, setNoSegments] = useState<number>()
  const [addSinkerTube, shouldAddSinkerTube] = useState<boolean>(false)
  const [addShieldingSkirt, shouldAddShieldingSkirt] = useState<boolean>(false)

  const maxNumberOfSegments = 10
  const maxNumberOfLayers = 20
  const addCages = () => {
    if (amount <= 0) {
      notyf.error('cannot add negative amount of cages');
      return;
    }

    if (!isNoDepthsValid(noDepths)) {
      notyf.error('Invalid max depth and number of cage layers combination.');
      return;
    }

    let newCages: CageContents[] = [...new Array(amount)].map((value: any, index: number) => makeCage(amountMade + index, noDepths, maxDepth, noSegments))
    if (addSinkerTube) {
      newCages.forEach((cage) => {
        cage.sensor_locations.push(makeSinkerTube(maxDepth));
      });
    }
    if (addShieldingSkirt) {
      newCages.forEach((cage) => {
        cage.sensor_locations.push(makeShieldingSkirt(10));
      });
    }

    setCages([...cages, ...newCages]);
    setMade(amountMade + amount);
    // setNewCageMode(true)
  };

  const isNoDepthsValid = (noDepths: number) => {
    return maxDepth / noDepths <= 10.0;
  };

  const inputsValid = () => {
    // Checks for Cage Depth and Cage Layers
    const validityOfPrimaryValues = maxDepth > 0 && noDepths >= 0 && noDepths <= maxNumberOfLayers && (maxDepth / noDepths <= 10)
    // Checks for Cage Segments (if present)
    const validityOfSecondaryValues = noSegments !== undefined ? noSegments > 0 && noSegments <= maxNumberOfSegments : true
    return validityOfPrimaryValues && validityOfSecondaryValues
  }

  return <Segment className="bg-blue text-white">
    <h3>New cage</h3>
    <p>Enter details for the cage.</p>
    <Grid>
      <Grid.Row className="hidden">
        <Grid.Column>
          <Input
            label={"Number of cages"}
            error={amount > 42 || amount <= 0}
            type="number"
            onChange={(e, data) => setAmount(+data.value)}
            value={amount}
            min="0"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Popup
            trigger={
              <div className="mb-1">
                <h4>Cage Depth
                  <Icon
                    name="question circle"
                    className="ms-2"
                  />
                </h4>
              </div>
            }
            content={`Enter the depth your cage is situated at. Valid values are from 1 metres and above.`}
          />

          <Input
            onChange={(e, data) => data.value && setMaxDepth(+data.value)}
            label={{ basic: true, content: "metres" }}
            labelPosition="right"
            placeholder="Cage depth"
            type="number"
            value={maxDepth}
            error={maxDepth < 0}
            min={0}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <Popup
            trigger={
              <div className="mb-1">
                <h4>Cage Layers
                  <Icon
                    name="question circle"
                    className="ms-2"
                  />
                </h4>
              </div>
            }
            content={`Defines how many layers (vertically) your cage consists of. Valid values are between 1 and ${maxNumberOfLayers}.`}
          />
          <Input
            onChange={(e, data) => data.value && setNoDepths(+data.value)}
            placeholder="Number of layers"
            label={{ basic: true, content: "layers" }}
            labelPosition="right"
            error={noDepths > maxNumberOfLayers || noDepths < 0}
            type="number"
            min={0}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        columns={2}
        verticalAlign="middle"
      >
        <Grid.Column width={8}>
          <div className="mb-1">
            <Popup
              trigger={
                <div className="mb-1">
                  <h4>
                    Cage Layers
                    <Icon name="question circle" className="ms-2" />
                  </h4>
                </div>
              }
              content={`Enable if each layer in your cage is divided into segments. Valid values are between 1 and ${maxNumberOfSegments}.`}
            />
            <Input
              onChange={(e, data) => data.value && setNoDepths(+data.value)}
              placeholder="Number of layers"
              label={{ basic: true, content: 'layers' }}
              labelPosition="right"
              error={noDepths > 20 || noDepths < 0}
              type="number"
              fluid
            />
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <Popup
            trigger={
              <h5>Cage Accessories
                <Icon
                  name="question circle"
                  className="ms-1"
                />
              </h5>
            }
            content="Enable if your cage have extra equipment present."
          />
          <Checkbox toggle onChange={(e, data) => setCageAccessories(data.checked ? true : false)} />
        </Grid.Column>
        <Grid.Column width={8}>
          {noSegments !== undefined &&
            <>
              <Input
                onChange={(e, data) => data.value && setNoSegments(+data.value)}
                placeholder="Number of segments"
                label={{ basic: true, content: "segments" }}
                labelPosition="right"
                type="number"
                step={1}
                error={noSegments > maxNumberOfSegments || noSegments < 0}
                min={0}
                fluid
              />
            </>
          }
        </Grid.Column>
        <Grid.Column
          width={8}
        >
          {showCageAccessories === true &&
            <div className="">
              <Checkbox
                onChange={(e, data) => shouldAddSinkerTube(data.checked ? data.checked : false)}
                className="me-1 mt-1"
              />
              Sinker tube
              <Checkbox
                onChange={(e, data) => shouldAddShieldingSkirt(data.checked ? data.checked : false)}
                className="ms-2 me-1"
              />
              Lice skirt
            </div>
          }
        </Grid.Column>
        <Grid.Column
          textAlign="center"
          width={16}
        >
          <Button
            color="black"
            disabled={!inputsValid()}
            onClick={addCages}
            className="mt-3"
            fluid
          >
            Add Cage
          </Button>
          {inputsValid() === false &&
            <Message
              negative
            >
              One or more values are invalid. See the tooltip for each input for valid values.
            </Message>
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
}

const makeCage = (i: number, depths: number, maxDepth: number, segments?: number) => {
  let cage: CageContents = {
    node_key: 'NewCage-' + (Math.random() + 1).toString(36).substring(7),
    node_kind: CAGE_NODE_TYPE,
    name: 'DemoCage' + i,
    position: [90 * (i % 5), 0, 90 * floor(i / 5)],
    meta: { type: STANDARD_CAGE_TYPE, circumference: 160 },
    sensor_locations: makeSLs(depths, maxDepth, segments),
  };
  return cage;
};
const makeSL = (i: number, segmentDepth: number, segment?: number, maxSegments?: number) => {
  let sl: SensorLocationContents = {
    name: 'someName',
    node_key: 'sensorlocation-' + (Math.random() + 1).toString(36).substring(7),
    node_kind: '',
    scenarios: [],
    sensors: [],
    status: { description: 'something', level: makeLevel(i, segment), time: new Date() },
    meta: createMetaFromType(i, segmentDepth, segment, maxSegments),
    scenario_status: [],
  };

  return sl;
};

const makeLevel = (row: number, segment?: number) => {
  if (segment) {
    return (row + segment) % 4;
  }
  return row % 4;
};

const makeSLs = (noDepths: number, maxDepth: number, maxSegments?: number) => {
  let sls: SensorLocationContents[] = [];

  Array.from(Array(noDepths).keys()).forEach((depth) => {
    Array.from(Array(maxSegments ? maxSegments : 1).keys()).forEach((segment) => {
      sls.push(makeSL(depth, maxDepth / noDepths, segment, maxSegments));
    });
  });

  return sls;
};

const createMetaFromType = (depth: number, segmentDepth: number, segment?: number, maxSegements?: number) => {
  let meta: SensorLocationMeta;

  if (segment !== undefined && maxSegements !== undefined) {
    let borders = getSegmentBorder(segment, maxSegements);
    meta = {
      min_depth: segmentDepth * depth,
      max_depth: segmentDepth * (depth + 1),
      left_border: borders[0],
      right_border: borders[1],
      type: 'depth',
    };
  } else {
    meta = {
      min_depth: segmentDepth * depth,
      max_depth: segmentDepth * (depth + 1),
      left_border: 0,
      right_border: 360,
      type: 'depth',
    };
  }
  return meta;
};

const getSegmentBorder = (segment: number, maxSegements: number) => {
  let segmentSize: number = 360 / maxSegements;
  return [segmentSize * segment, segmentSize * (segment + 1)];
};

interface EditSiteProps {
  match?: any;
}

const hasChangedCage = (prevCages: CageContents[], newCages: CageContents[]) => {
  if (prevCages.length !== newCages.length) {
    return true;
  }
  let foundChanges = false;
  prevCages.forEach((pc: CageContents) => {
    let nc: CageContents | undefined = newCages.find((c: CageContents) => c.node_key === pc.node_key);
    if (!nc) {
      foundChanges = true;
      return;
    }
    if (nc.position[0] !== pc.position[0] || nc.position[2] !== pc.position[2]) {
      foundChanges = true;
      return;
    }
    if (nc.name !== pc.name) {
      foundChanges = true;
      return;
    }
    if (pc.meta.circumference !== nc.meta.circumference) {
      foundChanges = true;
      return;
    }

    if (pc.sensor_locations.length !== nc.sensor_locations.length) {
      foundChanges = true;
      return;
    }
  });
  return foundChanges;
};

const hasChangedFeedingBarge = (prevFB: Feeding_Barge, newFB: Feeding_Barge) => {
  return (
    prevFB.position[0] !== newFB.position[0] ||
    prevFB.position[2] !== newFB.position[2] ||
    prevFB.rotation !== newFB.rotation
  );
};

const EditSite = observer(({ match }: EditSiteProps) => {
  const notyf = useContext(NotyfContext);
  const { siteStore, authStore, appStore } = useStore();
  if (match?.params?.siteId && siteStore.getSiteKey() !== match.params.siteStore.getSiteKey()) {
    siteStore.setSiteKey(match.params.siteStore.getSiteKey());
  }
  appStore.setBreadcrumb('Site Edit');
  const nodeClicked = (key: string) => {
    var foundCage = site?.cages.find((c: CageContents) => c.node_key === key);
    setPicked(foundCage);
  };
  const [picked, setPicked] = useState<CageContents | undefined>();
  const [site, setSite] = useState<SiteContents>();
  const [cages, setCages] = useState<CageContents[]>([]);
  const [feedingBarge, setFeedingBarge] = useState<Feeding_Barge>();
  const [showSaveSite, setShowSaveSite] = useState<boolean>(false);
  const { data: savedSite, error } = useSWR(
    siteStore.getSiteKey() ? '/api/v1/site/' + siteStore.getSiteKey() : null,
    (url) => SiteHandler(AuthFetcher(url, authStore.getTokenFunction())),
    {}
  );
  useEffect(() => {
    if (site && hasChangedCage(site.cages, cages)) {
      site.cages = cages;
      setSite(JSON.parse(JSON.stringify(site)));
    }
  }, [site, cages, setSite]);

  useEffect(() => {
    if (site && feedingBarge && hasChangedFeedingBarge(site.feeding_barge, feedingBarge)) {
      site.feeding_barge = feedingBarge;
      setSite(JSON.parse(JSON.stringify(site)));
    }
  }, [site, feedingBarge, setSite]);

  if (!savedSite) {
    return <Loader active size="huge"></Loader>;
  }

  if (!site || site.node_key !== siteStore.getSiteKey()) {
    if (savedSite) {
      //make a copy of the saved site
      let newSite: SiteContents = JSON.parse(JSON.stringify(savedSite));
      if (newSite && newSite.cages !== undefined) {
        newSite.cages.forEach((c) => {
          c.sensor_locations.forEach((sl, index) => {
            sl.status.level = index % 4;
          });
        });
        setSite(newSite);
        setCages(JSON.parse(JSON.stringify(newSite.cages)));
        setFeedingBarge(JSON.parse(JSON.stringify(newSite.feeding_barge)));
      }
    }
  }

  const saveSite = async () => {
    let response = await AuthFetcher('/api/v1/edit/site', authStore.getTokenFunction(), {
      method: 'PUT',
      body: site,
      raw: true,
    });

    if (response.ok) {
      notyf.success("Saved site!")
      mutate('/api/v1/site/' + siteStore.getSiteKey())
      setShowSaveSite(false)
    } else {
      notyf.error("Failed saving site")
    }
    setShowSaveSite(false)
  }
  console.log(savedSite)
  return <>
    <Dimmer.Dimmable as={Fragment}>
      <Fragment>
        {/* Displays the canvas 3D map */}
        <SiteView
          site={site}
          nodeClicked={nodeClicked}
          setPicked={setPicked}
          setMenuRightVisible={() => true}
          showAlarms={false}
          showColors={true}
        />
        {/* Displays the cage add/edit right-side menu */}
        <SideBarMenu
          site={site}
          pickedNode={picked}
          cages={cages}
          setCages={setCages}
          setSite={setSite}
          feedingBarge={feedingBarge}
          setFeedingBarge={setFeedingBarge}
          showSaveSiteDialog={() => setShowSaveSite(true)}
        />
      </Fragment>
      <Modal open={showSaveSite}>
        <Modal.Header>Cage Details</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column></Grid.Column>
              <Grid.Column>Old</Grid.Column>
              <Grid.Column>New</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Cages</Grid.Column><Grid.Column>{savedSite.cages.length}</Grid.Column>
              <Grid.Column>{site?.cages.length}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Feeding Barge Position</Grid.Column>
              <Grid.Column>X:{savedSite.feeding_barge.position[0]} Y:{savedSite.feeding_barge.position[2]}</Grid.Column>
              <Grid.Column>X:{site?.feeding_barge.position[0]} Y:{site?.feeding_barge.position[2]}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Feeding Barge Rotation</Grid.Column>
              <Grid.Column>{savedSite.feeding_barge.rotation}</Grid.Column>
              <Grid.Column>{site?.feeding_barge.rotation}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            onClick={() => { setShowSaveSite(false) }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={saveSite}
          >
            <span className="text-light-green">Save</span>
          </Button>
        </Modal.Actions>
      </Modal>
      <Dimmer style={{ zIndex: 100000000 /*Set stupidly high to avoid having the name of the cages hovering over the dimmer */ }} active={error}>
        <Header as='h2' inverted icon>
          Unable to load Site data
        </Header>
      </Dimmer>

    </Dimmer.Dimmable>
  </>
})

export default EditSite
import { useState } from 'react';
import { Container, Grid, Header, Button, Tab } from 'semantic-ui-react';
import { CageContents, SiteContents } from '../../lib/types';
import AlarmList from '../AlarmList/AlarmList';
import CageGraphGridNew from '../CageOverview/CageGraphGridNew';
import CageOverviewAlarms from '../CageOverview/CageOverviewAlarms';
import SensorList from '../SensorList/SensorList';

const getCageName = (cageKey: string, site: SiteContents | undefined) => {
  if (!site) {
    return '';
  }
  const cage = site.cages.find((cage) => cage.node_key === cageKey);
  if (cage) {
    return cage.name.length > 2 ? cage.name : cage.node_kind + ' ' + cage.name;
  } else {
    return '';
  }
};

interface RightMenuDashboardProps {
  cageKey: string;
  site: SiteContents | undefined;
  setRightMenuVisible: (b: boolean) => void;
}

const RightMenuDashboard = ({ cageKey, site, setRightMenuVisible }: RightMenuDashboardProps) => {
  const cage: CageContents | undefined = site?.cages.find((c: CageContents) => c.node_key === cageKey);
  const [activeIndex, setActiveIndex] = useState<string | number | undefined>();
  return (
    <Container className="dashboard_menu">
      <Grid>
        <Grid.Column floated="left" width={5}>
          <Header inverted style={{ padding: '20px' }}>
            {getCageName(cageKey, site)}
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={5} textAlign="right">
          <Button
            inverted
            circular
            icon="close"
            className="close_menu"
            onClick={() => {
              setRightMenuVisible(false);
            }}
          />
        </Grid.Column>
      </Grid>

      <Tab
        menu={{ color: 'blue', inverted: true, attached: true, tabular: true }}
        panes={panes({ cage, setRightMenuVisible })}
        activeIndex={activeIndex}
        onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
      />
    </Container>
  );
};

interface PanesProps {
  cage: CageContents | undefined;
  setRightMenuVisible: any;
}

const panes = ({ cage, setRightMenuVisible }: PanesProps) => {
  if (cage === undefined) {
    return [];
  } else {
    return [
      {
        menuItem: 'Trends',
        render: () => (
          <Tab.Pane className="inverted cage_panel" loading={!cage}>
            {cage && <CageOverviewAlarms cage={cage} className="alarm-container dashboard" />}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Graphs',
        render: () => {
          return (
            <Tab.Pane className="inverted cage_panel">
              {cage && (
                <CageGraphGridNew
                  setMenuRightVisible={setRightMenuVisible}
                  cageId={cage.node_key}
                  sampleSize={100}
                  referenceLinePercentage={100}
                />
              )}
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: 'Sensors',
        render: () => (
          <Tab.Pane className="inverted cage_panel">
            {cage && <SensorList cageKey={cage?.node_key} cageName={cage?.name} />}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Alarms',
        render: () => <Tab.Pane className="inverted cage_panel">{cage && <AlarmList cage={cage} />}</Tab.Pane>,
      },
    ];
  }
};

export default RightMenuDashboard;

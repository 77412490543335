import { ReactElement, useState } from 'react';
import { Button, Card, Form, Grid, GridColumn, Icon, Image, Label, Message, SemanticWIDTHS } from 'semantic-ui-react';
import useSWR from 'swr';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { observer } from 'mobx-react';
import useStore from '../../Store/Store';
import Placeholders from '../Placeholders';
import { SensorContents, SensorDeviceContents, SENSOR_NODE_TYPE } from '../../lib/types';
import NotyfContext from '../NotyfContext';
import { useContext } from 'react';

/**
 * The introduction text section
 *
 * @param isScanning - Whether or not scanning is on-going
 * @param setShowHelp - State method to toggle help display
 * @param showHelp - The current state value of help being displayed
 * @returns {ReactElement} - React JSX element
 */
const SensorScan = ({
  isScanning,
  setShowHelp,
  showHelp,
}: {
  isScanning: boolean;
  setShowHelp: any;
  showHelp: boolean;
}): ReactElement => {
  return (
    <>
      <Grid verticalAlign="middle">
        <GridColumn
          width={6}
          verticalAlign="middle"
        >
          {isScanning === true && (
            <>
              <img
                src="/images/loading.gif"
                alt="Loading animation"
                width="25px"
                className="me-1"
              />
              Looking for sensors...
            </>
          )}
        </GridColumn>
        <GridColumn
          width={10}
          textAlign="right"
        >
          <div>
            <span className="ms-2">
              <Button
                onClick={() => setShowHelp(!showHelp)}
                size="tiny"
                circular
                color="grey"
                className="p-1 ms-2"
              >
                Show connection help
                <Icon
                  name={showHelp === true ? 'chevron up' : 'chevron down'}
                  className="ms-1"
                />
              </Button>
            </span>
          </div>
        </GridColumn>
      </Grid>
      {/* Help section for troubleshooting sensor connections */}
      {showHelp &&
        <SensorHelpMessage
          method={setShowHelp}
          value={showHelp}
        />
      }
    </>
  );
};

const ManageSensorForm = ({
  setSensorName,
  setSelectedDevice,
  selectedDevice,
  saveSensor,
  sensorName,
  saving,
}: any) => {
  const cancelSelection = () => {
    setSelectedDevice(null);
    setSensorName(null);
  };

  const editMode = sensorName?.length;
  return (
    <>
      <h3 className="text-capitalize">
        {editMode ? 'Update' : 'Add'} {selectedDevice.sensor_type} sensor (ID: {selectedDevice.serial_number})
      </h3>
      <p>We highly suggest you name the sensor after where they'll be placed, the type of sensor and depth.</p>
      <p className="my-0">
        For example:
        <Label color="blue" className="ms-1">
          Cage 1 - Oxygen - 10 metres
        </Label>
      </p>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            required={true}
            label="Sensor name"
            placeholder="Provide a name for this sensor..."
            name="sensor-name"
            defaultValue={sensorName}
            onBlur={(e: any) => setSensorName(e.target.value)}
          />
          <Form.Field
            label="Sensor serial"
            placeholder="Serial number of the sensor"
            value={selectedDevice?.serial_number}
            name="sensor-serial"
            control="input"
            disabled
          />
          <Form.Field
            label="Sensor type"
            control="select"
            title="The sensor type is already defined"
            value={selectedDevice?.sensor_type}
            disabled
          >
            <SensorOptionValues
              selectedValue={selectedDevice?.sensor_type}
            />
          </Form.Field>
          <Form.Field width={3} className="text-center">
            <img src={`/images/sensor-${selectedDevice?.sensor_type}.svg`} alt="Sensor Icon" width={40} />
          </Form.Field>
        </Form.Group>
      </Form>
      <div className="text-center mt-3">
        <Button color="grey" className="me-3" onClick={() => cancelSelection()}>
          Cancel
        </Button>
        <Button
          color="green"
          className="bg-dark-green"
          content="Save"
          onClick={() => saveSensor(selectedDevice, sensorName)}
          disabled={saving || !sensorName?.length}
          loading={saving}
        />
      </div>
    </>
  );
};

/**
 * Displays a help message about troubleshooting sensor connections
 *
 * @param method - The state method which'll update help message toggle
 * @param value - The current value of the message toggle
 * @returns <ReactElement>
 */
const SensorHelpMessage = ({ method, value }: any) => {
  return (
    <Message onDismiss={() => method(!value)} className="mt-3">
      <Message.Header>Troubleshooting Sensor Connections</Message.Header>
      <p>
        Charge pads require sufficient power to charge a sensor. If connected to a USB port, there may not be enough
        power. Please use a mobile charger with at least 1 ampere output or higher.
      </p>
      <Message.List>
        <Message.Item>
          Check that both the charge pad(s) and sensor(s) are clean. Dust and debris may prevent proper connection.
        </Message.Item>
        <Message.Item>
          Check that the USB cable is properly attached to the charge pad and the charge outlet.
        </Message.Item>
        <Message.Item>
          A led on the sensor, located at the bottom, should pulse green when charging. Bluetooth is activated
          automatically when charging.
        </Message.Item>
        <Message.Item>
          It may take a little time after the sensor is placed on the charge pad until it shows up in the list.
        </Message.Item>
        <Message.Item>
          Keep the sensor on the charge pad until you've completed registration of the sensor.
        </Message.Item>
      </Message.List>
    </Message>
  );
};

/**
 * Meta data displayed for each device
 *
 */
const DeviceMeta = ({
  name,
  type,
  serial,
  charged,
  mac,
}: {
  name: string;
  type: string;
  serial: string;
  charged: boolean;
  mac: string;
}) => {
  const deviceName = name?.length ? name : `${type} sensor`;
  return (
    <Card.Content>
      <Card.Header>
        <h4 className="text-capitalize">
          <img src={`/images/sensor-${type}.svg`} alt="Sensor Icon" width={20} className="me-1" />
          {deviceName}
        </h4>
      </Card.Header>
      <Card.Meta></Card.Meta>
      <Card.Description>
        <p>
          <Icon name="info circle" />
          {type}
        </p>
        <p>
          <Icon name="file code" />
          {serial}
        </p>
        <p>
          <Icon name={charged === true ? 'check' : 'lightning'} />
          {charged ? 'Sensor is fully charged' : 'Sensor charging'}
        </p>
        <p>
          <Icon name="info circle" />
          {mac}
        </p>
      </Card.Description>
    </Card.Content>
  );
};

interface deviceListInterface {
  devices: any;
  setSelectedDevice: any;
  setSensorName: any;
  rows: SemanticWIDTHS;
  source: string;
}
const DeviceList = ({ devices, setSelectedDevice, setSensorName, rows, source }: deviceListInterface) => {
  // Filter any devices not identifying as a sensor with sensor type
  const dataFromDatabase = source === 'db';
  let filteredDevices = [];
  // Filtering for devices is slightly different when data comes from DB or from BT
  if (dataFromDatabase) {
    filteredDevices = devices?.filter((e: any) => e.sensor !== null);
  } else {
    filteredDevices = devices?.filter((e: any) => e.sensor_type.length > 0);
  }
  // Sort devices by sensor type and mac address
  filteredDevices.sort((a: any, b: any) => {
    if (a.sensor_type === b.sensor_type) {
      return a.mac < b.mac ? -1 : 1;
    } else {
      return a.sensor_type < b.sensor_type ? -1 : 1;
    }
  });

  // Convert existing db data to that of bluetooth structure
  // TODO: This shouldn't be needed once the needed backend changes are done
  if (dataFromDatabase) {
    filteredDevices = filteredDevices.map((e: any) => {
      return {
        node_key: e?.sensor?.node_key,
        node_kind: e?.sensor?.node_kind,
        name: e?.sensor?.name,
        mac: e?.sensor?.mac || 'N/A',
        sensor_type: e?.sensor?.sensor_type,
        serial_number: e?.sensor?.sensor_id,
        in_storage: false,
        is_fully_charged: false,
      };
    });
  }

  // Put the selected device as active and sets the name (if any exists, usually for editing)
  const selectDevice = (e: any) => {
    setSelectedDevice(e);
    setSensorName(e?.name);
  };

  const list = filteredDevices.map((e: any, index: number) => {
    return (
      <Card key={`card-${index}`} onClick={() => selectDevice(e)}>
        <DeviceMeta
          name={e.name}
          type={e.sensor_type}
          serial={e.serial_number}
          charged={e.is_fully_charged}
          mac={e.mac}
        />
        <Card.Content extra>
          <Button className={dataFromDatabase ? '' : `bg-dark-green text-white`} fluid onClick={() => selectDevice(e)}>
            {dataFromDatabase ? 'Edit sensor' : 'Add sensor'}
          </Button>
        </Card.Content>
      </Card>
    );
  });
  return (
    <>
      <h2>{filteredDevices?.length > 0 ? filteredDevices.length : ''} Sensors found (Bluetooth)</h2>
      <Card.Group
        itemsPerRow={rows}
      >
        {list}
      </Card.Group>
    </>
  );
};

const SensorOptionValues = ({ selectedValue }: { selectedValue: string }) => {
  const sensorTypes = [
    {
      value: 'oxygen',
      name: 'Oxygen',
    },
    {
      value: 'tilt',
      name: 'Tilt / Depth',
    },
    {
      value: 'salinity',
      name: 'Salinity',
    },
    {
      value: 'turbidity',
      name: 'Turbidity'
    },
  ];

  let output = sensorTypes.map((e: any) => {
    return <option value={e.value}>{e.name}</option>;
  });

  return <>{output}</>;
};

const SensorSetup = observer(() => {
  const { authStore, siteStore, firstTimeSetupStore } = useStore();
  const [showHelp, setShowHelp] = useState(false);
  const [saving, setSaving] = useState(false);
  const notyf = useContext(NotyfContext);
  const [sensorName, setSensorName] = useState('');
  const [selectedDevice, setSelectedDevice] = useState<SensorDeviceContents | null>(null);
  const siteKey = siteStore.getSiteKey();
  let devices: any = [];
  let isScanning = false;

  const resetDeviceSelection = () => {
    setSelectedDevice(null);
    setSensorName('');
  };

  const saveSensor = async (data: any, name: string) => {
    setSaving(true);
    let response;
    let newSensorAdded = false;
    // Node key exists and has length, so data is from the database. Will throw an error if it's not found, however
    if (data?.node_key?.length) {
      response = await AuthFetcher(
        `/api/v1/sensor/${data.sensor_type}/${data.serial_number}/meta/setName`,
        authStore.getTokenFunction(),
        {
          method: 'PUT',
          body: name,
          raw: true,
        }
      );
    } else {
      // Data is new
      newSensorAdded = true;
      const newSensor: SensorContents = {
        node_key: '',
        node_kind: SENSOR_NODE_TYPE,
        sensor_id: data.serial_number,
        sensor_type: data.sensor_type,
        name: name.length > 0 ? name : `${data.sensor_type}-${data.serial_number}`,
      };

      // TODO: Backend should use POST here, not PUT
      response = await AuthFetcher(`/api/v1/sensor/site/${siteKey}`, authStore.getTokenFunction(), {
        method: 'PUT',
        raw: true,
        body: newSensor,
      });
    }
    if (response.ok) {
      notyf.success(newSensorAdded ? 'Sensor added!' : 'Sensor was updated!');
      // Refresh / Retrieve sensors again to get the latest data
      mutate(`/api/v1/sensor/site/${siteKey}`);
      // Reset the device selection
      resetDeviceSelection();
    } else {
      notyf.error('Failed to save sensor. If the problem persists, please contact us at support@waterlinked.com.');
    }
    setSaving(false);
  };

  // Automatically search for devices
  const { data: deviceSearch, error: deviceSearchError } = useSWR(
    `/api/v1/sensorconfig/scan/${siteKey}`,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {
      refreshInterval: 10000,
    }
  );

  // Fetch information about sensors already been added
  const { data: existingDevices, error: existingDevicesError } = useSWR(`/api/v1/sensor/site/${siteKey}`, (url) =>
    AuthFetcher(url, authStore.getTokenFunction())
  );

  if (existingDevicesError) {
    notyf.error('Scanning devices failed. ' + existingDevices.toString());
  }

  if (deviceSearchError) {
    notyf.error(deviceSearchError.toString());
  } else if (deviceSearch) {
    isScanning = deviceSearch.is_scanning;
    devices = deviceSearch.result;
  }

  let devicesAdded = existingDevices?.filter((e: any) => e.sensor !== null)
  // There may be existing sensors in this list, but the ones we're looking for have the sensor attribute set.
  if (devicesAdded?.length >= 1) {
    firstTimeSetupStore.setStepComplete(firstTimeSetupStore.getCurrentStep());
  } else {
    firstTimeSetupStore.setStepIncomplete(firstTimeSetupStore.getCurrentStep());
  }
  // Device has been selected
  if (selectedDevice) {
    return (
      <ManageSensorForm
        setSensorName={setSensorName}
        setSelectedDevice={setSelectedDevice}
        selectedDevice={selectedDevice}
        saveSensor={saveSensor}
        sensorName={sensorName}
        saving={saving}
      />
    );
  } else {
    return (
      <div>
        <Grid>
          <GridColumn width={16} className="mt-1">
            <Image
              src="/images/first-time-setup-connect-sensors.jpg"
              alt="Place sensor on charge pad"
              className="mb-3 shadow rounded"
            />
            <p>
              The sensors are up next. If you haven't already, connect charge pad(s) to power and place a sensor on the
              charge pad.
            </p>
            <p>Wait for the led on the sensor to pulse green (located at the bottom). Sensors that've been found and sensors you've added are
              shown below. Searching starts automatically.
            </p>
          </GridColumn>
        </Grid>
        {/* Lists existing sensors already added, if any */}
        <h2>Sensors you have added</h2>
        {!devicesAdded?.length ? (
          <p>No sensors added yet.</p>
        ) : (
          <DeviceList
            devices={existingDevices}
            source="db"
            setSelectedDevice={setSelectedDevice}
            setSensorName={setSensorName}
            rows={3}
          />
        )}
        <hr className="my-3" />
        <SensorScan
          isScanning={isScanning}
          setShowHelp={setShowHelp}
          showHelp={showHelp}
        />
        {/* Lists sensors that have been found by scanning, if any */}
        {!devices.length ? (
          <Placeholders
            numberOfColumns={3}
            numberOfCards={3}
          />
        ) : (
          <div className="my-3">
            <DeviceList
              rows={3}
              devices={devices}
              source="bt"
              setSelectedDevice={setSelectedDevice}
              setSensorName={setSensorName}
            />
          </div>
        )}
      </div>
    );
  }
});

export default SensorSetup;

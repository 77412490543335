import { useState } from 'react';
import { Dropdown, Icon, Loader, Message, Popup, Segment, Table } from 'semantic-ui-react';
import useSWR from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { getSensorDepth, getSensorName, getSensorSegment } from '../../lib/format';
import { findSensorTypesFromMeasurements } from '../../lib/functionality';
import {
  AlarmContents,
  CageContents,
  MeasurementPair,
  ScenarioStatus,
  SensorContents,
  SensorLocationContents,
  TriggerContents,
} from '../../lib/types';
import ConnectAlarmDialogue from './ConnectAlarmDialogue';
import CreateAlarmDialogue from './CreateAlarmDialogue';
import SensorIcon from '../UIElements/SensorIcon';
import _ from 'lodash';
import DisconnectAlarmDialogue from './DisconnectAlarmDialogue';
import EditAlarmDialogue from './EditAlarmDialogue';

import { observer } from 'mobx-react-lite';
import useAlarmListStore from './AlarmListStore';
import useStore from '../../Store/Store';

interface AlarmListProps {
  cage: CageContents | undefined;
}

interface AlarmProps {
  alarm: AlarmContents;
  status?: ScenarioStatus;
  sl?: SensorLocationContents;
  setPickedAlarm: (a: AlarmContents) => void;
}

// const getColorFromLevel= (level:number)=>{
//     switch(level){
//         default:
//             return "grey"
//         case 0:
//             return "green"
//         case 1:
//             return "yellow"
//         case 2:
//             return "red"

//     }
// }

interface SensorProps {
  sensor: SensorContents;
}

const Sensor = ({ sensor }: SensorProps) => {
  let sensorName = getSensorName(sensor.sensor_type, sensor.sensor_id, sensor.name);
  return (
    <Popup
      trigger={
        <Segment textAlign="center" style={{ padding: 0, margin: 0 }}>
          <SensorIcon sensorType={sensor.sensor_type} />
        </Segment>
      }
    >
      {sensorName}
    </Popup>
  );
};

interface TriggerDisplayProps {
  trigger: TriggerContents;
}

const TriggerDisplay = ({ trigger }: TriggerDisplayProps) => {
  if (trigger.trigger_type === 'one_dimension_point') {
    let action =
      trigger.trigger_action === 'less_than'
        ? ' < '
        : trigger.trigger_action === 'more_than'
        ? ' > '
        : trigger.trigger_action;

    return (
      <Segment inverted>
        <Segment inverted>
          <Icon name="circle" color="yellow"></Icon>
          <span>{_.capitalize(trigger.measurements) + action + trigger.warning_point}</span>
        </Segment>
        <Segment inverted>
          <Icon name="circle" color="red"></Icon>
          <span>{_.capitalize(trigger.measurements) + action + trigger.critical_point}</span>
        </Segment>
      </Segment>
    );
  } else if (trigger.trigger_type === 'one_dimension_deviance') {
    return (
      <Segment inverted>
        <Segment inverted>
          <Icon name="circle" color="yellow"></Icon>
          <span>
            {_.capitalize(trigger.measurements) + ' ' + trigger.baseline_point} &plusmn; {trigger.warning_point}
          </span>
        </Segment>
        <Segment inverted>
          <Icon name="circle" color="red"></Icon>
          <span>
            {_.capitalize(trigger.measurements) + ' ' + trigger.baseline_point} &plusmn; {trigger.critical_point}
          </span>
        </Segment>
      </Segment>
    );
  }

  return <Segment></Segment>;
};

const Alarm = ({ alarm, status, sl, setPickedAlarm }: AlarmProps) => {
  // let level = status ? status.severity.level : -1
  const { alarmListStore } = useAlarmListStore();
  let depth = sl ? getSensorDepth(sl.meta) : '';
  let segment = sl ? getSensorSegment(sl.meta) : { segmentNr: -1, label: '' };
  let measurements = findSensorTypesFromMeasurements(
    status
      ? status.measurements.map((mPair: MeasurementPair) => {
          return mPair.type;
        })
      : []
  );
  let sensors: SensorContents[] = [];
  if (sl) {
    measurements
      .map((type: string) => {
        return sl.sensors.find((sensor: SensorContents) => sensor.sensor_type === type);
      })
      .forEach((sensor: SensorContents | undefined) => {
        if (sensor) {
          sensors.push(sensor);
        }
      });
  }
  return (
    <Table.Row>
      <Table.Cell>
        <Segment inverted>
          <span style={{ position: 'relative' }}>{alarm.name}</span>
        </Segment>
      </Table.Cell>
      <Table.Cell>
        <TriggerDisplay trigger={alarm.trigger}></TriggerDisplay>
      </Table.Cell>
      <Table.Cell>
        <Segment inverted>{depth + ' ' + segment.label}</Segment>
      </Table.Cell>
      <Table.Cell>
        <Segment inverted>
          {sensors.map((sensor: SensorContents, index: number) => (
            <Table.Cell key={index}>
              <Sensor sensor={sensor} />
            </Table.Cell>
          ))}
        </Segment>
      </Table.Cell>
      <Table.Cell>
        <Dropdown item direction="left" icon="ellipsis horizontal">
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(e) => {
                setPickedAlarm(alarm);
                if (sl) {
                  console.log('disconnecting alarm');
                  alarmListStore.showDisconnectAlarm(true);
                } else {
                  alarmListStore.showConnectAlarm(true);
                }
              }}
            >
              <Icon name={sl ? 'broken chain' : 'chain'} />
              {sl ? 'Disconnect' : 'Connect placement'}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                setPickedAlarm(alarm);
                alarmListStore.showEditAlarm(true);
              }}
            >
              <Icon name="pencil" />
              Edit alarm
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  );
};

interface AlarmSlItem {
  cage: CageContents;
  sl: SensorLocationContents;
  alarm: AlarmContents;
}

const AlarmList = observer(({ cage }: AlarmListProps) => {
  const { siteStore, authStore } = useStore();
  const { alarmListStore } = useAlarmListStore();
  const [pickedAlarm, setPickedAlarm] = useState<AlarmContents>();
  const { data: alarms, error } = useSWR('/api/v1/alarm', (url) => AuthFetcher(url, authStore.getTokenFunction()));
  const siteKey = siteStore.getSiteKey();
  console.log(error);
  if (!cage) {
    return <Loader active />;
  }

  console.log('Alarms', alarms);
  const assingedAlarms: AlarmSlItem[] = [];
  cage.sensor_locations.forEach((sl: SensorLocationContents) =>
    assingedAlarms.push(
      ...sl.scenarios.map((a: AlarmContents) => {
        return { cage: cage, sl: sl, alarm: a };
      })
    )
  );
  assingedAlarms.sort((a: AlarmSlItem, b: AlarmSlItem) => {
    if (a.sl.node_key === b.sl.node_key) {
      return a.alarm.node_key > b.alarm.node_key ? 1 : -1;
    }
    return a.sl.node_key > b.sl.node_key ? 1 : -1;
  });
  if (error) {
    return <Message negative>Unable to load alarms</Message>;
  }
  if (!alarms) {
    return <Loader active />;
  }
  console.log(alarms);
  console.log(assingedAlarms);

  cage.sensor_locations.sort((a: SensorLocationContents, b: SensorLocationContents) => {
    let segmentA = getSensorSegment(a.meta);
    let segmentB = getSensorSegment(b.meta);
    if (a.meta.max_depth === b.meta.max_depth) {
      return segmentA.segmentNr > segmentB.segmentNr ? 1 : -1;
    } else {
      return a.meta.max_depth > b.meta.max_depth ? 1 : -1;
    }
  });
  return (
    <>
      {/* <Segment style={{ background: '#0E1A2B', padding: '15px', borderRadius: '5px',width:'100%' }}> */}
      <Table celled inverted structured color="blue">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Alarm</Table.HeaderCell>
            <Table.HeaderCell>Trigger points</Table.HeaderCell>
            <Table.HeaderCell>Placement</Table.HeaderCell>
            <Table.HeaderCell>Related Sensors</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="5">Assigned alarms</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {assingedAlarms.map((sla: AlarmSlItem) => {
            let status: ScenarioStatus | undefined = sla.sl.scenario_status.find(
              (s: ScenarioStatus) => s.node_key === sla.alarm.node_key
            );
            return <Alarm alarm={sla.alarm} status={status} sl={sla.sl} setPickedAlarm={setPickedAlarm} />;
          })}
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="5">All alarms</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {alarms && alarms.map((alarm: AlarmContents) => <Alarm alarm={alarm} setPickedAlarm={setPickedAlarm} />)}
        </Table.Body>
        <Table.Header>
          <Table.Row key="add_sensor">
            <Table.HeaderCell
              colSpan="5"
              onClick={() => {
                alarmListStore.showCreateAlarm(true);
              }}
            >
              <Icon name="plus circle" color="green" />
              Create Alarm
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <CreateAlarmDialogue />
      <ConnectAlarmDialogue siteKey={siteKey} alarm={pickedAlarm} cage={cage} cageName={cage.name} />
      <DisconnectAlarmDialogue siteKey={siteKey} alarm={pickedAlarm} cage={cage} />
      <EditAlarmDialogue siteKey={siteKey} alarm={pickedAlarm} />
    </>
  );
});

export default AlarmList;

import * as THREE from 'three';
import { extend, ReactThreeFiber } from '@react-three/fiber';

export class OceanMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        oceanColor: { value: [0.0, 0.0, 1.0, 1.0] },
        waveNormals: { value: undefined },
      },
      vertexShader: `varying vec3 vColor;

                varying float vY;
            
                void main()
                {
                    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                    vY = worldPosition.y;
                    gl_Position = projectionMatrix * viewMatrix * worldPosition;
                }`,
      fragmentShader: `precision mediump float;

                varying float vY;

                uniform vec4 oceanColor;
                uniform sampler2D waveNormals;

                void main()
                {
                    gl_FragColor = oceanColor;
                }`,
    });
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      oceanMaterial: ReactThreeFiber.Object3DNode<OceanMaterial, typeof OceanMaterial>;
    }
  }
}

extend({ OceanMaterial });

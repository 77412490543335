import { Segment } from "semantic-ui-react";
import useStore from '../Store/Store';

const PrivacyPolicy = () => {
  const { appStore } = useStore();
  appStore.setBreadcrumb('Privacy Policy');
  return (
    <Segment>
      <h1>CageSense&trade; Privacy Policy</h1>
      <p>
        This privacy statement details how CageSense&trade; collects and uses visitor information.</p>
      <p>
        The statement contains information that you are entitled to when collecting information from CageSense&trade;, and general information about how we treat personal data.
      </p>
      <p>The legal owner of the website is the processing officer for the processing of personal data.  The treatment basis is the consent of the individual, unless otherwise specified.</p>
      <p>CageSense&trade; uses two sorts of cookies, for login and managing authentication and web analytics for improving the user experience and understanding user patterns.</p>
      <h2>Login and Authentication</h2>
      <p>
        Login and authentication to CageSense&trade; is handled via Auth0, a third-party, and the information received is subject
        to the <a
          href="https://www.okta.com/privacy-policy/"
          rel="noreferrer"
          target='_blank'
        >
          Okta Privacy Policy.
        </a>
      </p>
      <h2>Web Analytics and Cookies</h2>
      <p>
        As an important part of the effort to create a user-friendly website, we look at the user pattern of those who login to CageSense&trade;.
      </p>
      <p>
        To analyze the information, we use the Google Analytics analysis tool.
        Google Analytics uses cookies (small text files that the site stores on the user's computer), which registers the users' IP address and provides information about the individual user's online movements.
      </p>
      <p>
        Examples of what the statistics give us answers to are:
        <ul>
          <li>how many people visit different pages</li>
          <li>how long the visit lasts</li>
          <li>what websites users come from</li>
          <li>what OS and browsers are used</li>
        </ul>
      </p>
      <p>None of the cookies allow us to link information about your use of the site to you as an individual.</p>
      <p>
        The information collected by Google Analytics is stored on Google servers in the U.S.
        The information received is subject to the Google Privacy Policy.
      </p>
      <p>
        An IP address is defined as a personal information because it can be traced back to a particular hardware and thus to an individual. We use Google Analytics's tracking code to anonymize the IP address before the information is stored and processed by Google. Thus, the stored IP address can not be used to identify the individual user.
      </p>
      <h2>Support</h2>
      <p>
        Do you have any questions? Contact us at <a href="mailto:post@waterlinked.com">post@waterlinked.com</a><div className=""></div></p>
    </Segment>
  )
}

export default PrivacyPolicy
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { mutate } from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { getSensorDepth, getSensorSegment } from '../../lib/format';
import { AlarmContents, CageContents, SensorLocationContents } from '../../lib/types';
import useStore from '../../Store/Store';
import NotyfContext from '../NotyfContext';
import useAlarmListStore from './AlarmListStore';

interface DisconnectAlarmDialogueProps {
  siteKey: string | undefined;
  cage: CageContents;
  alarm: AlarmContents | undefined;
}
const makeContentForDisconnectingSensor = (
  alarm: AlarmContents | undefined,
  sl: SensorLocationContents | undefined
) => {
  if (!sl) {
    return '';
  }

  let placement: string = sl ? getSensorDepth(sl.meta) + ' ' + getSensorSegment(sl.meta)?.label : '';
  return 'Are you sure you want to disconnect Alarm ' + _.capitalize(alarm?.name) + ' from ' + placement;
};

const DisconnectAlarmDialogue = observer(({ siteKey, alarm, cage }: DisconnectAlarmDialogueProps) => {
  const notyf = useContext(NotyfContext);
  const { alarmListStore } = useAlarmListStore();
  const { authStore } = useStore();
  let sl: SensorLocationContents | undefined = cage.sensor_locations.find((sl: SensorLocationContents) => {
    return sl.scenarios.find((a: AlarmContents) => a.node_key === alarm?.node_key) !== undefined;
  });
  const unassign = () => {
    AuthFetcher(
      '/api/v1/delete/connection/' + sl?.node_key + '/SensorLocation/' + alarm?.node_key + '/Alarm',
      authStore.getTokenFunction(),

      { method: 'DELETE' }
    )
      ?.then((res) => {
        console.log(siteKey);
        mutate(`/api/v1/site/${siteKey}`);
        mutate(`/api/v1/alarm`);
        res.error ? notyf.error('Could not disconnect alarm') : notyf.success('Successfully disconnected alarm');
        alarmListStore.showDisconnectAlarm(false);
      })
      .catch((err) => {
        notyf.error('Could not disconnect alarm.');
        alarmListStore.showDisconnectAlarm(false);
      });
  };

  return (
    <Modal open={alarmListStore.disconnectAlarm} size="tiny" dimmer="blurring" className="blue">
      <Modal.Header style={{ color: 'black' }}>Are you sure?</Modal.Header>
      <Modal.Content>{makeContentForDisconnectingSensor(alarm, sl)}</Modal.Content>
      <Modal.Actions>
        <Button onClick={(e) => alarmListStore.showDisconnectAlarm(false)} negative>
          Cancel
        </Button>
        <Button onClick={(e) => unassign()} positive>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default DisconnectAlarmDialogue;

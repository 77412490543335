import React, { useState } from 'react';
import { Divider, Message, Segment, Icon, Header, Form, TextArea, Button, Popup, ButtonGroup } from 'semantic-ui-react';

const defaultCollectionTime: number = 15;

interface selectDurationProps {
  duration: number;
  onSelectDuration: React.Dispatch<React.SetStateAction<number>>;
}

export default function DiagnosticReport() {
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState(defaultCollectionTime);

  const onChange = (e: any) => {
    setDescription(e.target.value);
  };

  return (
    <Segment attached>
      <Divider horizontal>Diagnostic report</Divider>
      <Message info icon>
        <Icon name="eye" />
        <Message.Content>
          <Message.Header>Generate report for analysis by Water Linked support</Message.Header>
          <div>
            <p>Please make sure the sensor is in water and showing the behaviour you want assistance with.</p>
            <div>
              Clicking the button below initiates a&nbsp;
              <SelectDuration duration={duration} onSelectDuration={setDuration} />
              &nbsp;second data capture to share with the support team.
            </div>
          </div>
        </Message.Content>
      </Message>

      <Form action={'/api/v1/collect'}>
        <input type="hidden" name="desc" value={description} />
        {duration !== defaultCollectionTime && <input type="hidden" name="t" value={duration} />}
        <Header as="h5">Describe your setup and what you you are experiencing</Header>
        <Form.Field>
          <TextArea placeholder="Type details here" value={description} onInput={onChange} rows={4} />
        </Form.Field>
        <Form.Field>
          <Button disabled={!description}>Generate and download report</Button>
        </Form.Field>
      </Form>
    </Segment>
  );
}

function SelectDuration(props: selectDurationProps) {
  return (
    <Popup
      flowing
      on="click"
      trigger={<span>{props.duration}</span>}
      content={
        <ButtonGroup>
          <Button
            onClick={() => {
              props.onSelectDuration(15);
            }}
          >
            15 second
          </Button>
          <Button
            onClick={() => {
              props.onSelectDuration(30);
            }}
          >
            30 second
          </Button>
          <Button
            onClick={() => {
              props.onSelectDuration(60);
            }}
          >
            1 min
          </Button>
          <Button
            onClick={() => {
              props.onSelectDuration(60 * 5);
            }}
          >
            5 min
          </Button>
        </ButtonGroup>
      }
    />
  );
}

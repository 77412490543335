import { Link } from 'react-router-dom';
import { Sidebar, Menu, Divider, Icon } from 'semantic-ui-react';
import LogoutButton from './Components/LogoutButton';
import useStore from './Store/Store';
import { version } from '../package.json'

interface SidebarMenuProps {
  showLoginLogout: boolean;
  showAdmin: boolean;
  menuVisible: boolean;
  breadCrumb: string;
  setBreadCrumb: any;
  setMenuRightVisible: any;
}

const SidebarMenu = ({
  showLoginLogout,
  showAdmin,
  menuVisible,
  breadCrumb,
  setMenuRightVisible,
}: SidebarMenuProps) => {
  const { appStore, siteStore } = useStore();
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuRightVisible(false);
    appStore.setBreadcrumb((event.target as HTMLInputElement).innerText)
  };
  return (
    <Sidebar
      as={Menu}
      direction="left"
      animation="push"
      width="thin"
      visible={menuVisible}
      vertical
      inverted={true}
      color="blue"
      style={{ top: '60px' }}
    >
      {/* Sidebar logic for everything else */}
      <Menu.Item
        key={'overview-map'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'Overview Map'}
        to={'/'}
        color="green"
        className='my-1'
      >
        Overview Map
        <Icon name="map" />
      </Menu.Item>
      <Menu.Item
        key={'dashboard'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'My Site'}
        to={'/dashboard/' + siteStore.getSiteKey()}
        color="green"
        className='my-1'
      >
        My Site
        <Icon name="point" />
      </Menu.Item>
      {showAdmin && (
        <Menu.Item
          key={'site-edit'}
          as={Link}
          onClick={onClick}
          active={appStore.getBreadcrumb() === 'Site Edit'}
          to={'/siteedit/' + siteStore.getSiteKey()}
          color="green"
          className='my-1'
        >
          Site Edit
          <Icon name="pencil" />
        </Menu.Item>
      )}
      <Menu.Item
        key={'analytics'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'Analytics'}
        to={'/analytics/' + siteStore.getSiteKey()}
        className='my-1'
      >
        Analytics
        <Icon name="database" />
      </Menu.Item>
      <Divider />
      {showAdmin && (
        <Menu.Item
          key={'admin'}
          as={Link}
          onClick={onClick}
          active={appStore.getBreadcrumb() === 'Admin'}
          to="/admin"
          className='my-1'
        >
          Admin
          <Icon name="user secret" />
        </Menu.Item>
      )}
      {
        /*Disabled since it does not work for 2.0.0*/ false && showAdmin && (
          <Menu.Item
            key={'sensor-config'}
            as={Link}
            onClick={onClick}
            active={breadCrumb === 'Sensor Config'}
            to={'/sensorconfig/' + siteStore.getSiteKey()}
          >
            Sensor Config
          </Menu.Item>
        )
      }
      {showAdmin && (
        <Menu.Item
          key={'diagnostics'}
          as={Link}
          onClick={onClick}
          active={appStore.getBreadcrumb() === 'Diagnostics'}
          to={'/diagnostic/' + siteStore.getSiteKey()}
          className='my-1'
        >
          Diagnostics
          <Icon name="lab" />
        </Menu.Item>
      )}
      <Menu.Item
        key={'welcome'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'First time setup'}
        to={'/welcome'}
        className='my-1'
      >
        First time setup
        <Icon name="life ring" />
      </Menu.Item>
      <Menu.Item
        key={'help'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'Help'}
        to={'/help'}
        className='my-1'
      >
        Help
        <Icon name="question circle" />
      </Menu.Item>
      <Menu.Item
        key={'about'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'About'}
        to={'/about/' + siteStore.getSiteKey()}
        className='my-1'
      >
        About
        <Icon name="info circle" />
      </Menu.Item>
      <Menu.Item
        key={'privacy-policy'}
        as={Link}
        onClick={onClick}
        active={appStore.getBreadcrumb() === 'Privacy Policy'}
        to={'/privacy'}
        className='my-1'
      >
        Privacy Policy
        <Icon name="file text" />
      </Menu.Item>
      {showLoginLogout && <LogoutButton />}
      <div className='text-white w-100 text-center version position-absolute text-sm'>
        v{version}
      </div>
    </Sidebar>
  );
};

export default SidebarMenu;

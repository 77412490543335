import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { DropdownProps, Dropdown, Icon, Header, Loader } from 'semantic-ui-react';
import { AuthFetcher } from '../../lib/fetch';
import { findHighestAlarmStatus } from '../../lib/functionality';
import NotyfContext from '../NotyfContext';
import useSWR from 'swr';

import useStore from '../../Store/Store';
import { useContext } from 'react';

interface DataSiteObject {
  default: boolean;
  name: string;
  node_key: string;
  topside_id: string;
}
interface SiteOptionsObject {
  key: string;
  text: string;
  value: string;
  content: any;
}

const findCodeColorFromStatus = (status: number) => {
  switch (status) {
    case -1:
      return 'grey';
    case 0:
      return 'green';
    case 1:
      return 'yellow';
    case 2:
      return 'red';
    default:
      return 'grey';
  }
};

const findStatusCodeFromStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'OK';
    case 1:
      return 'Alarm: Warning';
    case 2:
      return 'Alarm: Critical';
    default:
      return '';
  }
};

interface SiteOptionProps {
  site: DataSiteObject;
}
const SiteOption = ({ site }: SiteOptionProps) => {
  const notyf = useContext(NotyfContext);
  const { authStore } = useStore();
  const { data: slAlarmStatuses, error } = useSWR(
    '/api/v1/alarm/site/' + site.node_key,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {}
  );
  const { data: siteStatus, error: siteStatusError } = useSWR(
    `/api/v1/status/${site.topside_id}`,
    (url) => AuthFetcher(url, authStore.getTokenFunction()),
    {}
  );

  if (error) {
    notyf.error(`Error while fetching alarms: ${error}`);
  }

  if (siteStatusError) {
    notyf.error(`Error while fetching site status: ${siteStatusError}`);
  }

  if (!slAlarmStatuses) {
    return <Loader active></Loader>;
  }
  var highestAlarmValue = findHighestAlarmStatus(slAlarmStatuses);

  return (
    <Header as="h4">
      <Header.Content>
        {site.name + ' (' + site.topside_id + ')'}
        <Header.Subheader className="text-white">
          <p>
            Cloud
            {siteStatus ? (
              <span className='ms-1'>
                <Icon
                  name={siteStatus?.topside_status?.cloud?.status === true ? 'check' : 'warning sign'}
                  color={siteStatus?.topside_status?.cloud?.status === true ? 'green' : 'red'}
                />
              </span>
            ) : (
              <span className="mx-1">No data</span>
            )}
            <span style={{ color: findCodeColorFromStatus(highestAlarmValue) }}>
              {error ? 'Error' : findStatusCodeFromStatus(highestAlarmValue)}
            </span>
          </p>
        </Header.Subheader>
      </Header.Content>
    </Header>
  );
};
const SiteSelect = observer(() => {
  const history = useHistory();
  const { appStore, siteStore } = useStore();
  let siteOptions: SiteOptionsObject[] = [];
  const sites = siteStore.getSites() || [];
  if (!sites) {
    console.error('Unable to fetch sites');
  } else if (sites && sites.length) {
    // Go through each site and make them into items that can be used in a dropdown
    sites.forEach((site) => {
      siteOptions.push({
        key: site.node_key,
        text: site.name,
        value: site.node_key,
        content: <SiteOption site={site} />,
      });
    });
  }

  const onCurrentSiteChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const site = sites ? sites.find((site) => site.node_key === data.value) : undefined;

    if (site) {
      siteStore.setSiteKey(site.node_key);
    }
  };

  return (
    <Dropdown
      placeholder='Select a site'
      className='text-bold d-flex align-items-center justify-content-between'
      fluid
      value={siteStore.getSiteKey()}
      style={{
        width: 200,
        textAlign: 'center',
        marginTop: '5px'
      }}
      options={[
        ...siteOptions,
        {
          key: 'site-overview-item',
          className: 'm-0 p-0',
          children: (
            <Dropdown.Item
              onClick={() => {
                appStore.setBreadcrumb('Overview Map')
                history.push('/')
              }}
              key='overview-map'
              className="m-0"
              content={
                <>
                  <Icon name="chevron left" />
                  Overview Map
                </>
              }
            />
          )
        }
      ]}
      onChange={onCurrentSiteChange}
      trigger={
        <div className='p-1 m-0'>
          {sites.find((site) => site.node_key === siteStore.getSiteKey())?.name || 'Select site'}
        </div>
      }
    >
    </Dropdown>
  );
});
export default SiteSelect;

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid } from 'semantic-ui-react';
import { version } from '../../package.json'
import '../css/Login.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="text-center login vh-100 mx-auto">
      <Grid className="text-blue p-3 rounded raised vh-100">
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <div className="bg-deep-blue text-white p-3 rounded">
              <h1 className="mb-3">
                <img src="/images/three-circles.svg" width={8} alt="Three dots" className="me-3" />
                CageSense&trade;
              </h1>
              <Button className="bg-light-green p-3 mt-1" onClick={() => loginWithRedirect()} fluid size="large">
                <span className="text-blue">Login</span>
              </Button>
            </div>
            <div className="text-right">
              <img
                src="/images/wl-logo-white.png"
                alt="Waterlinked Logo"
                className="mt-1"
                width={140}
              />
            </div>
            <div className='text-white text-right'>
                v{version}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default LoginButton;

import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { SiteContents } from '../../lib/types';

export class AlarmListStore {
  createAlarm: boolean = false;
  editAlarm: boolean = false;
  connectAlarm: boolean = false;
  disconnectAlarm: boolean = false;
  site: SiteContents | undefined = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  showCreateAlarm(b: boolean) {
    console.log('this happens');
    this.createAlarm = b;
  }

  showEditAlarm(b: boolean) {
    this.editAlarm = b;
  }

  showConnectAlarm(b: boolean) {
    this.connectAlarm = b;
  }

  showDisconnectAlarm(b: boolean) {
    this.disconnectAlarm = b;
  }

  setSite(site: SiteContents | undefined) {
    this.site = site;
  }
}

const alarmListContext = createContext({ alarmListStore: new AlarmListStore() });

const useAlarmListStore = () => useContext(alarmListContext);
export default useAlarmListStore;

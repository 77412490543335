import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';
import useStore from '../../Store/Store';
const Checklist = () => {
  const { firstTimeSetupStore } = useStore();
  useEffect(() => {
    const currentStep = firstTimeSetupStore.getCurrentStep();
    firstTimeSetupStore.setStepComplete(currentStep);
  });

  return (
    <div>
      <img src="/images/first-time-setup-intro.jpg" alt="Step 1 - Checklist" className="w-100 rounded" />
      <p className="my-2">Make sure you have the minimum required components before you begin:</p>
      <Grid className='my-3'>
        <Grid.Row>
          <Grid.Column
            computer={8}
            mobile={16}
            className='d-flex align-items-center'
          >
            <img src="/images/cagesense-gateway.svg"
              width={100}
              alt='Checklist item'
              className='me-2'
            />
            1 x CageSense&trade; Gateway
          </Grid.Column>
          <Grid.Column
            computer={8}
            mobile={16}
            className='d-flex align-items-center'
          >
            <img src="/images/receiver-cable.svg"
              width={100}
              alt='Checklist item'
              className='me-2'
            />
            2 x CageSense&trade; Receiver cables
          </Grid.Column>
        </Grid.Row>
        <Grid.Column
          computer={8}
          mobile={16}
          className='d-flex align-items-center'
        >

          <img src="/images/sensors.svg"
            width={100}
            alt='Checklist item'
            className='me-2'
          />
          1 x CageSense&trade; Sensor
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={16}
          className='d-flex align-items-center'
        >

          <img src="/images/sensor-charge-kit.svg"
            width={100}
            alt='Checklist item'
            className='me-2'
          />
          1 x CageSense&trade; Sensor Charge Kit
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={16}
          className='d-flex align-items-center'
        >
          <img src="/images/ethernet-cable.svg"
            width={100}
            alt='Checklist item'
            className='me-2'
          />
          1 x Ethernet Cable
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={16}
          className='d-flex align-items-center'
        >
          <img src="/images/computer.svg"
            width={100}
            alt='Checklist item'
            className='me-2'
          />
          A computer with Internet connection
        </Grid.Column>
      </Grid>
      <Message
        color='blue'
        className='mt-3'
      >
        Make sure the CageSense&trade; Gateway is connected to power before continuing.
      </Message>
    </div>
  );
};

export default Checklist;

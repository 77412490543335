// Placeholders acts as content replacements until content is ready (usually until something has finished loading)
import { Grid, Placeholder, Segment, SemanticWIDTHS } from 'semantic-ui-react';

const Placeholders = ({
  numberOfColumns,
  numberOfCards,
}: {
  numberOfColumns: SemanticWIDTHS;
  numberOfCards: number;
}) => {
  return (
    <Grid columns={numberOfColumns}>
      {[...Array(numberOfCards)].map((e: any, k: number) => {
        return (
          <Grid.Column key={`placeholder-${k}`}>
            <Segment raised>
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line length="medium" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="long" />
                  <Placeholder.Line length="long" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

export default Placeholders;

import { useState } from 'react';
import { Image } from 'semantic-ui-react';

import { getColor } from '../../lib/colors';
import sensorImg from '../../sensorNew.svg';

interface SensorIconProps {
  sensorType: string;
  scale?: number;
  rotation?: number,
  top?: string;
  marginLeft?: string;
}

const SensorIcon = ({ sensorType, scale = 0.4, rotation = 90, top = '4px', marginLeft = '20px' }: SensorIconProps) => {
  const [visible, setVisible] = useState(true);

  return sensorType ? (
    <div
      onClick={() => setVisible(!visible)}
      style={{
        zIndex: 1,
        width: 0,
        marginLeft: marginLeft,
        height: 0,
        top: top,
        cursor: 'none',
        transform: `scale(${scale}) rotate(${rotation}deg)`,
      }}
    >
      <Image
        src={sensorImg}
        style={{ width: '25px', position: 'absolute', top: '-20px', left: '-10px', maxWidth: '30px' }}
      />

      <svg
        width="25px"
        height="25px"
        style={{ position: 'relative', top: '0px', left: '-10px' }}
      >
        <rect
          // onClick={PlaceFeedingBarge}
          width="25px"
          height="13px"
          fill={getColor(sensorType)}
          rx="5"
          // ref={bargeRef}
          x={0}
          y={0}
        />
      </svg>
    </div>
  ) : null;
};

export default SensorIcon;

import { observer } from 'mobx-react';
import { Segment, Divider } from 'semantic-ui-react';
import CorrelatorGraphManual from '../Components/Diagnostics/CorrelatorGraphManual';
import DiagnosticReport from '../Components/Diagnostics/DiagnosticReport';
import MessageList from '../Components/Diagnostics/SensorMessages';
import useStore from '../Store/Store';

interface DiagnosticProps {
  match?: any;
  spectrumUrl: string;
}

const Diagnostic = observer(({ spectrumUrl, match }: DiagnosticProps) => {
  const { appStore, siteStore } = useStore();
  appStore.setBreadcrumb('Diagnostic');
  const siteKey = match?.params?.siteId ? match.params.siteId : siteStore.getSiteKey();
  let spectrumUrlDefined = spectrumUrl;
  if (siteKey && siteKey !== '') {
    spectrumUrlDefined = spectrumUrl + '/' + siteKey;
  }
  return (
    <Segment>
      <Divider horizontal>Spectrum</Divider>
      <CorrelatorGraphManual url={spectrumUrlDefined} />

      <Divider horizontal>Latest sensor messages</Divider>
      <MessageList />
      <DiagnosticReport />
    </Segment>
  );
});

export default Diagnostic;

import '../css/Welcome.css';
import { Icon, Step, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { Splash } from '../Components/Welcome/Splash';
import { StepIndicators } from '../Components/Welcome/StepIndicators';
import { StepContent } from '../Components/Welcome/StepContent';
import { stepInterface } from '../lib/types';

// Components for each step
import Checklist from '../Components/Welcome/Checklist';
// Connect Gateway to internet
import SetupInternet from '../Components/Welcome/SetupInternet';
// Add sites
import SetupSite from '../Components/Welcome/SetupSite';
// Connect receivers
import SetupReceivers from '../Components/Welcome/SetupReceivers';
// Add sensors
import SensorSetup from '../Components/Welcome/SetupSensors';
// Complete Setup
import SetupComplete from '../Components/Welcome/SetupComplete';

import useStore from '../Store/Store';
import Footer from '../Components/Welcome/Footer';

const allSteps: Array<stepInterface> = [
  {
    icon: 'list',
    heading: 'Checklist',
    completed: 0,
    component: Checklist,
  },
  {
    icon: 'wifi',
    heading: 'Internet Connection',
    completed: 0,
    component: SetupInternet,
  },
  {
    icon: 'map marker',
    heading: 'Add your site',
    completed: 0,
    component: SetupSite,
  },
  {
    icon: 'plug',
    heading: 'Connect receivers',
    completed: 0,
    component: SetupReceivers,
  },
  {
    icon: 'podcast',
    heading: 'Connect sensors',
    completed: 0,
    component: SensorSetup,
  },
  {
    icon: 'thumbs up outline',
    heading: 'Setup complete!',
    completed: 0,
    component: SetupComplete,
  },
];

/**
 * Displays the steps (left side) of the welcome screen
 *
 * @param currentStep {number} - The current step in the setup process
 * @returns mixed {Jsx.element}
 */
function Steps({ currentStep }: { currentStep: number }) {
  const stepItems = allSteps.map((step, stepIndex) => {
    // Check whether the step has been completed already
    const stepFinished = allSteps[stepIndex].completed === 1;
    return (
      <Step active={stepIndex === currentStep} key={'step-' + stepIndex}>
        <Icon name={stepFinished ? 'check' : step.icon} color={stepFinished ? 'green' : 'black'}></Icon>
        <Step.Content>
          <Step.Title>{step.heading}</Step.Title>
        </Step.Content>
      </Step>
    );
  });
  return (
    <Step.Group vertical size="tiny" className="w-100">
      {stepItems}
    </Step.Group>
  );
}

/**
 * Utility computed property for figuring out if all initial setup steps have been completed
 *
 * @param currentStep {Number} - The current step in the setup process
 * @returns boolean {boolean}
 */
function allStepsCompleted(currentStep: Number) {
  return currentStep === allSteps.length - 1;
}

/**
 * Displays the main layout for the welcome screen, which uses sub-components
 *
 * @returns {Jsx.element} - JSX Component
 */
const Welcome = observer(() => {
  const { firstTimeSetupStore } = useStore();
  const currentStepStatus = firstTimeSetupStore.getCurrentStepStatus();
  const setupStarted = firstTimeSetupStore.getSetupStarted();
  let nextButton = <></>;
  let previousButton = <></>;
  const currentStep = firstTimeSetupStore.getCurrentStep();
  if (!allStepsCompleted(currentStep) && setupStarted) {
    nextButton = (
      <Button color="green" disabled={!currentStepStatus} onClick={() => firstTimeSetupStore.navigate('next')}>
        <span className="text-blue">Continue</span>
        <Icon name="chevron right" className="text-blue" />
      </Button>
    );
  }
  if (currentStep > 0) {
    previousButton = (
      <Button color="grey" onClick={() => firstTimeSetupStore.navigate('prev')}>
        <Icon name="chevron left" />
        Back
      </Button>
    );
  }
  return (
    <div className="welcome rounded mt-3 mx-auto">
      <Grid className="mx-2">
        {
          // Left-side step column
          setupStarted === true && (
            <Grid.Column className="step-list bg-white computer only p-0" computer={4}>
              <Steps currentStep={currentStep} />
            </Grid.Column>
          )
        }
        <Grid.Column
          className={`page-content ' ${setupStarted ? 'bg-white shadow' : ''}`}
          computer={setupStarted === true ? 12 : 16}
          mobile={16}
        >
          {setupStarted ? (
            <div className="bg-white">
              <StepContent currentStep={currentStep} allSteps={allSteps} />
            </div>
          ) : (
            <div className="mb-3">
              <Splash />
            </div>
          )}
        </Grid.Column>
        {setupStarted && (
          <div className="mt-3 mx-auto computer only text-center">
            {previousButton}
            {nextButton}
            <div className="mt-3">
              <StepIndicators />
            </div>
          </div>
        )}
      </Grid>

      <Footer />
    </div>
  );
});

export default Welcome;

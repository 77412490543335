import '../css/Analytics.css';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Checkbox, Dropdown, Grid, Icon, Label, Menu, Message, Popup, Segment, Sticky } from "semantic-ui-react"
import { GetAnalyticViews, SiteDetailsFetcher } from '../lib/fetch'
import useStore from "../Store/Store"
import moment from 'moment';
// The component that displays a single graph for one sensor's reading
import SingleGraph from '../Components/Analytics/SingleGraph'
// The component that displays grouped graphs for one type of sensor reading (i.e oxygen)
import GroupedByTypeGraph from '../Components/Analytics/GroupedByTypeGraph'
// The graph option segment with things like selection for data points and grid toggle
import GraphOptions from '../Components/Analytics/GraphOptions';
import MessageBox from '../Components/Message';
import ViewManager from '../Components/Analytics/ViewManager';

/**
 * Computed result of the relation between sizes in text form and that corresponding grid column width
 * @param graph_size - string that should be small, medium or large
 * @returns number - Width to use for the grid columns
 */
const computedGraphSize = (graph_size: string) => {
  const size = graph_size.toLowerCase()
  switch (size) {
    case 'small':
      return 4
    case 'medium':
      return 8
    case 'large':
      return 16
    default:
      return 8
  }
}

/**
 * Iterates each sensor in a sensor location (assigned or unassigned) and displays their respective measurements
 */
const SensorLocationItems = observer(({ location, locationIndex, cageName }: { location: any, locationIndex: number, cageName: string }) => {
  const { analyticsStore } = useStore()
  return (
    <div>
      <h5
        key={`${location.name}-${locationIndex}`}
        className='ms-2 text-capitalize'
      >
        {location.name}
      </h5>
      {location?.sensors?.map((sensor: any, sensorIndex: number) => {
        return (
          <div
            key={`cage-${locationIndex}-${sensorIndex}`}
            className={
              `mb-1 ${analyticsStore.isSensorAndMeasurementSelected(sensor.sensor_id, sensor.sensor_type, sensor.measurement_type) > -1 ? 'bg-dark-green' : ''}`
            }
          >
            <div className='d-flex align-items-center text-capitalize p-1 ms-1 hover-intent-background'>
              <img
                src={`/images/sensor-${sensor.sensor_type}.svg`}
                alt="illustration icon for sensor"
                width={15}
                className="me-2"
              />
              <div>
                <div className="text-bold">
                  {sensor.name} - ID: {sensor.sensor_id}
                </div>
                <div className="text-italic">
                  {location.name}
                </div>
              </div>
            </div>
            {/* Measurement types for each sensor */}
            <div className='ms-3 p-1'>
              {/* The measurement order may be different, so sort them alphabetically so they display uniformly */}
              {sensor?.measurements?.sort((a: any, b: any) => (a < b ? -1 : 1))?.map((measurement: string, measurementIndex: number) => {
                return (
                  <Checkbox
                    key={`cage-${cageName}-sensor-measurement-${measurement}-${measurementIndex}`}
                    label={<label className='text-white'>{measurement}</label>}
                    className='text-capitalize me-2 text-sm'
                    onChange={(e, data) => analyticsStore.toggleSensor(sensor, measurement, data.checked, location.meta, cageName)}
                    toggle
                    checked={analyticsStore.isSensorAndMeasurementSelected(sensor.sensor_id, sensor.sensor_type, measurement) > -1}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
})
/**
 * Renders the sidebar menu with all sensors for the currently selected site,
 * grouped by what cage they belong to.
 * @param siteData - Array of site and cage data with sensor locations
 * @param viewData - Object holding info on the current active view and the settings in that
 * @returns ReactElement
 */
const SidebarMenu = observer(({ siteData, viewData }: { siteData: any, viewData: any }) => {
  const { analyticsStore } = useStore()
  const [activeIndex, setActiveIndex] = useState<any>(0)

  /**
  * We use this to determine whether the unassigned category is open/closed.
  * This value is outside the range of the other cage indexes.
  */
  const unassignedSensorsIndex = siteData.cages.length + 1

  const toggleCategory = (index: any) => {
    if (index === activeIndex) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }
  /*
  * Filter only sensor locations (depths) that have sensors present 
  * and cache the result (since potentially expensive operation)
  */
  const assignedSensors = useMemo(
    () => {
      siteData.cages.map((cage: any, index: number) => {
        return siteData.cages[index].sensor_locations = cage?.sensor_locations?.filter((f: any) => f.sensors.length)
      })
      return {
        name: siteData.name,
        cages: siteData.cages
      }
    }, [siteData]
  )

  const unassignedSensors = useMemo(
    () => {
      /**
      * The assigned sensors have the sensors attribute, however the unassigned ones do not.
      * In order to use a common component to list them both, the structure needs to be the same.
      */
      return {
        sensors: siteData?.unassigned_sensors,
        meta: {
          type: 'Unassigned',
          max_depth: 0,
          min_depth: 0,
          left_border: 0,
          right_border: 0
        }
      }
    }, [siteData]
  )
  // Does the current active site have cages present?
  if (assignedSensors?.cages?.length) {
    return (
      <Sticky active>
        <Menu attached='top'>
          <div className='position-fixed top-right bg-dark-blue text-white h-100 w-25 py-2 shadow overflow-y-scroll'>
            <h3
              className='d-flex align-items-center ms-2'
            >
              My sensors
              {/* Show a clear selection button only if there are sensors selected */}
              {viewData?.sensors?.length > 0 && (
                <Popup
                  key={`button-clear-all-sensors`}
                  trigger={
                    <Button
                      className='position-absolute top-right mt-1'
                      color='black'
                      size='small'
                      onClick={() => { analyticsStore.setSelectedSensors([]) }}
                    >
                      <Icon name='trash' />
                      Clear all
                    </Button>
                  }
                  content='Clear all selected sensors'
                  position='bottom left'
                />
              )}
            </h3>
            {/* Iterate each cage */}
            <Accordion
              fluid
            >
              {/* List the assigned sensors to a cage */}
              {assignedSensors.cages.map((cage: any, cageIndex: number) => {
                return (
                  <div key={`cage-${cageIndex}`}>
                    <Accordion.Title
                      active={activeIndex === cageIndex}
                      onClick={() => toggleCategory(cageIndex)}
                    >
                      <h5 className='bg-blue text-white p-2 d-flex'>
                        <div>
                          <Icon
                            name={activeIndex === cageIndex ? 'chevron up' : 'chevron down'}
                          />
                          {cage.name}
                        </div>
                        <div className='ms-auto'>
                          {cage?.sensor_locations.length} sensors
                        </div>
                      </h5>
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeIndex === cageIndex}
                    >
                      <div className='text-sm'>
                        {/* List sensors and their measurements in each cage */}
                        {cage?.sensor_locations.map((location: any, locationIndex: any) => {
                          return <SensorLocationItems
                            key={`sensor-location-$${locationIndex}`}
                            location={location}
                            locationIndex={locationIndex}
                            cageName={cage.name}
                          />
                        })}
                      </div>
                    </Accordion.Content>
                  </div>
                )
              })}
              {/* List the unassigned sensors */}
              <Accordion.Title
                active={activeIndex === unassignedSensorsIndex}
                onClick={() => toggleCategory(unassignedSensorsIndex)}
              >
                <h5 className='bg-blue text-white p-2 d-flex'>
                  <div>
                    <Icon
                      name={activeIndex === unassignedSensorsIndex ? 'chevron up' : 'chevron down'}
                    />
                    Unassigned sensors
                  </div>
                  <div className='ms-auto'>
                    {unassignedSensors?.sensors?.length} sensors
                  </div>
                </h5>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === unassignedSensorsIndex}
              >
                <div className='text-sm'>
                  <p className='ms-2 text-italic'>Tip: Assign sensors on the "My site" page.</p>
                  <SensorLocationItems
                    location={unassignedSensors}
                    locationIndex={unassignedSensorsIndex}
                    cageName={'Unassigned'}
                  />
                </div>
              </Accordion.Content>
            </Accordion>
          </div>
        </Menu>
      </Sticky>
    )
  } else {
    return (
      <div>
        There are no cages setup on your site. You can setup some from the overview map.
      </div>
    )
  }
})

/**
 * Traverses the site data and counts all sensors across all cages and returns a total number
 * @param siteData Array of cages, sensor locations and sensors
 * @returns number - The accumulated number of sensors available for a site
 */
const numberOfSensors = (siteData: any) => {
  let sensorCount = 0
  siteData.cages.map((cage: any, index: number) => {
    return siteData.cages[index].sensor_locations = cage?.sensor_locations?.filter((f: any) => {
      sensorCount += Number.parseInt(f?.sensors?.length) || 0
      return f.sensors.length
    })
  })
  return sensorCount
}

const Analytics2 = observer((props: any) => {
  const { siteStore, analyticsStore, authStore } = useStore()
  const [siteData, setSiteData] = useState<any>()
  const [allViews, setAllViews] = useState<any>([analyticsStore.getViewData()])
  const [loading, setLoading] = useState(false)
  const [darkMode, setDarkMode] = useState(analyticsStore.getDarkMode())
  const [graphGrid, setGraphGrid] = useState(analyticsStore.getGraphGrid())
  const [activeViewIndex, setActiveViewIndex] = useState(0)
  const [sensorListVisible, setSensorListVisible] = useState(false)
  // Setup default view first, in case there aren't any
  const [viewData, setViewData] = useState(allViews[0])
  const toggleSensorList = () => setSensorListVisible(value => !value)
  let siteId = siteStore.getSiteKey()

  // Use the site ID from URL and set that as the active site key. Will update whenever siteID changes
  useEffect(() => {
    // Event listening for when dark mode changes
    window.addEventListener('storage', () => {
      setDarkMode(analyticsStore.getDarkMode())
      setGraphGrid(analyticsStore.getGraphGrid())
    })
    // If there aren't any sensors selected, show the sidebar
    // For some inexplicable reason siteID sometimes is "local", which causes 500 errors
    if (siteId !== 'local') {
      setLoading(true)
      // Fetch all sensors for the current site when siteID changes (and only once)
      SiteDetailsFetcher(siteStore.getSiteKey(), authStore)
        .then(r => {
          setSiteData(r)
        })
      GetAnalyticViews(authStore)
        .then(views => {
          // Supplement the view list with a new empty one users can select
          views.push({
            node_key: null,
            name: 'New view',
            graph_settings: {
              live: true,
              grouped_graphs: false,
              data_points: 100,
              date_from: moment(new Date()).startOf('day').unix(),
              date_to: moment(new Date()).endOf('day').unix(),
              graph_size: 'medium',
            },
            sensors: []
          })
          if (views && views?.length > 0) {
            /**
             * The order is very important here, otherwise the reflected object  isn't mapped
             * correctly in memory, causing different components to use different objects.
             * Thus, causing different sources of truth, which is not what we want (at all).
             */
            setAllViews(views)
            analyticsStore.setViewData(views[activeViewIndex])
            // VERY IMPORTANT! Set the currently selected preset as selected!
            setViewData(analyticsStore.getViewData())
          }
        })
      setLoading(false)
    }
  }, [
    activeViewIndex,
    analyticsStore,
    siteStore,
    authStore,
    siteId
  ])

  const sensorsGroupedByMeasurementType = analyticsStore.getSensorsGroupedByMeasurementType()
  return (
    <Segment
      inverted={darkMode === true}
      className='p-0 border-none'
    >
      {loading &&
        <MessageBox
          heading='Loading'
          message='Please wait...'
          icon='circle notch'
          loading={true}
        />
      }
      {siteData?.name && siteData?.cages?.length ?
        <>
          <Grid>
            <Grid.Column
              key={`site-analytics`}
              width={16}
            >
              <h1 className='d-flex align-items-center ms-3 mt-3'>
                <Dropdown
                  icon='bars'
                  simple
                  className='me-2'
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text={`Change to ` + (darkMode === true ? 'light theme' : 'dark theme')}
                      icon={darkMode === true ? 'sun' : 'moon'}
                      onClick={() => analyticsStore.toggleDarkMode()}
                    />
                    <Dropdown.Item
                      text={`Turn graph grid lines ` + (graphGrid === true ? 'off' : 'on')}
                      icon='hashtag'
                      onClick={() => analyticsStore.toggleGraphGrid()}
                    />

                  </Dropdown.Menu>
                </Dropdown>
                Analytics for {siteData?.name}
                {viewData?.sensors?.length > 0 && (
                  <Popup
                    key={`tooltip-click-sensor-list`}
                    position='bottom center'
                    trigger={
                      <Label
                        className='ms-3'
                        color='blue'
                      >
                        {viewData?.sensors?.length} {viewData?.sensors?.length > 1 ? 'sensors' : 'sensor'} selected
                      </Label>
                    }
                    content="Displays the number of selected measurements from the list on the right"
                  />
                )}
              </h1>
              <div className='ms-3'>
                <ViewManager
                  setActiveViewIndex={setActiveViewIndex}
                  activeViewIndex={activeViewIndex}
                  views={allViews}
                  setAllViews={setAllViews}
                />
              </div>
              {viewData?.sensors?.length > 0
                ?
                <>
                  <GraphOptions
                    viewData={viewData}
                  />
                  <Grid className='m-0'>
                    {viewData?.graph_settings?.grouped_graphs === false
                      ?
                      // Single Graphs
                      viewData?.sensors?.map((sensor: any, sensorIndex: number) => {
                        return (
                          <Grid.Column
                            key={`single-graphs-${sensor?.sensor_id}-${sensorIndex}`}
                            width={computedGraphSize(viewData?.graph_settings?.graph_size)}
                          >
                            <SingleGraph
                              key={`sensor-graph-${sensor?.sensor_id}-${sensorIndex}`}
                              sensor={sensor}
                            />
                          </Grid.Column>
                        )
                      })
                      :
                      /*
                      * Grouped Graphs (by sensor reading type)
                      * We need to iterate a grouped object list measurement type and the respective
                      * sensor in that list, and then output the remaining sensors 
                      * that can't be grouped as single graphs.
                      */
                      Object.keys(sensorsGroupedByMeasurementType)?.map((measurementType: string, measurementIndex: any) => {
                        return (
                          <Grid.Column
                            key={`grouped-graphs-${measurementType}-${measurementIndex}`}
                            width={computedGraphSize(viewData?.graph_settings?.graph_size)}
                          >
                            <GroupedByTypeGraph
                              key={`grouped-graph-${measurementType}-${measurementIndex}`}
                              sensors={sensorsGroupedByMeasurementType[measurementType]}
                              measurementType={measurementType}
                            />
                          </Grid.Column>
                        )
                      })
                    }
                  </Grid>
                </>
                :
                <Message className='m-3'>
                  <Icon
                    name='info circle'
                    size='large'
                  />
                  Click "My sensors" on the right and select some sensors from list to get started.
                  <Icon
                    name='arrow right'
                    className='ms-3'
                  />
                </Message>
              }
            </Grid.Column>
          </Grid>
          {/* Sensor sidebar button toggle */}
          <Popup
            key={`sidebar-toggle-button`}
            trigger={
              <Button
                className={'sensor-sidebar position-fixed top-right m-0 p-2 mt-1 ' + (sensorListVisible ? ' sensor-sidebar-expanded ' : '')}
                onClick={() => toggleSensorList()}
                color='green'
              >
                <Icon
                  name={!sensorListVisible ? 'chevron left' : 'chevron right'}
                  fitted
                  color='blue'
                />
                <span className='text-blue'>
                  {` My sensors (${numberOfSensors(siteData)})`}
                </span>
              </Button>
            }
            content='Click to show/hide the sensor sidebar list'
            position='bottom left'
          />
          {/* Sensor sidebar that lists all the sensors for the selected site */}
          {sensorListVisible && (
            <SidebarMenu
              siteData={siteData}
              viewData={viewData}
            />
          )}
        </>
        :
        <div className='mt-3'>
          <MessageBox
            heading={`Site "${siteData?.name}" has no sensors`}
            message='Seems this site has no sensors added. You can change sites from the site selector up top or add new ones from the Overview map.'
            icon='info circle'
            iconColor='blue'
          />
        </div>
      }
    </Segment>
  )
})

export default Analytics2
import { Icon } from 'semantic-ui-react';
import useStore from '../../Store/Store';
import { observer } from 'mobx-react';
const StepIndicators = observer(() => {
  const { firstTimeSetupStore } = useStore();
  const currentStep = firstTimeSetupStore.getCurrentStep();
  const allSteps = firstTimeSetupStore.getSteps();
  const stepCircles = allSteps.map((step: any, stepIndex: any) => {
    return (
      <Icon
        color={step.completed === true ? 'green' : 'grey'}
        name="circle"
        key={`step-${stepIndex}`}
        className={`${stepIndex === currentStep ? 'current-step' : ''}`}
      />
    );
  });
  return <div className="steps mx-auto text-center">{stepCircles}</div>;
});

export { StepIndicators };

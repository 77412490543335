const releases = [
  {
    version: '2.1.3',
    changes: ['Add support for salinity sensor'],
    knownissues: [],
  },
  {
    version: '2.0.0',
    changes: ['Initial release'],
    knownissues: [],
  },
  /*
  {
    version: '1.4.1',
    changes: ['Bug fixes'],
    knownissues: [],
  },
  {
    version: '1.2.0',
    changes: ['Bug fixes'],
    knownissues: [],
  },
  {
    version: '1.1.1',
    changes: ['Bug fixes'],
    knownissues: [],
  },
  {
    version: '1.1.0',
    changes: ['Overview map', 'Site builder', 'Cloud run', 'Analytics', 'Sensorlist'],
    knownissues: [],
  },
  {
    version: '1.0.0 - Initial release',
    changes: ['Initial relase'],
    knownissues: [],
  },
*/
];

export default releases;
